import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { EventTypes, GameMode, reelSets } from '../../global.d';
import { setIsMiniPayTable, setUserLastBetResult } from '../../gql/cache';
import { isChallengeMode } from '../../utils';
import { BgSkin } from '../background/background';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { Combos, Icon } from '../d';
import { Slot } from '../slot/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends PIXI.Container {
  private getSlotById: CallableFunction;

  private payTableContainers: PIXI.Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(icons: Icon[], getSlotByIdFn: CallableFunction) {
    super();
    this.icons = icons;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    this.visible = setIsMiniPayTable();
    eventManager.addListener(EventTypes.DISABLE_PAY_TABLE, (isVisible: boolean) => (this.visible = isVisible));
    this.payTableContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payTableData.find((d) => d.slug === slug)?.combos as Combos,
    );
    this.createContainers();

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private createContainers() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot: Slot = this.getSlotById(index);
        let slotId: SlotId;
        if (slot.slotId === SlotId.Ax3) {
          slotId = SlotId.A;
        } else if (slot.slotId === SlotId.Bx3) {
          slotId = SlotId.B;
        } else if (slot.slotId === SlotId.Cx3) {
          slotId = SlotId.C;
        } else {
          slotId = slot.slotId;
        }

        const icon = this.icons.find((icon) => icon.id === slotId)!;
        const container = new PIXI.Container();
        const rect = new PIXI.Graphics();
        container.x = this.xPosition(col);
        container.y = this.yPosition(row);
        container.zIndex = 1;
        container.interactive = true;
        rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
        rect.drawRect(0, 0, REEL_WIDTH, SLOT_HEIGHT);
        rect.alpha = 0;
        container.addChild(rect);
        container.on('mousedown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        container.on('touchstart', () => eventManager.emit(EventTypes.SHOW_PAY_TABLE, index));
        const payTable = new MiniPayTable(index, icon, this.combosMap[icon.id]);
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(col: number): number {
    return col * REEL_WIDTH;
  }

  private yPosition(row: number): number {
    return row * SLOT_HEIGHT;
  }

  public setSpinResult(spinResult: Icon[], extendFlg: boolean, reelPosition?: number[], reelLength?: SlotId[][]): void {
    const ReelPosition: number[] = [];

    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index];
      const payTable = container.children[1] as MiniPayTable;
      let pos = 0;

      if (
        extendFlg &&
        reelPosition === undefined &&
        (setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C] ||
          setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B] ||
          setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B_A])
      ) {
        const over = index % REELS_AMOUNT;
        const ReelIdUpper = spinResult[REELS_AMOUNT * 0 + over].id;
        const ReelIdMiddle = spinResult[REELS_AMOUNT * 1 + over].id;
        const ReelIdLower = spinResult[REELS_AMOUNT * 2 + over].id;

        let iconId = icon.id;

        if (index >= 0 && index <= 4) {
          if (ReelIdUpper === ReelIdMiddle && ReelIdUpper === ReelIdLower) {
            pos = 0;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else if (ReelIdUpper === ReelIdMiddle) {
            pos = 1;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else {
            pos = 2;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          }
        } else if (index >= 5 && index <= 9) {
          if (ReelIdUpper === ReelIdMiddle && ReelIdUpper === ReelIdLower) {
            pos = 1;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else if (ReelIdUpper === ReelIdMiddle) {
            pos = 2;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else {
            pos = 0;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          }
        } else if (index >= 10 && index <= 14) {
          if (ReelIdUpper === ReelIdMiddle && ReelIdUpper === ReelIdLower) {
            pos = 2;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else if (ReelIdMiddle === ReelIdLower) {
            pos = 1;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          } else {
            pos = 0;
            iconId = this.changeIcon(setUserLastBetResult().reelSetId, icon.id);
          }
        }

        ReelPosition.push(pos);

        payTable.setPayTableData(iconId, this.combosMap[icon.id], pos);
      } else {
        payTable.setPayTableData(icon.id, this.combosMap[icon.id], pos);
      }
    });
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isChallengeMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private changeIcon(gameMode: string, icon: SlotId): SlotId {
    let rtn = icon;

    if (gameMode === reelSets[GameMode.FREE_SPINS_SYMBOLS_C] && icon === SlotId.C) {
      rtn = SlotId.Cx3;
    }
    if (gameMode === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B]) {
      if (icon === SlotId.C) {
        rtn = SlotId.Cx3;
      } else if (icon === SlotId.B) {
        rtn = SlotId.Bx3;
      }
    }
    if (gameMode === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B_A]) {
      if (icon === SlotId.C) {
        rtn = SlotId.Cx3;
      } else if (icon === SlotId.B) {
        rtn = SlotId.Bx3;
      } else if (icon === SlotId.A) {
        rtn = SlotId.Ax3;
      }
    }
    return rtn;
  }
}

export default MiniPayTableContainer;
