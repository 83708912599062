import { EventTypes, ShurikenType } from '../../../global.d';
import ViewContainer from '../../components/container';
import { ATTACK_SYMBOL_POSITION_Y, ATTACK_SYMBOL_WIDTH, SLOTS_CONTAINER_WIDTH, eventManager } from '../../config';

import AttackSymbol from './attackSymbol';

class AttackSymbolContainer extends ViewContainer {
  private attackSymbolIcon: AttackSymbol[] = [];

  constructor() {
    super();

    for (let j = 0; j < ShurikenType.SHURIKEN_MAX; j++) {
      const sprite = new AttackSymbol(j);
      this.attackSymbolIcon.push(sprite);
      this.addChild(sprite);
    }

    this.setPosition();

    eventManager.addListener(EventTypes.HANDLE_UPDATE_SHURIKEN, this.updateAttackSymbol.bind(this));

    eventManager.addListener(EventTypes.HANDLE_RESET_SHURIKEN, this.resetAttackSymbol.bind(this));
  }

  private setPosition(): void {
    this.x = SLOTS_CONTAINER_WIDTH - ATTACK_SYMBOL_WIDTH * 3;
    this.y = ATTACK_SYMBOL_POSITION_Y;
  }

  private updateAttackSymbol(shuriken: ShurikenType, valid: boolean): void {
    this.attackSymbolIcon[shuriken].updateAttackSymbolIcon(valid);
  }

  public resetAttackSymbol(): void {
    this.attackSymbolIcon.forEach((attackSymbolIcon) => attackSymbolIcon.updateAttackSymbolIcon(true));
  }
}

export default AttackSymbolContainer;
