import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { ParticleEmitterContainer } from '../components/particle';
import { eventManager } from '../config';

import {
  BACKGROUND_GRADIENT_1,
  BACKGROUND_GRADIENT_2,
  BACKGROUND_GRADIENT_3,
  BACKGROUND_GRADIENT_4,
  BACKGROUND_SIZE_HEIGHT,
  BACKGROUND_SIZE_WIDTH,
} from './config';
import { glitterConfig } from './glitterConfig';

export type BgSkin = 'base' | 'challenge1' | 'challenge2' | 'challenge3' | 'fs';

class Background extends ViewContainer {
  private bgCastleSprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.backgroundCastle));

  private currentSkin?: BgSkin;

  private ambientBaseCloudAnimation: SpineAnimation | undefined;

  private ambientBaseTreeAnimation: SpineAnimation | undefined;

  private glitterEffect: ParticleEmitterContainer;

  private backgroundBase = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.freespinsBase));

  private baseGameBackground: PIXI.Texture;

  public backgroundSize: { width: number; height: number };

  constructor(skin: BgSkin = 'base') {
    super();
    this.sortableChildren = true;
    this.bgCastleSprite.zIndex = 1;
    this.backgroundSize = {
      width: BACKGROUND_SIZE_WIDTH,
      height: BACKGROUND_SIZE_HEIGHT,
    };

    this.initCloudAnimation();
    this.initTreeAnimation();

    this.glitterEffect = new ParticleEmitterContainer(glitterConfig);
    this.glitterEffect.zIndex = 10;
    this.addChild(this.glitterEffect);

    this.baseGameBackground = this.createGradient(BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT);

    this.backgroundBase.anchor.set(0.5);
    this.addChild(this.backgroundBase);
    this.setSkin(skin);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.emit(EventTypes.RESIZE, BACKGROUND_SIZE_WIDTH, BACKGROUND_SIZE_HEIGHT);
  }

  private createGradient(width: number, height: number): PIXI.Texture {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const gradient = ctx!.createLinearGradient(0, 0, 0, height);

    canvas.setAttribute('width', width.toString());
    canvas.setAttribute('height', height.toString());

    gradient.addColorStop(0, BACKGROUND_GRADIENT_1);
    gradient.addColorStop(0.25, BACKGROUND_GRADIENT_2);
    gradient.addColorStop(0.85, BACKGROUND_GRADIENT_3);
    gradient.addColorStop(1, BACKGROUND_GRADIENT_4);

    ctx!.fillStyle = gradient;
    ctx!.fillRect(0, 0, width, height);

    return PIXI.Texture.from(canvas);
  }

  public setSkin(skinName: BgSkin) {
    if (this.currentSkin === skinName) return;

    this.currentSkin = skinName;

    if (skinName === 'base') {
      this.glitterEffect.stop(true);

      this.backgroundBase.texture = this.baseGameBackground;
      this.addChild(this.bgCastleSprite);

      this.startCloudAnimation();
      this.startTreeAnimation();
    } else if (skinName === 'fs') {
      this.backgroundBase.texture = PIXI.Texture.from(ResourceTypes.freespinsBase);
      this.removeChild(this.bgCastleSprite);

      this.ambientBaseTreeAnimation!.spine.state.clearTrack(0);
      this.ambientBaseTreeAnimation!.spine.visible = false;
      this.ambientBaseCloudAnimation!.spine.state.clearTrack(0);
      this.ambientBaseCloudAnimation!.spine.visible = false;

      this.glitterEffect.start();
    }
  }

  private initCloudAnimation(): void {
    this.ambientBaseCloudAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.Ambient_Cloud.spineData!);
    this.ambientBaseCloudAnimation.addOnStart(() => {
      this.addChild(this.ambientBaseCloudAnimation!.spine);
    });
    this.ambientBaseCloudAnimation.getSpine().pivot.set(0);
  }

  private initTreeAnimation(): void {
    this.ambientBaseTreeAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.Ambient_Tree.spineData!);
    this.ambientBaseTreeAnimation.addOnStart(() => {
      this.addChild(this.ambientBaseTreeAnimation!.spine);
    });
    this.ambientBaseTreeAnimation.getSpine().pivot.set(0);
  }

  private startCloudAnimation(): void {
    this.ambientBaseCloudAnimation!.setAnimation('Cloud', true);
    this.ambientBaseCloudAnimation!.spine.visible = true;
    this.ambientBaseCloudAnimation!.start();
  }

  private startTreeAnimation(): void {
    this.ambientBaseTreeAnimation!.setAnimation('Tree', true);
    this.ambientBaseTreeAnimation
      ?.getSpine()
      .position.set(-this.backgroundSize.width / 2, -this.backgroundSize.height / 2);
    this.ambientBaseTreeAnimation!.spine.visible = true;
    this.ambientBaseTreeAnimation!.spine.zIndex = 1;
    this.ambientBaseTreeAnimation!.start();
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinMode(settings.mode)) {
      this.setSkin('fs');
    } else {
      this.setSkin('base');
    }
  }

  private resize(width: number, height: number): void {
    this.backgroundSize = { width, height };
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = BACKGROUND_SIZE_WIDTH / BACKGROUND_SIZE_HEIGHT;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.backgroundBase.scale.set(height / BACKGROUND_SIZE_HEIGHT);
    } else {
      this.backgroundBase.scale.set(width / BACKGROUND_SIZE_WIDTH);
    }

    this.glitterEffect.scale.set(this.backgroundBase.scale.x);

    this.ambientBaseTreeAnimation?.getSpine().position.set(-width / 2, -height / 2);

    if (width > height) {
      this.bgCastleSprite.anchor.set(0.4, 0);
      this.bgCastleSprite.pivot.y = -54;
      this.bgCastleSprite.position.y = -this.y;
      this.bgCastleSprite.scale.set(this.backgroundBase.scale.x * 1.25);

      this.ambientBaseCloudAnimation?.getSpine().scale.set(this.bgCastleSprite.scale.y * 0.5);
      this.ambientBaseCloudAnimation?.getSpine().pivot.set(0, -1080 / 2);
      this.ambientBaseCloudAnimation!.getSpine().position.y = -this.y;

      this.ambientBaseTreeAnimation?.getSpine().scale.set(this.backgroundBase.scale.y);
    } else {
      this.bgCastleSprite.anchor.set(0.54, 1);
      this.bgCastleSprite.pivot.y = 0;
      this.bgCastleSprite.position.y = this.y;
      this.bgCastleSprite.scale.set(width / 700);

      this.ambientBaseCloudAnimation?.getSpine().scale.set(this.bgCastleSprite.scale.y);
      this.ambientBaseCloudAnimation?.getSpine().pivot.set(0, this.bgCastleSprite.texture.height);
      this.ambientBaseCloudAnimation!.getSpine().position.y = height / 2;

      this.ambientBaseTreeAnimation?.getSpine().scale.set(this.backgroundBase.scale.y * 0.4);
    }
  }
}
export default Background;
