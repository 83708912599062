import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_STOP_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, GameMode, reelSets } from '../../global.d';
import {
  setGameMode,
  setHighLightSlotPos,
  setIsAnticipation,
  setIsHighLightSlots,
  setNextResult,
  setReel,
  setUserLastBetResult,
} from '../../gql/cache';
import { destroySpine, isChallengeMode, isExtendedSlot, isFreeSpinMode } from '../../utils';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BgSkin } from '../background/background';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  HI_PAY_SLOT_WIDTH,
  HI_PAY_X3_SLOT_HEIGHT,
  HI_ROW_SYMBOL_HEIGHT_DIFF,
  HI_ROW_SYMBOL_WIDTH_DIFF,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  eventManager,
} from '../config';
import { Icon } from '../d';

export class SlotsStopDisplayContainer extends ViewContainer {
  private stopSymbolAnimations: Animation[] = [];

  private slotSprites: PIXI.Sprite[] = [];

  private slotStopId: SlotId[] = [];

  constructor(spinResult: Icon[]) {
    super();
    // this.width = GAME_CONTAINER_WIDTH;
    // this.height = GAME_CONTAINER_HEIGHT;

    for (let reelId = 0; reelId < SLOTS_PER_REEL_AMOUNT; reelId++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[SlotId.G]));

        sprite.anchor.set(0, 0);
        sprite.x = REEL_WIDTH * j;
        sprite.y = SLOT_HEIGHT * reelId;
        sprite.pivot.set(HI_ROW_SYMBOL_WIDTH_DIFF / 2, HI_ROW_SYMBOL_HEIGHT_DIFF / 2);

        this.addChild(sprite);
        this.slotSprites.push(sprite);
      }
    }

    const reelSet = setReel();

    for (let reelId = 0; reelId < REELS_AMOUNT; reelId++) {
      const reelPosition = setUserLastBetResult().result.reelPositions[reelId];
      const reelLength = reelSet[reelId].length;
      const reelIcon: SlotId[] = [];
      if (
        setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C] ||
        setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B] ||
        setUserLastBetResult().reelSetId === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B_A]
      ) {
        const changeReelX3: SlotId[][] = [
          [SlotId.A, SlotId.Ax3],
          [SlotId.B, SlotId.Bx3],
          [SlotId.C, SlotId.Cx3],
        ];

        let nextIndex = reelPosition! + 1;
        let beforeIndex = reelPosition! - 1;
        if (reelPosition === reelLength! - 1) {
          nextIndex = 0;
        } else if (reelPosition === 0) {
          beforeIndex = reelLength! - 1;
        }

        reelIcon[0] = reelSet[reelId][beforeIndex];
        reelIcon[1] = reelSet[reelId][reelPosition!];
        reelIcon[2] = reelSet[reelId][nextIndex];
      } else {
        reelIcon[0] = spinResult[0 * REELS_AMOUNT + reelId].id;
        reelIcon[1] = spinResult[1 * REELS_AMOUNT + reelId].id;
        reelIcon[2] = spinResult[2 * REELS_AMOUNT + reelId].id;
      }

      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        const reelIdIcon = i * REELS_AMOUNT + reelId;
        this.slotSprites[reelIdIcon].visible = true;
        this.slotSprites[reelIdIcon].scale.set(SLOT_SCALE);

        if (isExtendedSlot(reelIcon[i])) {
          if (i === 0) {
            this.slotSprites[reelIdIcon].height = HI_PAY_X3_SLOT_HEIGHT;
            if (reelIcon[0] === reelIcon[1] && reelIcon[0] === reelIcon[2]) {
              this.slotSprites[reelIdIcon].y = 0;
            } else if (reelIcon[0] === reelIcon[1]) {
              this.slotSprites[reelIdIcon].y = -SLOT_HEIGHT;
            } else {
              this.slotSprites[reelIdIcon].y = -SLOT_HEIGHT * 2;
            }
          } else {
            if (reelIcon[0] === reelIcon[1] && reelIcon[0] === reelIcon[2]) {
              this.slotSprites[reelIdIcon].height = 0;
            }
            if ((i === 1 && reelIcon[0] === reelIcon[1]) || (i === 2 && reelIcon[1] === reelIcon[2])) {
              this.slotSprites[reelIdIcon].height = 0;
            } else {
              this.slotSprites[reelIdIcon].width = HI_PAY_SLOT_WIDTH;
              this.slotSprites[reelIdIcon].height = HI_PAY_X3_SLOT_HEIGHT;
              this.slotSprites[reelIdIcon].y = SLOT_HEIGHT * i;
            }
          }
        } else {
          this.slotSprites[reelIdIcon].y = i * SLOT_HEIGHT;
        }
        if (this.slotSprites[reelIdIcon].height > 0) {
          this.slotSprites[reelIdIcon].texture = PIXI.Texture.from(MAPPED_SYMBOLS[reelIcon[i]]);
          this.slotSprites[reelIdIcon].scale.set(SLOT_SCALE);
          this.slotSprites[reelIdIcon].visible = true;
        }
        this.slotSprites[reelIdIcon].pivot.set(HI_ROW_SYMBOL_WIDTH_DIFF / 2, HI_ROW_SYMBOL_HEIGHT_DIFF / 2);
      }
    }
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.skipStopSymbolAnimations.bind(this));

    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.hideContainer.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.showSlotStops.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.JINGLE_START, this.skipScatterAnnounce.bind(this));
    eventManager.addListener(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, this.handleSetSlotsVisibility.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeBackground.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.SLOT_HIGHLIGHT_START, this.slotHighLightStart.bind(this));

    eventManager.addListener(EventTypes.SLOT_HIGHLIGHT_END, this.slotHighLightEnd.bind(this));
  }

  private onChangeBackground(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    if (isChallengeMode(mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (settings.mode === GameMode.REGULAR) {
      this.slotSprites.forEach((slot) => {
        slot.scale.set(SLOT_SCALE);
      });
    }
  }

  private skipScatterAnnounce(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
  }

  private onReelStopped(reelId: number): void {
    eventManager.emit(
      EventTypes.SHOW_STOP_SLOTS_DISPLAY,
      setNextResult()!.bet.result.spinResult,
      false,
      setNextResult()!.bet.result.reelPositions[reelId],
      setNextResult()!.bet.reelSet.layout[reelId].length,
      reelId,
    );
    this.startOnSymbolsStopAnimations(
      reelId,
      setNextResult()!.bet.result.reelPositions[reelId],
      setNextResult()!.bet.reelSet.layout[reelId].length,
    );
    if (reelId === REELS_AMOUNT - 1) {
      eventManager.emit(EventTypes.SLOT_HIGHLIGHT_END);
    }
  }

  private startOnSymbolsStopAnimations(reelId: number, reelPosition: number, reelLength: number): void {
    // TODO: Refactor
    if (reelId === 0) this.stopSymbolAnimations = [];

    const reelStopPosition: number[] = [];
    let nextIndex = reelPosition + 1;
    let beforeIndex = reelPosition - 1;
    if (reelPosition === reelLength - 1) {
      nextIndex = 0;
    } else if (reelPosition === 0) {
      beforeIndex = reelLength - 1;
    }
    reelStopPosition[0] = beforeIndex;
    reelStopPosition[1] = reelPosition;
    reelStopPosition[2] = nextIndex;

    const reelSet = setReel();
    let hi = 0;
    let targetReelId: SlotId;

    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      let slotId: SlotId | undefined;
      if (reelSet.length !== 0) {
        slotId = reelSet[reelId][reelStopPosition[row]];
      } else {
        slotId = setNextResult()?.bet.result.spinResult[row * REELS_AMOUNT + reelId].id;
      }

      // SlotId to ReelPostion conversion
      // ReelPostion is invalid except for the expansion Slot
      //   A3 A3 A3 WL D    ⇒ 0  1  2  2  2
      //   A3 A3 WL A3 G    ⇒ 1  2  1  0  1
      //   A3 D  E  A3 A3   ⇒ 2  0  0  1  0

      // 0 ⇒ Upper row of expansion Slot
      // 1 ⇒ Middle of expansion Slot
      // 2 ⇒ Bottom of expansion Slot

      if (reelSet.length !== 0 && isExtendedSlot(slotId!)) {
        const ReelIdUpper = reelSet[reelId][beforeIndex];
        const ReelIdMiddle = reelSet[reelId][reelPosition];
        const ReelIdLower = reelSet[reelId][nextIndex];
        if (row === 0) {
          targetReelId = ReelIdUpper;
        } else if (row === 1) {
          targetReelId = ReelIdMiddle;
        } else {
          targetReelId = ReelIdLower;
        }

        if (ReelIdUpper === ReelIdMiddle && ReelIdUpper === ReelIdLower) {
          hi = (SLOT_HEIGHT * 3) / 2;
        } else if (isExtendedSlot(ReelIdMiddle) && ReelIdMiddle === ReelIdLower && targetReelId === ReelIdMiddle) {
          hi = (SLOT_HEIGHT * 3) / 2 + SLOT_HEIGHT;
        } else if (isExtendedSlot(ReelIdMiddle) && ReelIdUpper === ReelIdMiddle && targetReelId === ReelIdMiddle) {
          hi = (SLOT_HEIGHT * 3) / 2 - SLOT_HEIGHT;
        } else if (isExtendedSlot(ReelIdUpper) && row === 0) {
          hi = (SLOT_HEIGHT * 3) / 2 - SLOT_HEIGHT * 2;
        } else if (isExtendedSlot(ReelIdLower) && row === 2) {
          hi = (SLOT_HEIGHT * 3) / 2 + SLOT_HEIGHT * 2;
        }
      }

      if (slotId && MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId]) {
        const animationData = MAPPED_SYMBOLS_STOP_ANIMATIONS[slotId];
        if (!animationData || !animationData.src || !animationData.animation) throw Error('INVALID SPINE DATA');
        const animation = new SpineAnimation({}, PIXI.Loader.shared.resources[animationData.src].spineData!);
        const dummy = Tween.createDelayAnimation(1000);
        const slot = row * REELS_AMOUNT + reelId;
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        dummy.addOnStart(() => {
          if (isExtendedSlot(slotId!)) {
            animation.spine.y = hi;
          } else {
            animation.spine.y = SLOT_HEIGHT / 2 + SLOT_HEIGHT * row;
          }
          animation.spine.x = REEL_WIDTH / 2 + REEL_WIDTH * reelId;

          this.addChild(animation.getSpine());
          animation.setAnimation(animationData.animation!, false);
          this.slotSprites[slot].visible = false;
          eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [slot], false);
        });

        dummy.addOnComplete(() => {
          destroySpine(animation);
          if (reelId === REELS_AMOUNT - 1) {
            setIsAnticipation(false);
            eventManager.emit(EventTypes.SLOT_HIGHLIGHT_END);
          }
          this.removeChild(animation.spine);
          this.slotSprites[slot].visible = true;
        });

        dummy.addOnSkip(() => {
          setIsAnticipation(false);
          destroySpine(animation);
          this.removeChild(animation.spine);
          this.slotSprites[row * REELS_AMOUNT + reelId].visible = true;
        });

        dummy.addOnChange(() => {
          if (setIsAnticipation() && slotId !== SlotId.SC1) {
            animation.spine.tint = ANTICIPATION_SLOTS_TINT;
          }

          if (!setIsAnticipation()) {
            animation.spine.tint = 0xffffff;
          }
        });

        this.stopSymbolAnimations.push(dummy);
        dummy.start();
      }
    }
  }

  private skipStopSymbolAnimations(): void {
    this.stopSymbolAnimations.forEach((animation) => animation.skip());
    this.stopSymbolAnimations = [];
  }

  private handleSetSlotsVisibility(slots: number[], visible: boolean): void {
    const highlight = setHighLightSlotPos();

    for (let i = 0; i < slots.length; i++) {
      let check = true;
      for (const element of highlight) {
        if (element === slots[i]) {
          check = false;
          break;
        }
      }
      if (check || !visible) {
        this.slotSprites[slots[i]].visible = visible;
      }
    }
  }

  private showSlotStops(
    spinResult: Icon[],
    constructorFlg: boolean,
    reelPosition?: number,
    reelLength?: number,
    reelId?: number,
  ): void {
    this.visible = true;
    const reelSet = setReel();

    const reelIcon: SlotId[] = [];

    if (constructorFlg === true) return;

    spinResult.forEach((result, index) => {
      this.slotStopId[index] = result.id;
    });

    if (isFreeSpinMode(setGameMode())) {
      const changeReelX3: SlotId[][] = [
        [SlotId.A, SlotId.Ax3],
        [SlotId.B, SlotId.Bx3],
        [SlotId.C, SlotId.Cx3],
      ];

      let nextIndex = reelPosition! + 1;
      let beforeIndex = reelPosition! - 1;
      if (reelPosition === reelLength! - 1) {
        nextIndex = 0;
      } else if (reelPosition === 0) {
        beforeIndex = reelLength! - 1;
      }

      reelIcon[0] = reelSet[reelId!][beforeIndex];
      reelIcon[1] = reelSet[reelId!][reelPosition!];
      reelIcon[2] = reelSet[reelId!][nextIndex];
    } else {
      if (reelId !== undefined) {
        reelIcon[0] = spinResult[0 * REELS_AMOUNT + reelId].id;
        reelIcon[1] = spinResult[1 * REELS_AMOUNT + reelId].id;
        reelIcon[2] = spinResult[2 * REELS_AMOUNT + reelId].id;
      }
    }
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        const reelIdIcon = i * REELS_AMOUNT + reelId;
        this.slotSprites[reelIdIcon].visible = true;
        this.slotSprites[reelIdIcon].scale.set(SLOT_SCALE);

        if (isExtendedSlot(reelIcon[i])) {
          if (i === 0) {
            this.slotSprites[reelIdIcon].height = HI_PAY_X3_SLOT_HEIGHT;
            if (reelIcon[0] === reelIcon[1] && reelIcon[0] === reelIcon[2]) {
              this.slotSprites[reelIdIcon].y = 0;
            } else if (reelIcon[0] === reelIcon[1]) {
              this.slotSprites[reelIdIcon].y = -SLOT_HEIGHT;
            } else {
              this.slotSprites[reelIdIcon].y = -SLOT_HEIGHT * 2;
            }
          } else {
            if (reelIcon[0] === reelIcon[1] && reelIcon[0] === reelIcon[2]) {
              this.slotSprites[reelIdIcon].height = 0;
            }
            if ((i === 1 && reelIcon[0] === reelIcon[1]) || (i === 2 && reelIcon[1] === reelIcon[2])) {
              this.slotSprites[reelIdIcon].height = 0;
            } else {
              this.slotSprites[reelIdIcon].width = HI_PAY_SLOT_WIDTH;
              this.slotSprites[reelIdIcon].height = HI_PAY_X3_SLOT_HEIGHT;
              this.slotSprites[reelIdIcon].y = SLOT_HEIGHT * i;
            }
          }
        } else {
          this.slotSprites[reelIdIcon].y = i * SLOT_HEIGHT;
        }
        if (this.slotSprites[reelIdIcon].height > 0) {
          this.slotSprites[reelIdIcon].texture = PIXI.Texture.from(MAPPED_SYMBOLS[reelIcon[i]]);
          this.slotSprites[reelIdIcon].scale.set(SLOT_SCALE);
          this.slotSprites[reelIdIcon].visible = true;
        }
        this.slotSprites[reelIdIcon].pivot.set(HI_ROW_SYMBOL_WIDTH_DIFF / 2, HI_ROW_SYMBOL_HEIGHT_DIFF / 2);
      }
    } else {
      for (let i = 0; i < spinResult.length; i++) {
        this.slotSprites[i].texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[i].id]);
        this.slotSprites[i].y = Math.floor(i / REELS_AMOUNT) * SLOT_HEIGHT;
        this.slotSprites[i].scale.set(SLOT_SCALE);
        this.slotSprites[i].pivot.set(HI_ROW_SYMBOL_WIDTH_DIFF / 2, HI_ROW_SYMBOL_HEIGHT_DIFF / 2);
        this.slotSprites[i].visible = true;
      }
    }
  }

  private hideContainer(): void {
    this.visible = false;
    this.slotSprites.forEach((sprite) => (sprite.visible = false));
  }

  private slotHighLightStart(): void {
    this.slotSprites.forEach((sprite, i) => {
      if (this.slotStopId[i] !== SlotId.SC1) {
        sprite.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private slotHighLightEnd(): void {
    setIsAnticipation(false);

    this.slotSprites.forEach((sprite) => (sprite.tint = 0xffffff));
  }
}
