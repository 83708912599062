export const FREE_SPINS_TREASURE_ICON_POS_X = 780;
export const FREE_SPINS_TREASURE_ICON_POS_Y = -100;
export const FREE_SPINS_TREASURE_ICON_SCALE = 0.5;

export const FREE_SPINS_COUNTER_POS_X = 840;
export const FREE_SPINS_COUNTER_POS_Y = -30;

export const FREE_SPINS_COUNTER_ANIMATION_SCALE = 1.5;
export const FREE_SPINS_COUNTER_ANIMATION_DELAY = 500;
export const FREE_SPINS_COUNTER_ANIMATION_LOOP = false;

export const FREE_SPINS_TITLE_MOVE_SCALE = 0.6;
export const FREE_SPINS_TITLE_MOVE_SPIN_SCALE = 0.8;

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_FIRE_TEXT_COLOR_CONFIG = {
  fill: ['#e7194b', '#f19e00', '#feff17', '#f19e00', '#e7194b'],
  fillGradientStops: [0.3, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
};

export const textStyle = {
  fontFamily: font,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontSize: 55,
  miterLimit: 0,
  strokeThickness: 4,
};

export const spinsStyle = {
  fontFamily: font,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontSize: 55,
  miterLimit: 0,
  strokeThickness: 4,
};
