import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { MessageFreeSpinsBannerProps } from '../../global';
import { updateTextScale } from '../../utils/utils';

import { BaseMessageBanner } from './baseMessageBanner';
import { btnTextStyle, titleTextStyle } from './textStyles';

export class MessageFreeSpinsBanner extends BaseMessageBanner {
  protected props: MessageFreeSpinsBannerProps;

  private title?: PIXI.Text;

  private description?: PIXI.Text;

  private btn?: PIXI.Text;

  constructor(props: MessageFreeSpinsBannerProps) {
    super(props);
    this.props = props;
  }

  public init(): MessageFreeSpinsBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    this.description = this.initDescription(this.props.description);
    this.btn = this.initBtn(this.props.btnText);

    this.addChild(this.title, this.description, this.btn);
    return this;
  }

  private initTitle(titleText: string): PIXI.Text {
    const title = new PIXI.Text(titleText, titleTextStyle);
    title.resolution = 1;
    title.anchor.set(0.5, 0.5);
    title.y = 225;
    updateTextScale(title, isMobile ? 670 : 800, isMobile ? 150 : 250);

    return title;
  }

  private initDescription(descriptionText: string): PIXI.Text {
    const description = new PIXI.Text(descriptionText, titleTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0.5);
    description.y = 400;
    updateTextScale(description, isMobile ? 670 : 800, isMobile ? 150 : 250);

    return description;
  }

  private initBtn(btnText: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0);
    btn.y = 590;
    updateTextScale(btn, isMobile ? 670 : 800, isMobile ? 150 : 250);

    return btn;
  }
}
