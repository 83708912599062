import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { MessageWinBannerProps } from '../../global';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils/utils';

import { BaseMessageBanner } from './baseMessageBanner';
import {
  MESSAGE_NEXT_BASE_GAME_Y,
  MESSAGE_WIN_BANNER_TITLE_Y,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_X,
  MESSAGE_WIN_BANNER_WIN_AMOUNT_Y,
} from './config';
import { btnTextStyle, totalWinAmountTextStyles, totalWinTitleStyles } from './textStyles';

export class MessageWinBanner extends BaseMessageBanner {
  protected props: MessageWinBannerProps;

  private title?: PIXI.Text;

  private totalWin?: PIXI.Text;

  constructor(props: MessageWinBannerProps) {
    super(props);
    this.props = props;
  }

  public init(): MessageWinBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    this.totalWin = this.initWinTotal(this.props.totalWin);
    this.addChild(this.title, this.totalWin);
    return this;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.y = MESSAGE_WIN_BANNER_TITLE_Y;
    updateTextScale(winTitle, isMobile ? 670 : 800, isMobile ? 150 : 250);
    return winTitle;
  }

  protected initWinTotal(totalWin: string): PIXI.Text {
    const winTotal = new PIXI.Text(totalWin, totalWinAmountTextStyles);
    winTotal.resolution = 1;
    winTotal.anchor.set(0.5, 0);
    winTotal.x = MESSAGE_WIN_BANNER_WIN_AMOUNT_X;
    winTotal.y = MESSAGE_WIN_BANNER_WIN_AMOUNT_Y;
    return winTotal;
  }
}
