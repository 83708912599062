import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { BgSkin } from '../background/background';
import ChallengeGameContainer from '../challengeGame/challengeGameContainer';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_TITLE_LOGO_Z_INDEX,
  GAME_TITLE_LOGO_X_POSITION,
  GAME_TITLE_LOGO_Y_POSITION,
  eventManager,
} from '../config';

class GameTitle extends ViewContainer {
  private titleLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));

  public challengeGameContainer: ChallengeGameContainer;

  constructor() {
    super();

    this.challengeGameContainer = new ChallengeGameContainer();
    this.challengeGameContainer.visible = true;
    this.addChild(this.challengeGameContainer);

    this.initGameLogo();

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private initGameLogo(): void {
    this.titleLogo.anchor.set(0);
    this.titleLogo.x = GAME_TITLE_LOGO_X_POSITION;
    this.titleLogo.y = GAME_TITLE_LOGO_Y_POSITION - 43; // TODO remove it  -43 = 327 - 370
    this.addChild(this.titleLogo);
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (settings.mode === GameMode.REGULAR || settings.mode === GameMode.BUY_FEATURE) {
      this.challengeGameContainer.visible = true;
      this.titleLogo.visible = true;
    } else {
      this.challengeGameContainer.visible = true;
      this.titleLogo.visible = false;
    }
  }
}

export default GameTitle;
