import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoNinjaFeatureDesc = [
    { key: 'infoNinjaFeatureDesc_1', text: i18n.t('infoNinjaFeatureDesc_1') },
    { key: 'infoNinjaFeatureDesc_2', text: i18n.t('infoNinjaFeatureDesc_2') },
    { key: 'infoNinjaFeatureDesc_3', text: i18n.t('infoNinjaFeatureDesc_3') },
    { key: 'infoNinjaFeatureDesc_4', text: i18n.t('infoNinjaFeatureDesc_4') },
  ];

  const infoFreeSpinFeatureDesc = [
    { key: 'infoFreeSpinFeatureDesc_1', text: i18n.t('infoFreeSpinFeatureDesc_1') },
    { key: 'infoFreeSpinFeatureDesc_2', text: i18n.t('infoFreeSpinFeatureDesc_2') },
    { key: 'infoFreeSpinFeatureDesc_3', text: i18n.t('infoFreeSpinFeatureDesc_3') },
    { key: 'infoFreeSpinFeatureDesc_4', text: i18n.t('infoFreeSpinFeatureDesc_4') },
    { key: 'infoFreeSpinFeatureDesc_5', text: i18n.t('infoFreeSpinFeatureDesc_5') },
    { key: 'infoFreeSpinFeatureDesc_6', text: i18n.t('infoFreeSpinFeatureDesc_6') },
  ];

  const infoBuyFeatureDesc = [
    { key: 'infoBuyFeatureDesc_1', text: i18n.t('infoBuyFeatureDesc_1') },
    { key: 'infoBuyFeatureDesc_2', text: i18n.t('infoBuyFeatureDesc_2') },
    { key: 'infoBuyFeatureDesc_3', text: i18n.t('infoBuyFeatureDesc_3') },
  ];

  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins.extract!;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().infoBuyFeatureIcon));
  }, []);

  return (
    <section className={styles.feature}>
      <div className={`${styles.p} ${styles.left}`}>
        <div key="infoFeatureFree" dangerouslySetInnerHTML={{ __html: t('infoFeatureFree') }} />
      </div>
      <h1 className={styles.title}>{t('infoNinjaFeatureTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoNinjaFeatureDesc.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
      <h2 className={styles.title}>{t('infoFreeSpinFeatureTitle')}</h2>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFreeSpinFeatureDesc.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
      <h3 className={styles.title}>{t('infoBuyFeatureTitle')}</h3>
      <div className={`${styles.container}`}>
        <img className="buyFeatureBtn" draggable="false" src={infoBuyFeatureIcon} />
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoBuyFeatureDesc.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles.gameRules}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
