import 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, mappedAudioSprites } from '../../config';
import {
  BgmSoundTypes,
  EventTypes,
  GameMode,
  MessageChallengeGameResultBannerProps,
  MessageChallengeGameStartBannerProps,
  MessageFreeSpinsBannerProps,
  MessageWinBannerProps,
} from '../../global.d';
import {
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWinReplay,
  setFreeSpinsTotalWin,
  setGameMode,
  setReplayBet,
} from '../../gql';
import { formatNumber, isFreeSpinMode, normalizeCoins, showCurrency, updateCoinValueAfterBonuses } from '../../utils';
import Tween from '../animations/tween';
import BgmControl from '../bgmControl/bgmControl';
import ViewContainer from '../components/container';
import {
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  LINES_NUMBER_CONTAINER_WIDTH,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_BACKGROUND_HEIGHT,
  SLOTS_BACKGROUND_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import { MessageChallengeGameResultBanner } from '../messageBanner/messageChallengeGameResultBanner';
import { MessageChallengeGameStartBanner } from '../messageBanner/messageChallengeGameStartBanner';
import { MessageFreeSpinsBanner } from '../messageBanner/messageFreeSpinsBanner';
import { MessageWinBanner } from '../messageBanner/messageWinBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
window.PIXI = PIXI;

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public slotStopDisplayContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: GameTitle;

  public gameReplay: GameReplay;

  public coinsAnimationContainer: PIXI.Container;

  // public ninjaPick: NinjaPick[] = [];

  // public ninjaAttack: NinjaAttack;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = SHOW_LINES_NUMBER_CONTAINER ? LINES_NUMBER_CONTAINER_WIDTH : 0;
    this.slotsContainer.sortableChildren = true;
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;

    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.slotStopDisplayContainer = props.slotStopDisplayContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;

    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.slotStopDisplayContainer);
    this.slotsContainer.addChild(this.miniPayTableContainer);
    this.slotsContainer.addChild(this.winSlotsContainer);

    this.slotsContainer.mask = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, SLOTS_CONTAINER_HEIGHT - SLOTS_BACKGROUND_HEIGHT, SLOTS_BACKGROUND_WIDTH, SLOTS_BACKGROUND_HEIGHT)
      .endFill();
    this.slotsContainer.addChild(this.slotsContainer.mask);

    this.addChild(this.reelsBackgroundContainer);
    this.addChild(this.slotsContainer);

    this.addChild(this.linesContainer);

    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(
      EventTypes.CREATE_CHALLENGE_GAME_START_MESSAGE_BANNER,
      this.createChallengeGameStartMessage.bind(this),
    );
    eventManager.addListener(
      EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER,
      this.createChallengeGameResultMessage.bind(this),
    );

    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_WIN_MESSAGE_BANNER, this.createWinMessage.bind(this));
  }

  private createChallengeGameStartMessage(props: MessageChallengeGameStartBannerProps): void {
    this.addChild(new MessageChallengeGameStartBanner(props).init());
  }

  private createChallengeGameResultMessage(props: MessageChallengeGameResultBannerProps): void {
    this.addChild(new MessageChallengeGameResultBanner(props).init());
  }

  private createFreeSpinsMessage(props: MessageFreeSpinsBannerProps): void {
    this.addChild(new MessageFreeSpinsBanner(props).init());
    BgmControl.stopBgm();
    BgmControl.playBgm(BgmSoundTypes.FS);
  }

  private createWinMessage(props: MessageWinBannerProps): void {
    const totalWinDelay = Tween.createDelayAnimation(mappedAudioSprites[ISongs.TotalWinBanner].duration);
    totalWinDelay.addOnStart(() => {
      BgmControl.fadeOutAll(0);
    });
    totalWinDelay.addOnSkip(() => {
      AudioHowl.fadeOut(1000, ISongs.TotalWinBanner);
    });
    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    if (
      setReplayBet() &&
      isFreeSpinMode(setGameMode()) &&
      setCurrentBonus().rounds === setCurrentBonus().currentRound
    ) {
      props.totalWin = `${formatNumber(
        setCurrency(),
        normalizeCoins(setCurrentFreeSpinsTotalWinReplay()),
        showCurrency(setCurrency()),
      )} `;
    }
    totalWinDelay.start();
    this.addChild(
      new MessageWinBanner({
        ...props,
        callback: () => {
          totalWinDelay.skip();
          if (props.callback) props.callback();
        },
      }).init(),
    );
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      this.gameTitle.visible = false;
    } else {
      updateCoinValueAfterBonuses();
      this.gameTitle.visible = true;
    }
  }

  private resize(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
