export enum ResourceTypes {
  challengegameFrame = 'challengegameFrame',
  reelFrame = 'reelFrame',
  reelsBackBasegame = 'reelsBackBasegame',
  titlelogo = 'titlelogo',
  messageBanner = 'messageBanner',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  sparks = 'sparks',
  particle = 'particle',
  shortLine = 'shortLine',
  blurSymbolANinja = 'blurSymbolANinja',
  blurSymbolANinjax3 = 'blurSymbolANinjax3',
  blurSymbolBNinja = 'blurSymbolBNinja',
  blurSymbolBNinjax3 = 'blurSymbolBNinjax3',
  blurSymbolCNinja = 'blurSymbolCNinja',
  blurSymbolCNinjax3 = 'blurSymbolCNinjax3',
  blurSymbolDNinjasword = 'blurSymbolDNinjasword',
  blurSymbolEChainsickle = 'blurSymbolEChainsickle',
  blurSymbolFKunai = 'blurSymbolFKunai',
  blurSymbolGMakibishi = 'blurSymbolGMakibishi',
  symbolANinja = 'symbolANinja',
  symbolANinjax3 = 'symbolANinjax3',
  symbolBNinja = 'symbolBNinja',
  symbolBNinjax3 = 'symbolBNinjax3',
  symbolCNinja = 'symbolCNinja',
  symbolCNinjax3 = 'symbolCNinjax3',
  symbolDNinjasword = 'symbolDNinjasword',
  symbolEChainsickle = 'symbolEChainsickle',
  symbolFKunai = 'symbolFKunai',
  symbolGMakibishi = 'symbolGMakibishi',
  symbolSCShuriken = 'symbolSCShuriken',
  symbolWLRoll = 'symbolWLRoll',
  backgroundCastle = 'backgroundCastle',
  challengegameBase = 'challengegameBase',
  challengegameBase2 = 'challengegameBase2',
  challengegameBase3 = 'challengegameBase3',
  challengegameCutinCoinicon = 'challengegameCutinCoinicon',
  challengegameCutinTreasureicon = 'challengegameCutinTreasureicon',
  challengegameEffect = 'challengegameEffect',
  challengegameIconShuriken = 'challengegameIconShuriken',
  challengegameIconShurikenOff = 'challengegameIconShurikenOff',
  challengegameLeveliconNinjaA = 'challengegameLeveliconNinjaA',
  challengegameLeveliconNinjaAOff = 'challengegameLeveliconNinjaAOff',
  challengegameLeveliconNinjaB = 'challengegameLeveliconNinjaB',
  challengegameLeveliconNinjaBOff = 'challengegameLeveliconNinjaBOff',
  challengegameLeveliconNinjaC = 'challengegameLeveliconNinjaC',
  challengegameLeveliconNinjaCOff = 'challengegameLeveliconNinjaCOff',
  freespinsBase = 'freespinsBase',
  iconGlow = 'iconGlow',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  intro01 = 'intro01',
  intro02 = 'intro02',
  intro03 = 'intro03',
  introBg = 'introBg',
  textLogo = 'textLogo',
}
