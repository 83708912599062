import { stubFalse } from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import { setBrokenGame, setCurrentBonus } from '../../gql';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils/utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  FREE_SPINS_COUNTER_ANIMATION_DELAY,
  FREE_SPINS_COUNTER_ANIMATION_LOOP,
  FREE_SPINS_COUNTER_ANIMATION_SCALE,
  FREE_SPINS_COUNTER_POS_X,
  FREE_SPINS_COUNTER_POS_Y,
  spinsStyle,
  textStyle,
} from './config';

export class FreeSpinsCounter extends ViewContainer {
  private titleText: PIXI.Text;

  private spinsText: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  constructor() {
    super();

    this.titleText = this.initTitleText(i18n.t('freeSpins'));
    this.titleText.anchor.set(0, 0.5);
    updateTextScale(this.titleText, isMobile ? 300 : 305, isMobile ? 250 : 250);
    this.titleText.x = 20;
    this.titleText.y = 35;
    this.spinsText = this.initSpinsText(5, 0);

    this.init();

    eventManager.addListener(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
      (spins: number, curr: number, immediately: boolean): void => this.handleUpdate(spins, curr, immediately),
    );

    if (setBrokenGame()) {
      this.handleUpdate(setCurrentBonus().rounds, setCurrentBonus().currentRound, true);
    }
  }

  public getSpinsTextPos(): number {
    return this.spinsText.position.x;
  }

  private init(): void {
    this.name = 'counter';
    this.addChild(this.titleText);
    this.addChild(this.spinsText);
    this.pivot.set(0, this.height);
    this.position.set(FREE_SPINS_COUNTER_POS_X, FREE_SPINS_COUNTER_POS_Y);
  }

  private initTitleText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>(titleText), textStyle);
    text.resolution = 1;
    text.anchor.set(0, 0);
    return text;
  }

  private initSpinsText(spins: number, currentSpin: number): PIXI.Text {
    const spinsText = new PIXI.Text(this.formatSpins(spins, currentSpin), spinsStyle);
    spinsText.resolution = 1;
    spinsText.anchor.set(0.5, 0.5);
    spinsText.position.set(50 + this.titleText.width + spinsText.width / 2, spinsText.height / 2);

    return spinsText;
  }

  private handleUpdate(spins: number, currentSpin: number, immediately: boolean): void {
    this.spinsText.text = this.formatSpins(spins, currentSpin);

    if (immediately) return;
    if (!this.visible) return;

    this.pulsAnimation = createZoomAnimation(
      this.spinsText,
      FREE_SPINS_COUNTER_ANIMATION_SCALE,
      FREE_SPINS_COUNTER_ANIMATION_DELAY,
      FREE_SPINS_COUNTER_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  private formatSpins(spins: number, currentSpin: number): string {
    return `${currentSpin}/${spins}`;
  }
}
