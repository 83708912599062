// eslint-disable-next-line @typescript-eslint/no-explicit-any
import AudioHowl from '@phoenix7dev/play-music';
import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ISongs, mappedAudioSprites, SlotId } from '../config';
import {
  BetBonusReward,
  BetCoinReward,
  BetReward,
  BonusGrants,
  ChallengeResult,
  EventTypes,
  GameMode,
  ISettledBet,
  PreLoadedGrantBreakdown,
  reelSets,
  UserBonus,
} from '../global.d';
import {
  setBrokenGame,
  setChallengeBonusId,
  setChallengeGameMessageBanner,
  setChallengeRemainRound,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setExtendedSlotPos,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSceneChange,
  setLastRegularWinAmount,
  setNextResult,
  setNinjaPick,
  setPreLoadedGrantBreakdown,
  setPrevReelsPosition,
  setProgress,
  setReelSetId,
  setScBonusId,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import {
  getUserBonuses,
  isStoppedGql,
  ReelSetType,
  slotBetGql,
} from '../gql/query';
import {
  formatNumber,
  getExtendedSpinResult,
  getGameModeByBonusId,
  getGameModeByReelSetId,
  getSpinResult5x3,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isChallengeMode,
  isExtendedSlot,
  isFreeSpinMode,
  isRegularMode,
  normalizeCoins,
  showCurrency,
} from '../utils';
import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Animator from './animations/animator';
import Tween from './animations/tween';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import BuyFeatureBtnIcon from './buyFeature/buyFeatureBtnIcon';
import BuyFeaturePopup from './buyFeature/buyFeaturePopup';
import BuyFeaturePopupConfirm from './buyFeature/buyFeaturePopupConfirm';
import {
  ANNOUNCE_ANIMATION_DURATION,
  ANTICIPATION_ENABLE,
  ANTICIPATION_LONG_SPIN_REEL,
  ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME,
  ANTICIPATION_SYMBOLS_ID,
  CHALLENGE_GAME_START_DELAY,
  eventManager,
  FREE_SPINS_TIME_OUT_BANNER,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  REELS_AMOUNT,
  SCENE_CHANGE_BLACK_SCREEN_ANIMATION_TIME,
  SCENE_CHANGE_FADE_TIME,
  SlotMachineState,
} from './config';
import { Icon, ISlotData } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import LinesContainer from './lines/linesContainer';
import MiniPayTableContainer from './miniPayTable/miniPayTableContainer';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import SceneChange from './sceneChange/sceneChange';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsStopDisplayContainer } from './winAnimations/slotsStopDisplayContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';
import WinSlotsContainer from './winAnimations/winSlotsContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).EV = eventManager;

class SlotMachine {
  private readonly application: PIXI.Application;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  public animator: Animator;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  private challengeResultNinja: ChallengeResult = ChallengeResult.NON;

  public static initSlotMachine = (
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(
      slotData,
      isSpinInProgressCallback,
      isSlotBusyCallback,
    );
  };

  private challengeGameCounter = 0;

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public miniPayTableContainer: MiniPayTableContainer;

  public winSlotsContainer: WinSlotsContainer;

  public slotStopDisplayContainer: SlotsStopDisplayContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public sceneChange: PIXI.Container;

  public infoBuyFeatureIcon: PIXI.Container;

  private constructor(
    slotData: ISlotData,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = new PIXI.Application({
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      width: GAME_CONTAINER_WIDTH,
      height: GAME_CONTAINER_HEIGHT,
    });
    this.application.renderer.once(EventTypes.POST_RENDER, () => {
      eventManager.emit(EventTypes.POST_RENDER);
    });
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.animator = new Animator(this.application);
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();
    // todo add if bonus logic

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotData.settings.startPosition;
    setPrevReelsPosition(startPosition.slice(0, 5));
    const reelSet = setUserLastBetResult().id
      ? slotData.reels.find(
          (reelSet) => reelSet.id === setUserLastBetResult().reelSetId,
        )!
      : slotData.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    //    this.reelsContainer.visible = false;
    this.tintContainer = new TintContainer();
    this.winSlotsContainer = new WinSlotsContainer();
    const spinResult = getSpinResult5x3({
      reelPositions: startPosition.slice(0, 5),
      reelSet,
      icons: slotData.icons,
    });
    this.slotStopDisplayContainer = new SlotsStopDisplayContainer(spinResult);
    //   this.slotStopDisplayContainer.visible = false;
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, true);

    this.miniPayTableContainer = new MiniPayTableContainer(
      slotData.icons,
      this.getSlotById.bind(this),
    );

    this.miniPayTableContainer.setSpinResult(spinResult, true);

    // this.menuBtn = new MenuBtn();
    // this.soundBtn = new SoundBtn();
    // this.turboSpinBtn = new TurboSpinBtn();
    // this.spinBtn = new SpinBtn();
    // this.betBtn = new BetBtn();
    // this.autoplayBtn = new AutoplayBtn();

    this.safeArea = new SafeArea();
    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.sceneChange = new SceneChange();
    this.gameView = new GameView({
      slotStopDisplayContainer: this.slotStopDisplayContainer,
      winSlotsContainer: this.winSlotsContainer,
      linesContainer: new LinesContainer(slotData.lines),
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
      miniPayTableContainer: this.miniPayTableContainer,
      lines: slotData.lines,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    this.safeArea.addChild(this.gameView);
    this.application.stage.addChild(this.safeArea);
    if (isBuyFeatureEnabled(slotData.clientSettings.features)) {
      this.initBuyFeature(slotData.lines, this.gameView);
    }
    this.menuBtn = new MenuBtn();
    this.soundBtn = new SoundBtn();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();

    this.initPixiLayers();
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      this.onBrokenGame();
    }
    this.application.stage.addChild(this.sceneChange);

    this.infoBuyFeatureIcon = new BuyFeatureBtnIcon();
  }

  private initPixiLayers() {
    this.application.stage.addChild(
      new Background(),
      new BottomContainer(),
      this.initSafeArea(),
      new FadeArea(),
    );
  }

  private initSafeArea(): SafeArea {
    const safeArea = new SafeArea();
    safeArea.addChild(this.gameView);
    return safeArea;
  }

  private initBuyFeature(lines: number[][], view: GameView): void {
    view.addChild(
      new BuyFeatureBtn(),
      new Backdrop(),
      new BuyFeaturePopup(lines),
      new BuyFeaturePopupConfirm(),
    );
  }

  private async onBrokenGame(): Promise<void> {
    const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);
    setIsFreeSpinsWin(true);
    setGameMode(gameMode);
    setCurrentBonusId(setCurrentBonus().id);
    setReelSetId(setCurrentBonus().reelSetId);
    eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
      mode: gameMode,
    });

    if (setCurrentFreeSpinsTotalWin()) {
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }
    eventManager.emit(EventTypes.CREATE_FREE_SPINS_TITLE, {
      text: 'freeSpinsTitleText',
      spins: setCurrentBonus().rounds,
      currentSpin: setCurrentBonus().currentRound,
    });

    if (setProgress().wasLoaded || setSkipIntroScreen()) {
      this.setState(SlotMachineState.IDLE);
    } else {
      eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        this.setState(SlotMachineState.IDLE);
      });
    }

    if (isChallengeMode(setGameMode())) {
      this.challengeGameCounter = setCurrentBonus().currentRound;
      setChallengeRemainRound(
        setCurrentBonus().rounds - setCurrentBonus().currentRound,
      );

      let challengeModeBackground;
      switch (Math.floor(setCurrentBonus().currentRound / 3)) {
        case 1:
          challengeModeBackground = 'challenge2';
          this.challengeResultNinja = ChallengeResult.NINJA_C;
          break;
        case 2:
          challengeModeBackground = 'challenge3';
          this.challengeResultNinja = ChallengeResult.NINJA_B;
          break;
        default:
          challengeModeBackground = 'challenge1';
          break;
      }

      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: GameMode.CHALLENGE_GAME,
        background: challengeModeBackground,
      });
    }
  }

  private initListeners(): void {
    eventManager.addListener(
      EventTypes.RESET_SLOT_MACHINE,
      this.resetSlotMachine.bind(this),
    );
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(
      EventTypes.SLOT_MACHINE_STATE_CHANGE,
      this.onStateChange.bind(this),
    );
    eventManager.addListener(
      EventTypes.REGISTER_ANIMATOR,
      this.registerAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REMOVE_ANIMATOR,
      this.removeAnimator.bind(this),
    );
    eventManager.addListener(
      EventTypes.REELS_STOPPED,
      this.onReelsStopped.bind(this),
    );
    eventManager.addListener(
      EventTypes.COUNT_UP_END,
      this.onCountUpEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.THROW_ERROR,
      this.handleError.bind(this),
    );
    eventManager.addListener(
      EventTypes.END_RETRIGGER_FEATURE,
      this.onRetriggerEnd.bind(this),
    );
    eventManager.addListener(
      EventTypes.CHANGE_MODE,
      this.onChangeMode.bind(this),
    );
    eventManager.addListener(
      EventTypes.START_BUY_FEATURE_ROUND,
      this.startBuyFeature.bind(this),
    );
    // eventManager.addListener(
    //   EventTypes.MANUAL_CHANGE_BACKGROUND,
    //   this.onChangeBackgroundMode.bind(this),
    // );

    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioHowl.play({ type: ISongs.BigWin_Loop });
      }
    });

    eventManager.addListener(EventTypes.SET_STATE, this.setState.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      this.challengeGameCounter = 0;
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find(
        (reels) => reels.id === settings.reelSetId,
      );
      const spinResult = getSpinResult5x3({
        reelPositions: settings.reelPositions.slice(0, 5),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });

      this.miniPayTableContainer.setSpinResult(spinResult, false);
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult, false);
    }
    setPrevReelsPosition(settings.reelPositions.slice(0, 5));
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);

    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
      });
      eventManager.emit(EventTypes.REMOVE_FREE_SPINS_TITLE);
      eventManager.emit(
        EventTypes.DISABLE_BUY_FEATURE_BTN,
        setIsContinueAutoSpinsAfterFeature(),
      );

      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();
    } else if (isChallengeMode(settings.mode)) {
      setScBonusId(setCurrentBonus().id);
    }

    if(this.nextResult){
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
    }

  }

  // private onChangeBackgroundMode(): void {
  //   const previousGameMode = setGameMode();
  //   // this.setState(SlotMachineState.IDLE);
  // }

  private startBuyFeature(): void {
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: GameMode.BUY_FEATURE,
      reelPositions: [0, 0, 0, 0, 0],
      reelSetId: reelSets[GameMode.REGULAR], // TODO  confirmation !
    });
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: [1, 1, 1],
      reelSetId: reelSets[GameMode.FREE_SPINS],
    });
  }

  private async endFreeSpins(): Promise<void> {
    const res = await client.query<{
      userBonuses: UserBonus[];
    }>({
      query: getUserBonuses,
      variables: { input: { id: setCurrentBonus().id } },
      fetchPolicy: 'network-only',
    });
    const bonus = res.data.userBonuses[0];
    let { betId } = res.data.userBonuses[0].data;
    if (betId === undefined) {
      betId = res.data.userBonuses[0].betId;
    }
    const bet = await client.query<ISettledBet>({
      query: slotBetGql,
      variables: { input: { id: betId } },
      fetchPolicy: 'network-only',
    });

    const { winCountAmount, reelSetId } = {
      winCountAmount: bet.data.bet.result.winCoinAmount,
      reelSetId: bet.data.bet.reelSetId,
    };
    let reelPositions = bet.data.bet.result.reelPositions;
    if (reelPositions.length === 0) {
      reelPositions = [0, 0, 0, 0, 0];
    }

    AudioHowl.play({ type: ISongs.TotalWinBanner, stopPrev: true });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      setIsSceneChange(true);
      eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON);
      eventManager.emit(EventTypes.SCENE_CHANGE_DOWN);
      eventManager.emit(EventTypes.CHANGE_MODE, {
        mode: GameMode.REGULAR,
        reelSetId,
        reelPositions,
      });

      setTimeout(() => {
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }, 100);

      setTimeout(() => {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(
            setCurrency(),
            normalizeCoins(setFreeSpinsTotalWin()),
            showCurrency(setCurrency()),
          ),
        );
      }, SCENE_CHANGE_FADE_TIME - 400);

      setTimeout(() => {
        setIsSceneChange(false);
        eventManager.emit(EventTypes.UPDATE_SPIN_BUTTON);
      }, SCENE_CHANGE_FADE_TIME);
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        preventDefaultDestroy: true,
        callback,
        title: i18n.t('youWon'),
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )}`,
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
        title: i18n.t('youWon'),
      });
    }
    setBrokenGame(false);
    this.challengeResultNinja = ChallengeResult.NON;
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t('error_general'),
      });
    }
  }

  private registerAnimator(animator: () => void, priority?: number) {
    if (priority !== undefined) {
      this.application.ticker.add(animator, undefined, priority);
    } else {
      this.application.ticker.add(animator);
    }
  }

  private removeAnimator(animator: () => void) {
    this.application.ticker.remove(animator);
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0].spinAnimation
      ?.getFakeRolling()
      .removeOnComplete(this.throwTimeoutError);
  }

  private updateFreeSpinsAmount(total: number, current: number): void {
    eventManager.emit(
      EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE,
      current.toString(),
      total.toString(),
      false,
    );
  }

  public spin(isTurboSpin: boolean | undefined): void {
    if (setGameMode() === GameMode.CHALLENGE_GAME) {
      return;
    }
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange();
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getScatterCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(
              this.nextResult.bet.result.spinResult,
            ),
          );
          this.stopSpin();
        }
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      this.isReadyForStop = false;
      eventManager.emit(EventTypes.START_SPIN_ANIMATION);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!!isTurboSpin);

      if (isFreeSpinMode(setGameMode())) {
        const bonus = setCurrentBonus();
        bonus.currentRound += 1;
        eventManager.emit(
          EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT,
          setCurrentBonus().rounds,
          bonus.currentRound,
          false,
        );
        setCurrentBonus(bonus);
      }

      spinAnimation.start();
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i];
      const spinAnimation: SpinAnimation =
        reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            if (isFreeSpinMode(setGameMode())) {
              this.updateFreeSpinsAmount(
                setCurrentBonus().currentRound,
                setCurrentBonus().rounds,
              );
            }
            this.removeErrorHandler();
            this.dynamicReelSetChange();
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getScatterCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(
                this.nextResult.bet.result.spinResult,
              ),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i].isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() =>
            eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin),
          );
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getBonusFromResult(): UserBonus | undefined {
    return (
      this.nextResult?.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetBonusReward' ,
      ) as BetBonusReward
    )?.userBonus;
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type != 'FREE_BET_ROUND';
    })?.userBonus;
  }

  private isFreeSpins(): boolean {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';

    return !!(
      this.nextResult?.rewards.filter(isBonusReward).find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.userBonus?.bonus.type != 'FREE_BET_ROUND',
      ) as BetBonusReward
    )?.userBonus;
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    // const bonus = this.getBonusFromResult();
    const mode = setGameMode();
    if (freeSpinsBonus) {
      if (mode === GameMode.BUY_FEATURE || mode === GameMode.REGULAR) {
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
        });
        setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
      }
    } else {
      if (mode === GameMode.BUY_FEATURE || mode === GameMode.REGULAR) {
        setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
      }
    }

    if (isFreeSpinMode(mode)) {
      setCurrentFreeSpinsTotalWin(
        setCurrentFreeSpinsTotalWin() +
          this.nextResult!.bet.result.winCoinAmount,
      );
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
      this.updateFreeSpinsAmount(
        setCurrentBonus().currentRound,
        setCurrentBonus().rounds,
      );
    }

    // on retrigger
    //      AudioHowl.play({ type: ISongs.FeatureReTrigger, stopPrev: true });

    setWinAmount(this.nextResult?.bet.result.winCoinAmount);
    if (freeSpinsBonus) {
      if (!isFreeSpinMode(mode)) {
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
        });
      }
    }

    if (freeSpinsBonus) {
      const challengeGameStartDelay = Tween.createDelayAnimation(
        CHALLENGE_GAME_START_DELAY,
      );
      challengeGameStartDelay.addOnComplete(() => {
        this.challengeGameStart();
      });
      challengeGameStartDelay.start();
    } else {
      this.setState(SlotMachineState.IDLE);
    }
    if( this.nextResult ){
      eventManager.emit(
        EventTypes.UPDATE_USER_BALANCE,
        this.nextResult?.balance.settled,
      );
    }
  }

  private challengeGameStart(): void {
    setIsFreeSpinsWin(true);
    setGameMode(GameMode.CHALLENGE_GAME);
    eventManager.emit(EventTypes.SCENE_CHANGE_DOWN);
    eventManager.emit(EventTypes.CHALLENGE_NINJA_INIT);

    const callback = () => {
      eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
      setNinjaPick(false);
      setChallengeGameMessageBanner(false);
      eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      this.setState(SlotMachineState.IDLE);
    };

    setTimeout(() => {
      eventManager.emit(
        EventTypes.UPDATE_TOTAL_WIN_VALUE,
        setCurrentFreeSpinsTotalWin(),
      );
    }, SCENE_CHANGE_FADE_TIME - 400);

    setTimeout(() => {
      setChallengeGameMessageBanner(true);
      if (!setIsContinueAutoSpinsAfterFeature()) {
        eventManager.emit(
          EventTypes.CREATE_CHALLENGE_GAME_START_MESSAGE_BANNER,
          {
            title: i18n.t('ChallengeGameTitle'),
            description1: i18n.t('challengeGameDescription1'),
            btnText: i18n.t('pressToStart'),
            preventDefaultDestroy: true,
            callback,
          },
        );
      } else {
        eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
        setNinjaPick(false);
        setChallengeGameMessageBanner(false);
        this.setState(SlotMachineState.IDLE);
      }

      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: GameMode.CHALLENGE_GAME,
        background: 'challenge1',
      });
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
      eventManager.emit(EventTypes.CHALLENGE_NINJA_START);
    }, SCENE_CHANGE_BLACK_SCREEN_ANIMATION_TIME);
  }

  private dynamicReelSetChange(): void {
    if (setReelSetId() !== reelSets[setGameMode()]) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find(
          (reels) => reels.id === reelSets[setGameMode()],
        )!,
        reelPositions: [0, 0, 0, 0, 0],
      });
      setReelSetId(reelSets[setGameMode()]);
    }
  }

  private onRetrigger(userBonus: UserBonus | undefined): void {
    // todo throw error correctly
    if (userBonus === undefined) throw new Error('Bonus not found');
    eventManager.emit(EventTypes.CHANGE_MODE, {
      mode: getGameModeByReelSetId(userBonus.bonus.reelSetId),
      reelPositions: this.nextResult!.bet.result.reelPositions,
      reelSetId: userBonus.bonus.reelSetId,
      isRetrigger: true,
    });
  }

  private onRetriggerEnd(): void {
    this.updateFreeSpinsAmount(
      setCurrentBonus().currentRound,
      setCurrentBonus().rounds,
    );
    this.setState(SlotMachineState.IDLE);
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
  }

  private getAnticipationStartReelId(spinResult: Array<Icon>): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;
    let minReelId = REELS_AMOUNT;
    _.forEach(ANTICIPATION_SYMBOLS_ID, (symbolId, i) => {
      const count = ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME[i];
      let currentCount = 0;
      for (let j = 0; j < REELS_AMOUNT; j++) {
        // eslint-disable-next-line no-plusplus
        if (spinResult[j].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT].id === symbolId) currentCount++;
        // eslint-disable-next-line no-plusplus
        if (spinResult[j + REELS_AMOUNT * 2].id === symbolId) currentCount++;

        if (currentCount >= count && j + 1 < REELS_AMOUNT)
          minReelId = Math.min(minReelId, ANTICIPATION_LONG_SPIN_REEL);
      }
    });
    return minReelId;
  }

  private getScatterCount(spinResult: Array<Icon>): Array<number> {
    let count = 0;
    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.SC1)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    if (setGameMode() === GameMode.CHALLENGE_GAME) {
      const coinReward = this.getChallengeCoinReward(result);
      // let bonusReward = 0;
      // if (this.getChallengeBonusRewardNum(result) > 0) {
      //   bonusReward = this.getChallengeBonusReward(result);
      // }

      const userBonusId = this.isChallengeBonusId(result);
      setCurrentBonusId(userBonusId);

      const challengeGameRewardData = setPreLoadedGrantBreakdown();

      const rewardData =
        challengeGameRewardData[this.challengeGameCounter].grants[0].grants;
      let challengeResult = ChallengeResult.COINS;
      let reward = 0;
      if (rewardData[0].type === 'COINS') {
        challengeResult = ChallengeResult.COINS;
        reward = this.getChallengeCoinReward(result);
      } else if (rewardData[0].type === 'BONUS') {
        reward = (rewardData[0] as BonusGrants)?.count;

        if (reward > 0) {
          challengeResult = ChallengeResult.FREE_SPIN;
        } else {
          switch (this.challengeResultNinja) {
            case ChallengeResult.NON:
              challengeResult = ChallengeResult.NINJA_C;
              break;
            case ChallengeResult.NINJA_C:
              challengeResult = ChallengeResult.NINJA_B;
              break;
            case ChallengeResult.NINJA_B:
              challengeResult = ChallengeResult.NINJA_A;
              break;
            default:
              break;
          }
          this.challengeResultNinja = challengeResult;
        }
      }

      eventManager.emit(
        EventTypes.CHALLENGE_NINJA_SPIN_RESULT,
        challengeResult,
        reward,
      );

      this.isSpinInProgressCallback();
      this.challengeGameCounter += 1;
    }

    const spinResult = getSpinResult5x3({
      reelPositions: result.bet.result.reelPositions.slice(0, 5),
      reelSet: setSlotConfig().reels.find(
        (reelSet) => reelSet.id === result.bet.reelSet.id,
      )!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    this.nextResult = result;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, 5));

    setNextResult(result);

    eventManager.emit(
      EventTypes.UPDATE_USER_BALANCE,
      this.nextResult.balance.placed,
    );

    const userBonusId = this.isChallengeBonusId(result);
    if (userBonusId !== undefined) {
      const challengeRemainRound = this.getChallengeRemainRound(result);
      setChallengeBonusId(userBonusId);
      if (setChallengeRemainRound() === 0) {
        setChallengeRemainRound(challengeRemainRound);
      }
      setCurrentBonus({
        ...setCurrentBonus(),
        //       bonus:
      });

      setPreLoadedGrantBreakdown(this.getPreLoadedGrantBreakdown(result));
    }

    if (setGameMode() === GameMode.CHALLENGE_GAME) {
      eventManager.emit(
        EventTypes.CHALLENGE_NINJA_CLICK,
        this.gameView.gameTitle.challengeGameContainer.getNinjaIndex(),
      );
    }
  }

  private getPreLoadedGrantBreakdown(
    result: ISettledBet,
  ): PreLoadedGrantBreakdown[] {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    return (
      result.rewards.filter(isBonusReward).find(
        // eslint-disable-next-line no-underscore-dangle
      (reward) => reward.userBonus?.bonus.type != 'FREE_BET_ROUND'
      ) as BetBonusReward
    )?.userBonus?.data!.preLoadedGrantBreakdown!;
  }

  private isChallengeBonusId(result: ISettledBet): string {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';
    return (
      result.rewards.filter(isBonusReward).find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.userBonus?.bonus.type != 'FREE_BET_ROUND'
      ) as BetBonusReward
    )?.userBonusId;
  }

  private getChallengeRemainRound(result: ISettledBet): number {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward =>
      reward.__typename === 'BetBonusReward';
    return (
      result.rewards.filter(isBonusReward).find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.userBonus?.bonus.type != 'FREE_BET_ROUND'
      ) as BetBonusReward
    )?.userBonus!.rounds;
  }

  private getChallengeCoinReward(result: ISettledBet): number {
    return (
      result.rewards.find(
        // eslint-disable-next-line no-underscore-dangle
        (reward) => reward.__typename === 'BetCoinReward',
      ) as BetCoinReward
    )?.amount;
  }

  private getChallengeBonusRewardNum(result: ISettledBet): number {
    return result.rewards.filter(
      (reward) => reward.__typename === 'BetBonusReward',
    ).length;
  }

  private getChallengeBonusReward(result: ISettledBet): number {
    return (result.rewards.find(
      (reward) => reward.__typename === 'BetBonusReward',
    ) as BetBonusReward)!.userBonus!.rounds!;
  }

  public onSpinStop(isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.miniPayTableContainer.setSpinResult(
      this.nextResult!.bet.result.spinResult,
      false,
      setNextResult()!.bet.result.reelPositions,
      setNextResult()!.bet.reelSet.layout,
    );
    this.setSpinResult(
      this.nextResult!.bet.result.spinResult,
      setNextResult()!.bet.result.reelPositions,
      setNextResult()!.bet.reelSet.layout,
    );
    this.setState(SlotMachineState.JINGLE);
  }

  public setSpinResult(
    spinResult: Icon[],
    reelPosition?: number[],
    reelLength?: SlotId[][],
  ): void {
    const ReelPosition: number[] = [];
    const extendedSpinResult = getExtendedSpinResult(spinResult, reelPosition);

    extendedSpinResult.forEach((icon, index: number) => {
      let reelId = 0;

      const col = Math.floor(index % REELS_AMOUNT);
      const row = Math.floor(index / REELS_AMOUNT);
      const ReelIdMiddle = extendedSpinResult[REELS_AMOUNT * 1 + col];
      const ReelIdUpper = extendedSpinResult[REELS_AMOUNT * 0 + col];
      const ReelIdLower = extendedSpinResult[REELS_AMOUNT * 2 + col];

      // SlotId to ReelPostion conversion
      // ReelPostion is invalid except for the expansion Slot
      //   A3 A3 A3 WL D    ⇒ 0  1  2  2  2
      //   A3 A3 WL A3 G    ⇒ 1  2  1  0  1
      //   A3 D  E  A3 A3   ⇒ 2  0  0  1  0

      // 0 ⇒ Upper row of expansion Slot
      // 1 ⇒ Middle of expansion Slot
      // 2 ⇒ Bottom of expansion Slot

      let targetReelId: SlotId;

      if (row === 0) {
        targetReelId = ReelIdUpper;
      } else if (row === 1) {
        targetReelId = ReelIdMiddle;
      } else {
        targetReelId = ReelIdLower;
      }

      if (ReelIdUpper === ReelIdMiddle && ReelIdUpper === ReelIdLower) {
        reelId = row;
      } else if (
        isExtendedSlot(ReelIdMiddle) &&
        ReelIdMiddle === ReelIdLower &&
        targetReelId === ReelIdMiddle
      ) {
        reelId = row - 1;
      } else if (
        isExtendedSlot(ReelIdMiddle) &&
        ReelIdUpper === ReelIdMiddle &&
        targetReelId === ReelIdMiddle
      ) {
        reelId = row + 1;
      } else {
        reelId = 2 - row;
      }

      ReelPosition.push(reelId);
    });

    setExtendedSlotPos(ReelPosition);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x].slots[
      (2 * this.reelsContainer.reels[x].data.length -
        this.reelsContainer.reels[x].position +
        y -
        1) %
        this.reelsContainer.reels[x].data.length
    ];
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(width: number, height: number): void {
    this.application.renderer.resize(width, height);
  }

  private setState(state: SlotMachineState): void {
    if( state === SlotMachineState.IDLE && this.state === SlotMachineState.IDLE && !setBrokenGame() && setGameMode() != GameMode.CHALLENGE_GAME){
      return;
    }
    this.state = state;
    eventManager.emit(
      EventTypes.DISABLE_PAY_TABLE,
      isFreeSpinMode(setGameMode()) || isChallengeMode(setGameMode())
        ? false
        : state === 0,
    );
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    return this.nextResult!.bet.result.winCoinAmount > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE ||
        setIsFreeSpinsWin() ||
        setIsContinueAutoSpinsAfterFeature(),
    );

    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }

      if (isFreeSpinMode(setGameMode())) {
        if (
          // TODO setCurrentBonus().isActive &&
          setCurrentBonus().rounds === setCurrentBonus().currentRound
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          this.endFreeSpins();
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      } else if (
        isChallengeMode(setGameMode()) &&
        setIsContinueAutoSpinsAfterFeature()
      ) {
        this.skipAnimations();
        setTimeout(() => eventManager.emit(EventTypes.SPACE_KEY_SPIN), 500);
      }
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.isFreeSpins() && !isFreeSpinMode(setGameMode())) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.FeatureTrigger].duration,
        );
        jingleDelay.addOnStart(() => {
          AudioHowl.play({ type: ISongs.FeatureTrigger, stopPrev: true });
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });
        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(
          EventTypes.START_WIN_ANIMATION,
          this.nextResult!,
          false,
        );
      } else {
        if( this.nextResult ){
          eventManager.emit(
            EventTypes.UPDATE_USER_BALANCE,
            this.nextResult?.balance.settled,
          );
        }
        this.setState(SlotMachineState.IDLE);
      }
    }
  }
}

export default SlotMachine;
function isSlotBusy(arg0: boolean) {
  throw new Error('Function not implemented.');
}
