import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IUserBalance } from '../../global.d';
import { getUserGql, setSlotConfig } from '../../gql';
import { ISlotConfig } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { formatNumber } from '../../utils';

import styles from './info.module.scss';

const GameRules: React.FC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);

  const {
    user: { balance },
  } = userData!;
  const betSettings = getBetsSetting();
  const { currency } = balance;
  const minBet = formatNumber(currency, betSettings.minBetCount);
  const maxBet = formatNumber(currency, betSettings.maxBetCount);
  const version = window.__ENV__?.APP_VERSION ?? 'develop';

  const gameRules = [
    { key: 'infoGameRules1_1', text: i18n.t('infoGameRules1_1') },
    { key: 'infoGameRules1_2', text: i18n.t('infoGameRules1_2') },
    { key: 'infoGameRules1_3', text: i18n.t('infoGameRules1_3') },
    { key: 'infoGameRules1_4', text: i18n.t('infoGameRules1_4') },
    { key: 'infoGameRules1_5', text: i18n.t('infoGameRules1_5') },
    { key: 'infoGameRules1_6', text: i18n.t('infoGameRules1_6') },
    { key: 'infoGameRules1_7', text: i18n.t('infoGameRules1_7') },
    { key: 'infoGameRules1_8', text: i18n.t('infoGameRules1_8') },
    { key: 'infoGameRules1_9', text: i18n.t('infoGameRules1_9') },
    { key: 'infoGameRules1_10', text: i18n.t('infoGameRules1_10') },
    { key: 'infoGameRules1_11', text: i18n.t('infoGameRules1_11') },
  ];

  return (
    <div className={styles.gameRules}>
      <h1 className={styles.title}>{t('infoGameRulesTitle')}</h1>
      <div className={`${styles.p2} ${styles.left}`}>
        {gameRules.map((v) => (
          <div key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
      <div className={`${styles.p2} ${styles.left}`}>
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules2', {
              minBet,
            }),
          }}
        />
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules3', {
              maxBet,
            }),
          }}
        />
      </div>
      <div className={`${styles.p2} ${styles.left}`}>
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules4', {
              rtp: '96.49%',
            }),
          }}
        />
        <div
          className={`${styles.p} ${styles.left}`}
          dangerouslySetInnerHTML={{
            __html: t('infoGameRules5', {
              rtpBuy: '96.79%',
            }),
          }}
        />
      </div>
      <div
        className={`${styles.p} ${styles.left}`}
        dangerouslySetInnerHTML={{
          __html: t('infoGameRulesVersion', {
            version,
          }),
        }}
      />
    </div>
  );
};

export default GameRules;
