import { BonusStatus, EventTypes, GameMode, UserBonus, freeRoundBonusId } from '../../global.d';
import {
  setCurrentBonus, // setCurrentStage,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsFreeRoundBonus,
  setIsTimeoutErrorMessage,
  setNextResult,
  setStressful,
  setWinAmount,
} from '../../gql/cache';
import i18n from '../../i18next';
import { calcActiveUserBonusTotalWinAmount, isChallengeMode, isFreeSpinMode, isRegularMode } from '../../utils';
import Tween from '../animations/tween';
// import { calcActiveUserBonusTotalWinAmount, isBaseGameMode, isCroonBonusMode } from '../../utils';
import { SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME, SlotMachineState, eventManager } from '../config';

import { FreeSpinEndState } from './d';
import { FreeRoundsPopup } from './freeRoundsPopup';
import { FreeRoundsEndPopup } from './freeRoundsPopupEnd';
import { getFreeRoundBonus, getUserActiveBonuses, isGetFreeSpinBonus } from './helper';

const finishedFreeRounds = () => {
  return (
    setFreeRoundBonus().currentRound === setFreeRoundBonus().rounds &&
    setFreeRoundBonus().isActive &&
    !setCurrentBonus().isActive
  );
};

const openEndPopup = () => {
  if (!setFreeRoundBonus().isActive) return;
  setFreeRoundBonus({ ...setFreeRoundBonus(), isActive: false });
  eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END);
};

export class FreeRoundBonus {
  private freeRoundsPopup: FreeRoundsPopup;

  private freeRoundsEndPopup: FreeRoundsEndPopup;

  private freeSpinEndState: FreeSpinEndState = 'none';

  private freeRoundBonusAtEndOfFreeSpins: UserBonus | null = null;

  constructor() {
    this.freeRoundsPopup = new FreeRoundsPopup();
    this.freeRoundsEndPopup = new FreeRoundsEndPopup();

    if (setFreeRoundBonus().isActive) {
      setIsFreeRoundBonus(true);
      if (!setCurrentBonus().isActive) {
        eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS, setFreeRoundBonus().rounds);
      } else {
        if (setFreeRoundsTotalWin() > 0) {
          this.showTotalWinValue();
        }
      }
    }

    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.slotMachineStateChange.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, () => {
      if (!setIsFreeRoundBonus()) return;
      if (setIsTimeoutErrorMessage()) return;
      this.updateRounds();
    });
    eventManager.addListener(EventTypes.UPDATE_TOTAL_WIN_VALUE2, (win: number) => {
      if (win > 0) {
        setFreeRoundsTotalWin(setFreeRoundsTotalWin() + win);
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }
    });
    eventManager.addListener(EventTypes.CHALLENGE_NINJA_INIT, () => {
      if (setFreeRoundsTotalWin() + setWinAmount() > 0) {
        setFreeRoundsTotalWin(setFreeRoundsTotalWin() + setWinAmount());
        eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
      }
    });
    eventManager.addListener(EventTypes.INVALID_BONUS_CHECK, this.invalidBonusCheck.bind(this));

    eventManager.addListener(EventTypes.ROLLBACK_REELS, () => {
      if (setNextResult()) {
        setNextResult()!.bet.result.winCoinAmount = 0;
      }
    });
  }

  private updateTotalWinValue() {
    // const winAmount = setCurrentBonus().data.pachiCroonRounds[setCurrentStage()]!.coinReward * setBetAmount();
    // setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
    setFreeRoundsTotalWin(setFreeRoundsTotalWin());

    if (setFreeRoundsTotalWin() > 0) {
      this.showTotalWinValue();
    }
  }

  private async invalidBonusCheck() {
    const bonuses = await getUserActiveBonuses();
    const freeRoundBonus = bonuses.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId) as UserBonus;
    const isFreeRoundBonus = freeRoundBonus;
    const remainingRounds = setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound;

    if (setFreeRoundBonus().isActive) {
      if (!isFreeRoundBonus && remainingRounds > 0) {
        setStressful({
          show: true,
          type: 'network',
          message: i18n.t('errors.OPERATOR.INVALID_BONUS'),
          callback: () => {
            setFreeRoundBonus({
              ...setFreeRoundBonus(),
              isActive: false,
            });
            eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS_END, true);
          },
        });
      }
    }
  }

  private updateRounds() {
    if (setFreeRoundBonus().isActive && isRegularMode(setGameMode())) {
      setFreeRoundBonus({
        ...setFreeRoundBonus(),
        currentRound: setFreeRoundBonus().currentRound + 1,
      });
      eventManager.emit(
        EventTypes.UPDATE_FREE_ROUNDS_LEFT,
        setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
      );
    }
  }

  private startFreeRoundBonusDuringBaseGame(freeRoundBonus: UserBonus) {
    const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
    setFreeRoundBonus({
      id: freeRoundBonus.id,
      bonusId: freeRoundBonus.bonusId,
      coinAmount: freeRoundBonus.coinAmount,
      coinValue: freeRoundBonus.coinValue,
      status: freeRoundBonus.status,
      rounds: freeRoundBonus.rounds,
      totalWinAmount: totalWinAmount,

      isActive: true,
      currentRound: 0,
    });
    setFreeRoundsTotalWin(totalWinAmount);

    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
    eventManager.emit(EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS, {
      inDuration: 2000,
      outDuration: 1000,
      callback: () => {
        setIsFreeRoundBonus(true);
        eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS, setFreeRoundBonus().rounds);
        eventManager.emit(
          EventTypes.UPDATE_FREE_ROUNDS_LEFT,
          setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
        );
      },
    });
  }

  private showTotalWinValue() {
    eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
  }

  private async slotMachineStateChange(state: SlotMachineState) {
    if (state !== SlotMachineState.IDLE) return;

    const nextResult = setNextResult();
    if (!nextResult) return;

    const gameMode = setGameMode();

    if (gameMode === GameMode.CHALLENGE_GAME) return;

    if (!setIsFreeRoundBonus()) {
      if (isRegularMode(gameMode) && !isGetFreeSpinBonus(nextResult) && this.freeSpinEndState === 'none') {
        const freeRoundBonus = getFreeRoundBonus(nextResult);
        // TODO(FRB) start during game
        /* const freeRoundBonus: UserBonus = {
          ...setCurrentBonus(),
          rounds: 2,
           coinAmount: 1,
          //coinValue: 1000, // KRW
          coinValue: 1, // EUR
        }; */

        if (freeRoundBonus && freeRoundBonus.status !== BonusStatus.SETTLED) {
          const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
          setFreeRoundBonus({
            id: freeRoundBonus.id,
            bonusId: freeRoundBonus.bonusId,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            status: freeRoundBonus.status,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: totalWinAmount,

            isActive: true,
            currentRound: 0,
          });
          setFreeRoundsTotalWin(totalWinAmount);

          eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
          eventManager.emit(EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS, {
            inDuration: 2000,
            outDuration: 1000,
            callback: () => {
              setIsFreeRoundBonus(true);
              eventManager.emit(EventTypes.OPEN_POPUP_FREE_ROUNDS, setFreeRoundBonus().rounds);
              eventManager.emit(
                EventTypes.UPDATE_FREE_ROUNDS_LEFT,
                setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
              );
            },
          });
        }
      }
      return;
    }

    if (!setIsFreeRoundBonus()) return;

    const winAmount = setNextResult()!.bet.result.winCoinAmount;
    if (winAmount > 0) {
      setFreeRoundsTotalWin(setFreeRoundsTotalWin() + winAmount);
      eventManager.emit(EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE, setFreeRoundsTotalWin());
    }

    if (finishedFreeRounds()) {
      if (isRegularMode(gameMode) && !isGetFreeSpinBonus(nextResult!)) {
        if (this.freeSpinEndState === 'none') {
          openEndPopup();
        }
      }
    }
  }

  private onChangeMode(settings: { mode: GameMode }) {
    const mode = settings.mode;

    if (setIsFreeRoundBonus() && (isFreeSpinMode(mode) || isChallengeMode(mode))) {
      if (setFreeRoundsTotalWin() > 0) {
        setTimeout(() => {
          this.showTotalWinValue();
        });
      }
    } else {
      if (finishedFreeRounds()) {
        const delay = Tween.createDelayAnimation(SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME);
        delay.addOnComplete(() => {
          openEndPopup();
        });
        delay.start();
      } else if (!this.freeRoundsPopup.isOnceOpened) {
        if (setIsFreeRoundBonus() && isRegularMode(mode)) {
          if (this.freeRoundBonusAtEndOfFreeSpins) {
            setFreeRoundsTotalWin(calcActiveUserBonusTotalWinAmount(this.freeRoundBonusAtEndOfFreeSpins!));
          }

          const delay = Tween.createDelayAnimation(SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME);
          delay.addOnComplete(() => {
            eventManager.emit(
              EventTypes.OPEN_POPUP_FREE_ROUNDS,
              setFreeRoundBonus().rounds - setFreeRoundBonus().currentRound,
            );
          });
          delay.start();
        }
      }
      this.freeSpinEndState = 'none';
    }
  }
}
