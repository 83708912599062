import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, SlotId, SlotIdPriority, SymbolAnimationType } from '../../config';
import { EventTypes, ISettledBet } from '../../global.d';
import { setExtendedSlotPos, setHighLightSlotPos, setIsHighLightSlots, setSlotConfig } from '../../gql';
import { destroySpine, getExtendedSpinResult, isExtendedSlot } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import SpriteAnimation from '../animations/sprite';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  APPLICATION_FPS,
  REELS_AMOUNT,
  REEL_WIDTH,
  SHOW_ALL_LINES_ON_WIN,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPRITE_ANIMATION_FPS,
  TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT,
  WIN_SLOT_ANIMATION_DURATION,
  WIN_SLOT_ANIMATION_SCALE,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';
import { Slot } from '../slot/slot';

class WinSlotsContainer extends ViewContainer {
  private winSlotsContainer: ViewContainer | null = null;

  private slotsContainer: Slot[][];

  private spineAnimations: SpineAnimation[] = [];

  public animation: AnimationChain | null = null;

  public loopAnimation: Animation | null = null;

  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer = [];
    this.sortableChildren = true;
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));

    const slotsArray = [];
    const container = new ViewContainer();
    container.width = SLOT_WIDTH * REELS_AMOUNT;
    container.height = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
    for (let j = 0; j < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; j++) {
      const slot = new Slot(j % 3, SlotId.A);
      slot.anchor.set(0.5, 0.5);
      slot.x = SLOT_WIDTH * Math.floor(j / 3);
      slot.y += SLOT_HEIGHT / 2;
      slot.visible = false;
      slotsArray.push(slot);
      container.addChild(slot);
    }
    this.slotsContainer.push(slotsArray);
    this.winSlotsContainer = container;
    this.addChild(container);
  }

  private getIconBySlotId(slotId: SlotId): Icon {
    return _.find(setSlotConfig().icons, (icon) => icon.id === slotId)!;
  }

  private onStartWinAnimation(nextResult: ISettledBet, isTurboSpin: boolean) {
    this.cleanUpSpineAnimations();
    this.showWin(nextResult, isTurboSpin);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.loopAnimation?.skip();
    this.cleanUpSpineAnimations();

    this.animation = null;
    this.loopAnimation = null;
  }

  public highlightSlots(slots: number[], spinResult: SlotId[], isTurboSpin: boolean | undefined): AnimationGroup {
    const animationGroup = new AnimationGroup({});
    const buff: number[] = [];
    slots.forEach((slotId) => {
      if (MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId]].type === SymbolAnimationType.SPINE) {
        let x3flg = false;

        if (isExtendedSlot(spinResult[slotId])) {
          x3flg = true;
        }
        animationGroup.addAnimation(
          this.createSlotSpineAnimation(
            slotId,
            MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId]].src!,
            MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId]].animation!,
            !!isTurboSpin,
            x3flg,
            spinResult[slotId],
          ),
        );
      }

      if (MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId]].type === SymbolAnimationType.SPRITE) {
        const sheet = this.getSlotAnimationSheet(spinResult[slotId])!;
        animationGroup.addAnimation(this.createSlotSpriteAnimation(sheet, slotId, isTurboSpin));
      }

      if (MAPPED_SYMBOLS_ANIMATIONS[spinResult[slotId]].type === SymbolAnimationType.DEFAULT) {
        const slot = this.slotsContainer[slotId % 5][Math.floor(slotId / 5)];
        slot.texture = PIXI.Texture.from(MAPPED_SYMBOLS[spinResult[slotId]]);
        slot.visible = false;
        animationGroup.addAnimation(this.createSlotScaleAnimation(slot, isTurboSpin));
        animationGroup.addOnStart(() => {
          slot.visible = true;
        });
      }
    });
    animationGroup.addOnStart(() => {
      setIsHighLightSlots(true);
      setHighLightSlotPos(slots);
      eventManager.emit(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, [...slots], false);
    });
    animationGroup.addOnComplete(() => {
      setHighLightSlotPos([]);
      eventManager.emit(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    animationGroup.addOnSkip(() => {
      setIsHighLightSlots(false);
      setHighLightSlotPos([]);
      eventManager.emit(EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY, [...slots], true);
      this.hideAllSlots();
    });
    return animationGroup;
  }

  private createSlotSpineAnimation(
    id: number,
    srcName: string,
    animationName: string,
    isTurboSpine: boolean,
    isX3: boolean,
    slotId: SlotId,
  ): Animation {
    const dummy = Tween.createDelayAnimation(2000);
    let animation: SpineAnimation | undefined;
    dummy.addOnStart(() => {
      animation = this.spineAnimations[id];
      if (animation === undefined) {
        animation = new SpineAnimation({}, PIXI.Loader.shared.resources[srcName].spineData!);
        animation.spine.x = (id % REELS_AMOUNT) * REEL_WIDTH + REEL_WIDTH / 2;
        animation.spine.y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
        const position = setExtendedSlotPos();
        if (isX3) {
          animation.spine.y -= position[id] * SLOT_HEIGHT - SLOT_HEIGHT;
        }

        this.spineAnimations[id] = animation;
        this.spineAnimations[id].getSpine().zIndex = SlotIdPriority[slotId] + id;
      }
      this.winSlotsContainer!.addChild(animation.spine);
      if (animation.spine.state.tracks[0]) {
        animation.spine.state.tracks[0].trackTime = 0;
      }
      animation.spine.state.clearTrack(0);
      animation.spine.state.setAnimation(0, animationName, true);
    });

    dummy.addOnComplete(() => {
      if (animation?.spine) this.winSlotsContainer!.removeChild(animation.spine);
    });
    dummy.addOnSkip(() => {
      if (animation?.spine) this.winSlotsContainer!.removeChild(animation.spine);
    });
    return dummy;
  }

  private getSlotAnimationSheet(slotId: SlotId): PIXI.Spritesheet | undefined {
    return _.get(PIXI.Loader.shared.resources, MAPPED_SYMBOLS_ANIMATIONS[slotId].src!).spritesheet;
  }

  private createSlotSpriteAnimation(sheet: PIXI.Spritesheet, id: number, isTurboSpin: boolean | undefined): Animation {
    const animatedSprite = new SpriteAnimation({}, Object.values(sheet?.textures));
    animatedSprite.spriteAnimation.animationSpeed =
      (isTurboSpin ? SPRITE_ANIMATION_FPS * TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT : SPRITE_ANIMATION_FPS) /
      APPLICATION_FPS;
    animatedSprite.spriteAnimation.x = SLOT_WIDTH / 2;
    animatedSprite.spriteAnimation.y = SLOT_HEIGHT * Math.floor(id / REELS_AMOUNT) + SLOT_HEIGHT / 2;
    const container = this.winSlotsContainer!;
    animatedSprite.addOnStart(() => {
      container.addChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnSkip(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    animatedSprite.addOnComplete(() => {
      container.removeChild(animatedSprite.spriteAnimation);
    });
    return animatedSprite;
  }

  private createSlotScaleAnimation(sprite: PIXI.Sprite, isTurboSpin: boolean | undefined): AnimationGroup {
    const animation: AnimationGroup = new AnimationGroup({});
    const { x, y } = sprite.scale;
    const animationChainX = new AnimationChain();
    const animationDuration = isTurboSpin ? WIN_SLOT_ANIMATION_DURATION / 4 : WIN_SLOT_ANIMATION_DURATION / 2;
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x,
        target: x * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainX.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.X,
        propertyBeginValue: x * WIN_SLOT_ANIMATION_SCALE,
        target: x,
        duration: animationDuration,
      }),
    );
    const animationChainY = new AnimationChain();
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y,
        target: y * WIN_SLOT_ANIMATION_SCALE,
        duration: animationDuration,
      }),
    );
    animationChainY.appendAnimation(
      new Tween({
        object: sprite.scale,
        property: TweenProperties.Y,
        propertyBeginValue: y * WIN_SLOT_ANIMATION_SCALE,
        target: y,
        duration: animationDuration,
      }),
    );
    animation.addAnimation(animationChainX);
    animation.addAnimation(animationChainY);
    return animation;
  }

  public hideAllSlots(): void {
    for (let i = 0; i < this.slotsContainer.length; i++) {
      for (let j = 0; j < this.slotsContainer[i].length; j++) {
        this.slotsContainer[i][j].visible = false;
        this.slotsContainer[i][j].scale.set(1, 1);
      }
    }
  }

  private showWin(nextResult: ISettledBet, isTurboSpin: boolean | undefined): void {
    const { paylines } = nextResult;
    const { spinResult } = nextResult.bet.result;
    const reelPosition = nextResult.bet.result.reelPositions;
    this.animation = new AnimationChain();
    const set = new Set<number>();
    const extendedSpinResult = getExtendedSpinResult(spinResult, reelPosition);
    paylines.forEach((payline) => {
      payline.winPositions.forEach((position) => {
        set.add(position);
      });
    });
    const allSlotsHighlight = this.highlightSlots(Array.from(set), extendedSpinResult, isTurboSpin);
    this.animation.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_TINT, true);
    });
    allSlotsHighlight.addOnStart(() => {
      eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
    });
    allSlotsHighlight.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      this.hideAllSlots();
    });
    allSlotsHighlight.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (SHOW_ALL_LINES_ON_WIN) this.animation.appendAnimation(allSlotsHighlight);
    const animationChain = this.createHighlightChainAnimation(paylines, spinResult, reelPosition, isTurboSpin, false);
    this.loopAnimation = this.createHighlightChainAnimation(paylines, spinResult, reelPosition, isTurboSpin, true);
    this.loopAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      this.hideAllSlots();
    });
    if (paylines.length > 1) this.animation.appendAnimation(animationChain);
    animationChain.addOnSkip(() => {
      eventManager.emit(EventTypes.HIDE_ALL_WIN_LINES);
      eventManager.emit(EventTypes.SHOW_TINT, false);
    });
    animationChain.addOnComplete(() => {
      eventManager.emit(EventTypes.HIDE_ALL_WIN_LINES);
    });
    this.animation.addOnComplete(() => this.loopAnimation?.start());
    this.animation.start();
  }

  public createHighlightChainAnimation(
    paylines: IWinLine[],
    spinResult: Icon[],
    reelPosition: number[],
    isTurboSpin: boolean | undefined,
    isLoop: boolean,
  ): Animation {
    const extendedSpinResult = getExtendedSpinResult(spinResult, reelPosition);

    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions, extendedSpinResult, isTurboSpin);
      payline.winPositions.forEach((icon, i) => {
        if (isExtendedSlot(extendedSpinResult[icon])) {
          if (icon >= 5) {
            const inx = icon - 5;
            if (extendedSpinResult[inx] === extendedSpinResult[icon]) {
              if (!payline.winPositions.includes(inx)) payline.winPositions.push(inx);
            }
          }
        }
      });
      chain.addOnStart(() => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
      });
      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      });
      chain.addOnSkip(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
      });
      animationChain!.appendAnimation(chain);
    });
    return animationChain;
  }

  private cleanUpSpineAnimations(): void {
    for (let i = 0; i < this.spineAnimations.length; i++) {
      if (this.spineAnimations[i]) {
        destroySpine(this.spineAnimations[i]);
      }
    }
    this.spineAnimations = [];
  }
}

export default WinSlotsContainer;
