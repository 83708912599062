import { SlotId } from '../../config';

import { Slot } from './slot';

export class Slot1 extends Slot {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(id: number, slotId: SlotId) {
    super(id, slotId);
  }
}
