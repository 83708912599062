import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isChallengeMode } from '../../../utils';
import { BgSkin } from '../../background/background';
import ViewContainer from '../../components/container';
import { ChallengeGameReelBackground, REELS_AMOUNT, REEL_WIDTH, SLOT_WIDTH, eventManager } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private lights: PIXI.Sprite[] = [];

  private reelContainer: PIXI.Container[] = [];

  private textureChallengeBase: PIXI.Texture[];

  private spriteChallengeBase = new PIXI.Sprite();

  constructor() {
    super();

    this.textureChallengeBase = [
      PIXI.Texture.from(ResourceTypes.challengegameBase),
      PIXI.Texture.from(ResourceTypes.challengegameBase2),
      PIXI.Texture.from(ResourceTypes.challengegameBase3),
    ];

    this.initChallengeGameReelBackground();
    this.reelContainer = this.initReelBackground();

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private onModeChange(settings: { mode: GameMode }): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      if (isChallengeMode(settings.mode)) {
        this.reelContainer[i].visible = false;
      } else {
        this.reelContainer[i].visible = true;
      }
    }
  }

  private initChallengeGameReelBackground(): void {
    this.spriteChallengeBase = new PIXI.Sprite(this.textureChallengeBase[ChallengeGameReelBackground.challenge1]);
    this.addChild(this.spriteChallengeBase);
    this.spriteChallengeBase.visible = false;
  }

  private initReelBackground(): PIXI.Container[] {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const container = new PIXI.Container();
      const texture = PIXI.Texture.from(ResourceTypes.reelsBackBasegame);

      const light = new PIXI.Sprite(texture);
      this.lights.push(light);
      container.y = 0;
      container.x = i * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
      container.addChild(light);
      this.reelContainer.push(container);
      this.addChild(container);
    }
    return this.reelContainer;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;

    if (isChallengeMode(mode)) {
      this.spriteChallengeBase.visible = true;

      if (background === 'challenge1') {
        // eslint-disable-next-line prefer-destructuring
        this.spriteChallengeBase.texture = this.textureChallengeBase[ChallengeGameReelBackground.challenge1];
      } else if (background === 'challenge2') {
        // eslint-disable-next-line prefer-destructuring
        this.spriteChallengeBase.texture = this.textureChallengeBase[ChallengeGameReelBackground.challenge2];
      } else if (background === 'challenge3') {
        // eslint-disable-next-line prefer-destructuring
        this.spriteChallengeBase.texture = this.textureChallengeBase[ChallengeGameReelBackground.challenge3];
      }
    } else {
      this.spriteChallengeBase.visible = false;
    }
  }
}

export default ReelsBackgroundContainer;
