import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { destroySpine, isChallengeMode } from '../../../utils';
import Animation from '../../animations/animation';
import SpineAnimation from '../../animations/spine';
import Tween from '../../animations/tween';
import { BgSkin } from '../../background/background';
import ViewContainer from '../../components/container';
import {
  ANTICIPATION_DURATION,
  REELS_FRAME_ADJUSTMENT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../../config';

class ReelsFrame extends ViewContainer {
  private baseFrameTex = PIXI.Texture.from(ResourceTypes.reelFrame);

  private challengeFrameTex = PIXI.Texture.from(ResourceTypes.challengegameFrame);

  //  private spriteReelsFrame = new PIXI.Sprite();

  private frame: PIXI.Sprite;

  public anticipationAnimation: Animation | undefined;

  constructor() {
    super();
    this.sortableChildren = true;
    this.frame = this.initFrame();

    this.addChild(this.frame);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.NEARMISS, () => {
      this.anticipationAnimation = this.createAnticipationBackgroundAnimation();
      this.anticipationAnimation.start();
    });
    eventManager.addListener(EventTypes.NEARMISS_SKIP, () => {
      this.anticipationAnimation?.skip();
    });
  }

  private createAnticipationBackgroundAnimation(): Animation {
    const dummy = Tween.createDelayAnimation(ANTICIPATION_DURATION);
    const animation = new SpineAnimation({}, PIXI.Loader.shared.resources.Nearmiss.spineData!);

    dummy.addOnStart(() => {
      animation.getSpine().x = REEL_WIDTH * 4 + REEL_WIDTH / 2;
      animation.getSpine().y = SLOTS_CONTAINER_HEIGHT / 2;
      animation.getSpine().state.timeScale = 1;
      animation.getSpine().zIndex = this.frame.zIndex + 1;
      this.addChild(animation.getSpine());
      animation.setAnimation('Nearmiss', true);
    });
    dummy.addOnComplete(() => {
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });
    dummy.addOnSkip(() => {
      this.removeChild(animation.getSpine());
      destroySpine(animation);
    });

    return dummy;
  }

  private initFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(this.baseFrameTex);
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.zIndex = 10;
    this.setPosition(frame);
    return frame;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isChallengeMode(settings.mode)) {
      this.frame.texture = this.challengeFrameTex;
    } else {
      this.frame.texture = this.baseFrameTex;
    }
    this.setPosition(this.frame);
  }

  private setPosition(frame: PIXI.Sprite): void {
    frame.anchor.set(0.5, 1);
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT + REELS_FRAME_ADJUSTMENT);
    frame.zIndex = 10;
  }
}

export default ReelsFrame;
