const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_GOLD_TEXT_COLOR_CONFIG = {
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
  miterLimit: 1,
};
const GRADIENT_GOLD_TITLE_COLOR_CONFIG = {
  fill: ['#fff70a', '#fcc200', '#fffb00', '#fcc200', '#b47508'],
  fillGradientStops: [0.2, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
  miterLimit: 1,
};

export const buyFeatureTitleStyle = {
  fontSize: 70,
  fontFamily: font,
  ...GRADIENT_GOLD_TITLE_COLOR_CONFIG,
  strokeThickness: 8,
  miterLimit: 1,
};

export const buyFeatureConfirmStyle = {
  fontSize: 50,
  fontFamily: font,
  fill: '#ff3333',
  stroke: '#661111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
  miterLimit: 1,
};

export const totalCostTextStyle = {
  fontSize: 50,
  fontFamily: font,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 4,
};

export const totalCostTextAmountStyle = {
  fontSize: 65,
  fontFamily: font,
  lineHeight: 65,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 5,
};

export const amountTextStyle = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 0,
  dropShadowDistance: 3,
  letterSpacing: 0,
  wordWrap: true,
  wordWrapWidth: 240,
  breakWords: true,
  miterLimit: 1,
};

export const betValueStyle = {
  fontSize: 50,
  fontFamily: font,
  lineHeight: 50,
  fill: '#f6e87e',
  stroke: '#111111',
  strokeThickness: 4,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowDistance: 3,
  letterSpacing: 2,
  miterLimit: 1,
};

export const betValueStyleConfirm = {
  fontSize: 90,
  fontFamily: font,
  lineHeight: 50,
  ...GRADIENT_GOLD_TEXT_COLOR_CONFIG,
  strokeThickness: 7,
};
