import * as PIXI from 'pixi.js';

class Loader extends PIXI.AnimatedSprite {
  constructor(size: number) {
    super(PIXI.Loader.shared.resources.loaderSprite!.spritesheet!.animations.Load);

    this.loop = true;
    this.animationSpeed = 0.5;
    this.anchor.set(0.5, 0.5);
    this.width = size;
    this.height = size;
    this.x = 0;
    this.y = 0;
  }

  public animate(): void {
    this.play();
  }

  public stopAnimation(): void {
    this.stop();
  }
}
export default Loader;
