import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import { ISongs, audioSpriteVolume } from '../../config';
import { BgmSoundTypes, EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setGameMode, setIsSuspended } from '../../gql/cache';
import { isChallengeMode, isFreeSpinMode } from '../../utils';
import { BgSkin } from '../background/background';
import { SlotMachineState, eventManager } from '../config';

type BgmType = Record<BgmSoundTypes, { base: ISongs; melo?: ISongs }>;

export const bgmList: BgmType = {
  regular: { base: ISongs.BaseGameBGM_Base, melo: ISongs.BaseGameBGM_Melo },
  challengeGame1: { base: ISongs.ChallengeGame01 },
  challengeGame2: { base: ISongs.ChallengeGame02 },
  challengeGame3: { base: ISongs.ChallengeGame03 },
  fs: { base: ISongs.FreeSpinBGM },
};

class BgmControl {
  private bgmListIndex: BgmSoundTypes;

  private background: BgSkin | undefined;

  private meloFlg: boolean;

  private timer: NodeJS.Timeout | undefined;

  constructor() {
    this.bgmListIndex = BgmSoundTypes.BASE;
    this.background = undefined;
    this.meloFlg = false;
    this.timer = undefined;

    eventManager.on(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.on(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onSlotMachineStateChange.bind(this));
  }

  private clearTimeout() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  private onSlotMachineStateChange(state: SlotMachineState) {
    if (state === SlotMachineState.IDLE) {
      this.clearTimeout();

      if (this.meloFlg) {
        this.timer = setTimeout(() => {
          AudioHowl.fadeOut(3000, bgmList[this.bgmListIndex].melo!);
        }, 30 * 1000);
      }
    } else if (state === SlotMachineState.SPIN) {
      this.clearTimeout();
    }
  }

  private onModeChange(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    let bgmListIndex;
    let bgmTitle: BgmSoundTypes;

    this.meloFlg = false;
    if (isChallengeMode(mode)) {
      this.background = background;
      bgmListIndex = this.getChallengeSound(this.background);
      if (bgmListIndex !== undefined && bgmListIndex !== this.bgmListIndex) {
        this.stopBgm();
        this.playBgm(bgmListIndex);
      }
    } else {
      if (isFreeSpinMode(mode)) {
        bgmTitle = BgmSoundTypes.FS;
      } else {
        bgmTitle = BgmSoundTypes.BASE;
      }

      if (this.bgmListIndex != bgmTitle) {
        this.stopBgm();
        this.playBgm(bgmTitle);
      }
    }
  }

  private setBgmIndex(): void {
    if (isFreeSpinMode(setGameMode())) {
      this.bgmListIndex = BgmSoundTypes.FS;
    } else if (isChallengeMode(setGameMode())) {
      this.bgmListIndex = this.getChallengeSound(this.background);
    } else {
      this.bgmListIndex = BgmSoundTypes.BASE;
    }
  }

  public playBgm(bgmListIndex?: BgmSoundTypes): void {
    if (AudioHowl.isRestricted) {
      return;
    }
    this.stopBgm();
    if (bgmListIndex === undefined) {
      this.setBgmIndex();
    } else {
      this.bgmListIndex = bgmListIndex;
    }
    AudioHowl.play({ type: bgmList[this.bgmListIndex].base });
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioHowl.play({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public stopBgm(): void {
    AudioHowl.stop({ type: bgmList[this.bgmListIndex].base });
    if ('melo' in bgmList[this.bgmListIndex]) {
      AudioHowl.stop({
        type: bgmList[this.bgmListIndex].melo!,
        volume: 0,
      });
    }
  }

  public fadeInBase(fadeTime: number): void {
    AudioHowl.fadeIn(fadeTime, bgmList[this.bgmListIndex].base, audioSpriteVolume[bgmList[this.bgmListIndex].base]);
  }

  public fadeInMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      const soundProp = AudioHowl.getSoundByKey(bgmList[this.bgmListIndex].melo!);
      if (soundProp.volume !== 0) {
        return;
      }

      this.meloFlg = true;
      AudioHowl.fadeIn(fadeTime, bgmList[this.bgmListIndex].melo!, audioSpriteVolume[bgmList[this.bgmListIndex].melo!]);
    }

    this.onSlotMachineStateChange(SlotMachine.getInstance().state);
  }

  public fadeOutAll(fadeTime: number): void {
    AudioHowl.fadeOut(fadeTime, bgmList[this.bgmListIndex].base);
    this.fadeOutMelo(fadeTime);
  }

  public fadeOutMelo(fadeTime: number): void {
    if ('melo' in bgmList[this.bgmListIndex]) {
      this.meloFlg = false;
      AudioHowl.fadeOut(fadeTime, bgmList[this.bgmListIndex].melo!);
    }
  }

  public handleChangeRestriction(value = true): void {
    if (setBrokenGame() || !value) {
      setIsSuspended(false);
      AudioHowl.unSuspend();
      AudioHowl.changeRestriction(false, []);
      eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
      this.playBgm();
    } else {
      this.playBgm();
    }
  }

  private getChallengeSound(background: BgSkin | undefined): BgmSoundTypes {
    if (background === 'challenge1') {
      const ret = BgmSoundTypes.CG1;
    }
    let rtn = BgmSoundTypes.CG1;
    switch (background) {
      case 'challenge1':
        rtn = BgmSoundTypes.CG1;
        break;
      case 'challenge2':
        rtn = BgmSoundTypes.CG2;
        break;
      case 'challenge3':
        rtn = BgmSoundTypes.CG3;
        break;
      default:
        break;
    }
    return rtn;
  }
}

export default new BgmControl();
