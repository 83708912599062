import * as PIXI from 'pixi.js';

import {
  BonusGrants,
  ChallengeResult,
  CoinGrants,
  EventTypes,
  GameMode,
  NinjaType,
  ShurikenType,
} from '../../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setChallengeBonusId,
  setChallengeGameMessageBanner,
  setChallengeRemainRound,
  setCurrentBonus,
  setGameMode,
  setIsContinueAutoSpinsAfterFeature,
  setNinjaPick,
} from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { getGameModeByBonusId, isChallengeMode, isFreeSpinMode } from '../../utils';
import Tween from '../animations/tween';
import { BgSkin } from '../background/background';
import ViewContainer from '../components/container';
import {
  CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME,
  FREE_SPINS_TIME_OUT_BANNER,
  SlotMachineState,
  eventManager,
} from '../config';
import {
  FREE_SPINS_TREASURE_ICON_POS_X,
  FREE_SPINS_TREASURE_ICON_POS_Y,
  FREE_SPINS_TREASURE_ICON_SCALE,
} from '../gameView/config';
import { FreeSpinsCounter } from '../gameView/freeSpinsCounter';

import AttackSymbolContainer from './attackSymbol/attackSymbolContainer';
import { CHALLENGE_GAME_START_FREE_SPINS, NinjaIndex } from './config';
import NinjaAttack from './ninjaAttack';
import NinjaPick from './ninjaPick';
import NinjaSymbolContainer from './ninjaSymbol/ninjaSymbolContainer';

class ChallengeGameContainer extends ViewContainer {
  private freeSpinsCounter: FreeSpinsCounter;

  private ninjaSymbol: NinjaSymbolContainer;

  private attackSymbol: AttackSymbolContainer;

  private attackCounter: ShurikenType;

  public ninjaPick: NinjaPick[] = [];

  public ninjaAttack: NinjaAttack;

  private ninjaIndex: NinjaIndex;

  private pickResultCoin: number;

  private resultCoinAmount: number;

  private stagePickNinja: NinjaType = NinjaType.NINJA_C;

  private resultFreeGame: number;

  private pickResultNinja: ChallengeResult;

  private pickResultReward: number;

  private resultNinja: NinjaType;

  private challengeModeBackground: 'challenge1' | 'challenge2' | 'challenge3';

  private treasureIcon = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.challengegameCutinTreasureicon));

  constructor() {
    super();

    this.ninjaIndex = NinjaIndex.NINJA_1;

    this.freeSpinsCounter = new FreeSpinsCounter();
    this.ninjaSymbol = new NinjaSymbolContainer();
    this.attackSymbol = new AttackSymbolContainer();
    this.pickResultCoin = 0;
    this.stagePickNinja = NinjaType.NINJA_C;
    this.resultCoinAmount = 0;
    this.resultFreeGame = CHALLENGE_GAME_START_FREE_SPINS;
    this.pickResultNinja = ChallengeResult.NON;
    this.pickResultReward = 0;
    this.resultNinja = NinjaType.NON;
    this.challengeModeBackground = 'challenge1';

    this.treasureIcon.scale.set(FREE_SPINS_TREASURE_ICON_SCALE);
    this.treasureIcon.position.set(FREE_SPINS_TREASURE_ICON_POS_X, FREE_SPINS_TREASURE_ICON_POS_Y);

    this.addChild(this.treasureIcon);
    this.addChild(this.freeSpinsCounter);
    this.addChild(this.ninjaSymbol);

    for (let i = 0; i < NinjaIndex.NINJA_MAX; i++) {
      this.ninjaPick[i] = new NinjaPick(i);
    }
    this.ninjaAttack = new NinjaAttack();

    this.attackCounter = ShurikenType.SHURIKEN_1st;

    this.treasureIcon.visible = false;
    this.freeSpinsCounter.visible = false;
    this.ninjaSymbol.visible = false;
    this.attackSymbol.visible = false;

    for (let i = 0; i < NinjaIndex.NINJA_MAX; i++) {
      this.addChild(this.ninjaPick[i]);
    }

    this.addChild(this.ninjaAttack);

    this.addChild(this.attackSymbol);

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_CLICK, this.ninjaAttackStart.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_RESULT_LOOP, this.challengeNinjaResult.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_SPIN, this.challengeNinjaSpin.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_SPIN_RESULT, this.setChallengeNinjaSpinResult.bind(this));

    eventManager.addListener(EventTypes.HANDLE_FREE_SPINS_TEXT_MOVE_END, this.setUpdateFreeSpinsCount.bind(this));

    eventManager.addListener(EventTypes.SPACE_KEY_SPIN, this.spaceKeySpin.bind(this));

    if (setBrokenGame()) {
      setGameMode(getGameModeByBonusId(setCurrentBonus().bonusId));
      this.onBrokenGame();
    }
  }

  public getSpinsTextPos(): number {
    return this.freeSpinsCounter.getSpinsTextPos();
  }

  public challengeNinjaSpin(ninjaIndex: NinjaIndex) {
    this.ninjaIndex = ninjaIndex;
  }

  public getNinjaIndex(): NinjaIndex {
    return this.ninjaIndex;
  }

  public setResult(): void {
    this.pickResultNinja = this.pickResultCoin;
  }

  public setChallengeNinjaSpinResult(result: ChallengeResult, num: number) {
    this.pickResultNinja = result;
    this.pickResultReward = num;
    this.ninjaAttack.setResult(result, num);

    if (this.pickResultNinja === ChallengeResult.FREE_SPIN) {
      this.resultFreeGame += num;
    } else if (this.pickResultNinja === ChallengeResult.COINS) {
      this.pickResultCoin += num;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (settings.mode === GameMode.REGULAR || settings.mode === GameMode.BUY_FEATURE) {
      this.ninjaSymbol.resetNinjaSymbol();
      this.treasureIcon.visible = false;
      this.freeSpinsCounter.visible = false;
      this.ninjaSymbol.visible = false;
      this.attackSymbol.visible = false;
      this.resultCoinAmount = 0;
      this.resultFreeGame = CHALLENGE_GAME_START_FREE_SPINS;
    } else if (settings.mode === GameMode.CHALLENGE_GAME) {
      this.treasureIcon.visible = true;
      this.freeSpinsCounter.visible = true;
      this.ninjaSymbol.visible = true;
      this.attackSymbol.visible = true;
      this.pickResultCoin = 0;
      this.pickResultNinja = ChallengeResult.NON;

      eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT, this.resultFreeGame, 0, true);
    } else if (isFreeSpinMode(settings.mode)) {
      this.resultNinja = NinjaType.NON;
      this.challengeModeBackground = 'challenge1';
      this.attackSymbol.resetAttackSymbol();
    }
  }

  private onBrokenGame() {
    if (isFreeSpinMode(setGameMode())) {
      this.treasureIcon.visible = true;
      this.freeSpinsCounter.visible = true;
      this.ninjaSymbol.visible = true;

      const ninja: boolean[] = [];
      const gameMode = getGameModeByBonusId(setCurrentBonus().bonusId);

      switch (gameMode) {
        case GameMode.FREE_SPINS_SYMBOLS_C:
          ninja[2] = true;
          break;
        case GameMode.FREE_SPINS_SYMBOLS_C_B:
          ninja[2] = true;
          ninja[1] = true;
          break;
        case GameMode.FREE_SPINS_SYMBOLS_C_B_A:
          ninja[2] = true;
          ninja[1] = true;
          ninja[0] = true;
          break;
        default:
          break;
      }
      for (let nin = 0; nin < 3; nin++) {
        this.ninjaSymbol.updateNinjaSymbol(nin, ninja[nin]);
      }
    }
    if (isChallengeMode(setGameMode())) {
      eventManager.emit(EventTypes.CHALLENGE_NINJA_INIT);
      setNinjaPick(false);
      setChallengeBonusId(setCurrentBonus().id);

      const ninja: boolean[] = [];

      switch (Math.floor(setCurrentBonus().currentRound / 3)) {
        case 1:
          this.challengeModeBackground = 'challenge2';
          this.pickResultNinja = ChallengeResult.NINJA_C;
          this.resultNinja = NinjaType.NINJA_C;
          ninja[2] = true;
          break;
        case 2:
          this.challengeModeBackground = 'challenge3';
          this.pickResultNinja = ChallengeResult.NINJA_B;
          this.resultNinja = NinjaType.NINJA_B;
          ninja[2] = true;
          ninja[1] = true;
          break;
        default:
          break;
      }

      const bonusList = setCurrentBonus().data.preLoadedGrantBreakdown.filter(
        (bonus, index) =>
          bonus.grants[0].grants[0].type === 'BONUS' && index < Math.floor(setCurrentBonus().currentRound / 3) * 3,
      );

      let bonusCount = 0;
      bonusList.forEach((bonus) => {
        bonusCount += (bonus.grants[0].grants[0] as BonusGrants).count;
      });

      this.resultFreeGame += bonusCount;

      this.attackCounter = setCurrentBonus().currentRound % 3;
      for (let i = 0; i < this.attackCounter; i++) {
        eventManager.emit(EventTypes.HANDLE_UPDATE_SHURIKEN, i, false);
        const ninja = Math.floor(setCurrentBonus().currentRound / 3) * 3 + i;

        let pickResultNinja: ChallengeResult;
        let pickResultReward = 0;
        switch (setCurrentBonus().data.preLoadedGrantBreakdown[ninja].grants[0].grants[0].type) {
          case 'COINS':
            pickResultNinja = ChallengeResult.COINS;
            pickResultReward =
              (setCurrentBonus().data.preLoadedGrantBreakdown[ninja].grants[0].grants[0] as CoinGrants).multiplier *
              setBetAmount();
            break;
          case 'BONUS':
            pickResultNinja = ChallengeResult.FREE_SPIN;
            pickResultReward = (
              setCurrentBonus().data.preLoadedGrantBreakdown[ninja].grants[0].grants[0] as BonusGrants
            ).count;
            this.resultFreeGame += pickResultReward;
            eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT, this.resultFreeGame, 0, false);
            break;
          default:
            pickResultNinja = ChallengeResult.COINS;
            break;
        }

        this.ninjaPick[i].setChallengeNinjaSpinEnd();

        eventManager.emit(EventTypes.CHALLENGE_NINJA_RESULT_DISPLAY, i, pickResultNinja, pickResultReward);
      }

      for (let nin = 0; nin < 3; nin++) {
        this.ninjaSymbol.updateNinjaSymbol(nin, ninja[nin]);
      }
    }
  }

  private ninjaAttackStart(): void {
    setChallengeRemainRound(setChallengeRemainRound() - 1);

    eventManager.emit(EventTypes.HANDLE_UPDATE_SHURIKEN, this.attackCounter, false);
    if (this.attackCounter < ShurikenType.SHURIKEN_3rd) {
      this.attackCounter += 1;
    }
  }

  private challengeNinjaResult(targetNinja: NinjaIndex): void {
    const callback = () => {
      this.callbackChallengeGameResult();
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    };

    const callbackNext = () => {
      this.callbackChallengeGameNext();
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    };

    if (this.pickResultNinja) {
      eventManager.emit(
        EventTypes.CHALLENGE_NINJA_RESULT_DISPLAY,
        targetNinja,
        this.pickResultNinja,
        this.pickResultReward,
      );
    }

    this.resultCoinAmount += this.pickResultCoin;

    if (this.pickResultNinja <= ChallengeResult.NINJA_A) {
      let ninjaIcon = 0;
      switch (this.pickResultNinja) {
        case ChallengeResult.NINJA_A:
          this.stagePickNinja = NinjaType.NINJA_A;
          this.resultNinja = NinjaType.NINJA_A;
          ninjaIcon = 0;
          break;
        case ChallengeResult.NINJA_B:
          this.stagePickNinja = NinjaType.NINJA_B;
          this.resultNinja = NinjaType.NINJA_B;
          ninjaIcon = 1;
          break;
        case ChallengeResult.NINJA_C:
          this.stagePickNinja = NinjaType.NINJA_C;
          this.resultNinja = NinjaType.NINJA_C;
          ninjaIcon = 2;
          break;
        default:
          break;
      }
      this.ninjaSymbol.updateNinjaSymbol(ninjaIcon, true);
    }

    if (setChallengeRemainRound() % 3 === 0 && setChallengeRemainRound() > 0) {
      // TODO
      let iconPtn = 0;
      switch (this.stagePickNinja) {
        case NinjaType.NINJA_C:
          iconPtn = 0;
          break;
        case NinjaType.NINJA_B:
          iconPtn = 1;
          break;
        case NinjaType.NINJA_A:
          iconPtn = 2;
          break;
        default:
          break;
      }

      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callbackNext();
      });

      if (this.resultFreeGame > 0) {
        setTimeout(() => {
          setChallengeGameMessageBanner(true);
          if (!setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              btnText: i18n.t('pressToNextStage'),
              callback: () => {
                callbackNext();
              },
            });
          } else {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              btnText: i18n.t('pressToNextStage'),
              onInitCallback: () => delay.start(),
            });
          }
        }, CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME);
      } else {
        setTimeout(() => {
          setChallengeGameMessageBanner(true);
          if (!setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              btnText: i18n.t('pressToNextStage'),
              callback: () => {
                callbackNext();
              },
            });
          } else {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              btnText: i18n.t('pressToNextStage'),
              onInitCallback: () => delay.start(),
            });
          }
        }, CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME);
      }
    } else if (setChallengeRemainRound() === 0) {
      // TODO
      let iconPtn = 0;
      switch (this.resultNinja) {
        case NinjaType.NINJA_C:
          iconPtn = 0;
          break;
        case NinjaType.NINJA_B:
          iconPtn = 1;
          break;
        case NinjaType.NINJA_A:
          iconPtn = 2;
          break;
        default:
          break;
      }

      const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
      delay.addOnComplete(() => {
        callback();
      });

      if (this.resultFreeGame > 0 && this.resultNinja > NinjaType.NON) {
        setTimeout(() => {
          setChallengeGameMessageBanner(true);

          const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
          delay.addOnComplete(() => {
            callback();
          });

          if (!setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              callback,
            });
          } else {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              getIconPattern: iconPtn,
              onInitCallback: () => delay.start(),
            });
          }
        }, CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME);
      } else if (this.resultFreeGame > 0) {
        setTimeout(() => {
          setChallengeGameMessageBanner(true);
          if (!setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              callback,
            });
          } else {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              freeSpins: i18n.t('freeSpinsText', {
                spin: this.resultFreeGame,
              }),
              onInitCallback: () => delay.start(),
            });
          }
        }, CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME);
      } else if (this.resultNinja > NinjaType.NON) {
        setTimeout(() => {
          setChallengeGameMessageBanner(true);
          if (!setIsContinueAutoSpinsAfterFeature()) {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              getIconPattern: iconPtn,
              callback,
            });
          } else {
            eventManager.emit(EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER, {
              title: i18n.t('youWon'),
              btnText: i18n.t('pressToNextFeature'),
              getIconPattern: iconPtn,
              onInitCallback: () => delay.start(),
            });
          }
        }, CHALLENGE_GAME_RESULT_DISPLAY_DELAY_TIME);
      } else {
        // console.assert('setChallengeGame Result error');
      }

      setCurrentBonus({
        ...setCurrentBonus(),
        rounds: this.resultFreeGame,
        currentRound: 0,
      });
    } else {
      setNinjaPick(false);
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    }

    if (setChallengeRemainRound() % 3 !== 0 && !setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
    }
  }

  private callbackChallengeGameNext(): void {
    eventManager.emit(EventTypes.SCENE_CHANGE_DOWN);

    setTimeout(() => {
      setNinjaPick(false);
      setChallengeGameMessageBanner(false);

      eventManager.emit(EventTypes.HANDLE_RESET_SHURIKEN);

      switch (this.challengeModeBackground) {
        case 'challenge1':
          this.challengeModeBackground = 'challenge2';
          break;
        case 'challenge2':
          this.challengeModeBackground = 'challenge3';
          break;
        default:
          break;
      }

      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: GameMode.CHALLENGE_GAME,
        background: this.challengeModeBackground,
      });
      eventManager.emit(EventTypes.CHALLENGE_NINJA_INIT);
      this.attackCounter = 0;
    }, 200);
  }

  private callbackChallengeGameResult(): void {
    eventManager.emit(EventTypes.SCENE_CHANGE_UP);
    setNinjaPick(true);

    let gameMode = GameMode.FREE_SPINS;
    switch (this.resultNinja) {
      case NinjaType.NON:
        gameMode = GameMode.FREE_SPINS;
        break;
      case NinjaType.NINJA_C:
        gameMode = GameMode.FREE_SPINS_SYMBOLS_C;
        break;
      case NinjaType.NINJA_B:
        gameMode = GameMode.FREE_SPINS_SYMBOLS_C_B;
        break;
      case NinjaType.NINJA_A:
        gameMode = GameMode.FREE_SPINS_SYMBOLS_C_B_A;
        break;
      default:
        break;
    }

    setTimeout(() => {
      eventManager.emit(EventTypes.SET_STATE, SlotMachineState.IDLE);
      eventManager.emit(EventTypes.MANUAL_CHANGE_BACKGROUND, {
        mode: GameMode.FREE_SPINS,
        background: 'fs',
      });
    }, 1000);
    setTimeout(() => {
      setGameMode(gameMode);
      eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
    }, 1500);
    this.attackCounter = 0;
  }

  private setUpdateFreeSpinsCount(): void {
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT, this.resultFreeGame, 0, false);
  }

  private spaceKeySpin(): void {
    if (setChallengeGameMessageBanner()) {
      eventManager.emit(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER);
    } else {
      for (let i = 0; i < this.ninjaPick.length; i++) {
        if (!this.ninjaPick[i].getPickResult()) {
          this.ninjaPick[i].handleClick();
          return;
        }
      }
    }
  }
}

export default ChallengeGameContainer;
