import { GET_BET_SETTINGS, client, setCoinValue, setSlotConfig } from '.';
import { IBetSettings } from './d';

export const getBetsSetting = (): IBetSettings => {
  const { id } = setSlotConfig();
  const { clientSettings, lines } = client.readFragment({
    id: `Slot:${id}`,
    fragment: GET_BET_SETTINGS,
  });

  const minBet = clientSettings.coinAmounts.default[0] ?? 0;
  const maxBet = clientSettings.coinAmounts.default[clientSettings.coinAmounts.default.length - 1] ?? 0;
  const bets = clientSettings.coinAmounts.default;
  const { quick } = clientSettings.coinAmounts;
  const coinValue = setCoinValue();
  const numberOfLines = lines.length;
  const minBetCount = (minBet * numberOfLines * coinValue) / 100;
  const maxBetCount = (maxBet * numberOfLines * coinValue) / 100;

  return {
    minBet,
    maxBet,
    bets,
    quick,
    numberOfLines,
    coinValue,
    minBetCount,
    maxBetCount,
  };
};
