export const CHALLENGE_GAME_START_FREE_SPINS = 5;
export const RESULT_ICON_SCALE = 1.5;

export enum NinjaIndex {
  NINJA_4,
  NINJA_5,
  NINJA_6,
  NINJA_1,
  NINJA_2,
  NINJA_3,
  NINJA_MAX,
}

export const ninjaPosition = [
  [213, 200],
  [640, 200],
  [1067, 200],
  [213, 580],
  [640, 580],
  [1067, 580],
];

export const ninjaAnimType = [
  ['Ninja4_Idle', 'Ninja4_Spot'],
  ['Ninja5_Idle', 'Ninja5_Spot'],
  ['Ninja6_Idle', 'Ninja6_Spot'],
  ['Ninja1_Idle', 'Ninja1_Spot'],
  ['Ninja2_Idle', 'Ninja2_Spot'],
  ['Ninja3_Idle', 'Ninja3_Spot'],
];

export enum NinjaAttackTypes {
  NINJA_FAILED = 'Ninja_Failed',
  NINJA_SUCCESS = 'Ninja_Success',
  NINJA_SUCCESS_FREE_SPIN = 'Ninja_SuccessFreeSpin',
  NINJA_GET_A = 'Ninja_GetA',
  NINJA_GET_B = 'Ninja_GetB',
  NINJA_GET_C = 'Ninja_GetC',
}
