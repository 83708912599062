import * as PIXI from 'pixi.js';

import { ChallengeResult, EventTypes, GameMode } from '../../global.d';
import { setCurrency, setNinjaPick } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { formatNumber, isChallengeMode, isFreeSpinMode, normalizeCoins, showCurrency } from '../../utils';
import SpineAnimation from '../animations/spine';
import { BgSkin } from '../background/background';
import { eventManager } from '../config';

import { NinjaIndex, RESULT_ICON_SCALE, ninjaAnimType, ninjaPosition } from './config';
import { coinStyle, freeSpinsStyle, freeSpinsTextStyle } from './textStyles';

class NinjaPick extends PIXI.Container {
  private NinjaAnimation: SpineAnimation | undefined;

  private ninjaType: NinjaIndex;

  private onClick: boolean;

  private pickResult = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.challengegameLeveliconNinjaA));

  private resultBase = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.challengegameCutinCoinicon));

  private resultGlow = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.iconGlow));

  private resultText: PIXI.Text;

  private resultSpinText: PIXI.Text;

  constructor(ninjaType: NinjaIndex) {
    super();
    this.visible = false;
    this.ninjaType = ninjaType;
    this.onClick = false;

    this.pickResult.visible = false;
    this.pickResult.anchor.set(0.5, 0.5);
    this.pickResult.position.set(ninjaPosition[ninjaType][0], ninjaPosition[ninjaType][1]);
    this.resultText = this.initCoinText(i18n.t(''));
    this.resultText.visible = false;
    this.resultSpinText = this.initSpinText('');
    this.resultSpinText.visible = false;

    this.resultBase.visible = false;
    this.resultBase.anchor.set(0.5, 0.5);
    this.resultBase.position.set(ninjaPosition[ninjaType][0], ninjaPosition[ninjaType][1]);

    this.resultGlow.visible = false;
    this.resultGlow.anchor.set(0.5, 0.5);
    this.resultGlow.position.set(ninjaPosition[ninjaType][0], ninjaPosition[ninjaType][1]);

    this.addChild(this.resultGlow, this.pickResult, this.resultBase, this.resultText, this.resultSpinText);

    this.initNinjaAnimation(this.ninjaType);

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_INIT, this.challengeNinjaInit.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_START, this.challengeNinjaStart.bind(this));
    eventManager.addListener(EventTypes.CHALLENGE_NINJA_END, this.challengeNinjaEnd.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_RESULT_DISPLAY, this.challengeNinjaResult.bind(this));

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_SPIN_RESULT, this.setChallengeNinjaSpinResult.bind(this));

    eventManager.addListener(EventTypes.REPLAY_CHALLENGE_NINJA_CLICK, () => this.handleClick());

    this.interactive = true;

    // TODO remove it!
    this.on('click', () => {
      this.handleClick();
    });
    this.on('touchstart', () => {
      this.handleClick();
    });
    this.on('mousedown', () => {
      this.mousedown();
    });
    this.on('mouseup', () => {
      this.mouseup();
    });
    this.on('mouseover', () => {
      this.mouseover();
    });
    this.on('mouseout', () => {
      this.mouseout();
    });
  }

  public getPickResult(): boolean {
    return this.resultGlow.visible;
  }

  private challengeNinjaInit(): void {
    this.challengeNinjaEnd();
    this.resultText.visible = false;
    this.pickResult.visible = false;
    this.resultBase.visible = false;
    this.resultGlow.visible = false;
    this.resultSpinText.visible = false;

    this.NinjaAnimation!.getSpine().visible = true;
    this.challengeNinjaStart();
    this.onClick = false;
  }

  private initCoinText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('titleText'), coinStyle);
    text.resolution = 1;
    text.anchor.set(0.5);
    text.position.set(ninjaPosition[this.ninjaType][0], ninjaPosition[this.ninjaType][1]);

    return text;
  }

  private initSpinText(titleText: string): PIXI.Text {
    const text = new PIXI.Text(titleText, coinStyle);
    text.resolution = 1;
    text.anchor.set(0.5);
    text.position.set(ninjaPosition[this.ninjaType][0], ninjaPosition[this.ninjaType][1] + 70);

    return text;
  }

  private initNinjaAnimation(ninjaType: NinjaIndex): void {
    const ninjaSpineData = [
      PIXI.Loader.shared.resources.challengeGame_ninja4.spineData,
      PIXI.Loader.shared.resources.challengeGame_ninja5.spineData,
      PIXI.Loader.shared.resources.challengeGame_ninja4.spineData,
      PIXI.Loader.shared.resources.challengeGame_ninja1.spineData,
      PIXI.Loader.shared.resources.challengeGame_ninja2.spineData,
      PIXI.Loader.shared.resources.challengeGame_ninja3.spineData,
    ];

    this.NinjaAnimation = new SpineAnimation({}, ninjaSpineData[ninjaType]!);

    this.NinjaAnimation!.addOnStart(() => {
      this.addChild(this.NinjaAnimation!.spine);
    });
    this.NinjaAnimation!.getSpine().pivot.set(0);

    this.NinjaAnimation?.getSpine().position.set(ninjaPosition[ninjaType][0], ninjaPosition[ninjaType][1]);
  }

  private challengeNinjaStart(): void {
    this.NinjaAnimation!.setAnimation(ninjaAnimType[this.ninjaType][0], true);
    this.NinjaAnimation!.spine.stateData.setMix(
      ninjaAnimType[this.ninjaType][0],
      ninjaAnimType[this.ninjaType][1],
      0.1,
    );
    this.NinjaAnimation!.start();
  }

  private challengeNinjaEnd(): void {
    this.NinjaAnimation!.end();
  }

  public handleClick(): void {
    if (!this.onClick && !setNinjaPick()) {
      setNinjaPick(true);
      this.onClick = true;
      this.NinjaAnimation!.setAnimation(ninjaAnimType[this.ninjaType][1], true);

      eventManager.emit(EventTypes.CHALLENGE_NINJA_SPIN, this.ninjaType);
    }
  }

  public setChallengeNinjaSpinEnd(): void {
    this.onClick = true;
    this.NinjaAnimation!.end();
    this.NinjaAnimation!.getSpine().visible = false;
  }

  private setChallengeNinjaSpinResult(): void {
    if (this.onClick) {
      this.NinjaAnimation!.end();
      this.NinjaAnimation!.getSpine().visible = false;
    }
  }

  // TODO remove it!
  private mousedown() {
    // console.log('mousedown ', this.ninjaType);
  }

  private mouseup() {
    // console.log('mouseup ', this.ninjaType);
  }

  private mouseover() {
    // console.log('mouseover ', this.ninjaType);
  }

  private mouseout() {
    // console.log('mouseout ', this.ninjaType);
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isChallengeMode(settings.mode)) {
      this.visible = true;
    } else if (isFreeSpinMode(settings.mode)) {
      this.NinjaAnimation!.end();
      this.NinjaAnimation!.getSpine().visible = false;
      this.visible = false;
      this.pickResult.visible = false;
      this.resultBase.visible = false;
      this.resultGlow.visible = false;
    } else {
      this.visible = false;
    }
  }

  private challengeNinjaResult(ninjaNo: NinjaIndex, result: ChallengeResult, num?: number): void {
    const ninjaIconTable = [
      ResourceTypes.challengegameLeveliconNinjaC,
      ResourceTypes.challengegameLeveliconNinjaB,
      ResourceTypes.challengegameLeveliconNinjaA,
    ];
    setTimeout(() => {
      if (this.ninjaType === ninjaNo) {
        if (result <= ChallengeResult.NINJA_A) {
          let index = 0;
          switch (result) {
            case ChallengeResult.NINJA_A:
              index = 2;
              break;
            case ChallengeResult.NINJA_B:
              index = 1;
              break;
            case ChallengeResult.NINJA_C:
              index = 0;
              break;
            default:
              break;
          }
          this.pickResult.texture = PIXI.Texture.from(ninjaIconTable[index]);
          this.pickResult.scale.set(RESULT_ICON_SCALE);
          this.pickResult.visible = true;
        } else if (result === ChallengeResult.FREE_SPIN) {
          this.resultText.text = `+${num!.toString()}`;
          this.resultText.style = freeSpinsStyle;
          if (num === 1) {
            this.resultSpinText.text = i18n.t('challengeModeFreeSpin') as string;
          } else {
            this.resultSpinText.text = i18n.t('challengeModeFreeSpins') as string;
          }
          this.resultSpinText.style = freeSpinsTextStyle;
          this.resultBase.texture = PIXI.Texture.from(ResourceTypes.challengegameCutinTreasureicon);
          this.resultBase.scale.set(RESULT_ICON_SCALE);
          this.resultText.visible = true;
          this.resultSpinText.visible = true;
          this.resultBase.visible = true;
        } else if (result === ChallengeResult.COINS) {
          this.resultText.text = formatNumber(setCurrency(), normalizeCoins(num), showCurrency(setCurrency()));
          this.resultText.style = coinStyle;
          this.resultBase.texture = PIXI.Texture.from(ResourceTypes.challengegameCutinCoinicon);
          this.resultBase.scale.set(RESULT_ICON_SCALE);
          this.resultText.visible = true;
          this.resultBase.visible = true;
        }
        this.resultGlow.visible = true;
      }
    }, 0);
  }
}
export default NinjaPick;
