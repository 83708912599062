import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { isChallengeMode, isFreeSpinMode } from '../../../utils';
import { BgSkin } from '../../background/background';
import { ATTACK_SYMBOL_SCALE, ATTACK_SYMBOL_WIDTH, eventManager } from '../../config';

class AttackSymbol extends PIXI.Sprite {
  private textureShurikenOff: PIXI.Texture;

  private textureShuriken: PIXI.Texture;

  private attackNo: number;

  constructor(pos: number) {
    super();
    this.attackNo = pos;

    this.textureShurikenOff = PIXI.Texture.from(ResourceTypes.challengegameIconShurikenOff);

    this.textureShuriken = PIXI.Texture.from(ResourceTypes.challengegameIconShuriken);

    this.init();
    this.setPosition();

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  private init(): void {
    this.texture = this.textureShuriken;
  }

  private setPosition(): void {
    this.scale.set(ATTACK_SYMBOL_SCALE);

    this.position.x = this.attackNo * ATTACK_SYMBOL_WIDTH;
  }

  public updateAttackSymbolIcon(valid: boolean): void {
    if (valid) {
      this.texture = this.textureShuriken;
    } else {
      this.texture = this.textureShurikenOff;
    }
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = false;
    } else if (isChallengeMode(settings.mode)) {
      this.visible = true;
    }
  }
}

export default AttackSymbol;
