import React, { useEffect, useRef, useState } from 'react';

import { EventTypes } from '../../global.d';
import { setIsProcessToGame, setSkipIntroScreen } from '../../gql/cache';
import { eventManager } from '../../slotMachine/config';

import IntroScreen from './introScreen';
import styles from './introScreen.module.scss';

const IntroScreenLayout: React.FC = () => {
  const [isShowContent, setShowContent] = useState(setSkipIntroScreen());
  const introScreen = useRef<IntroScreen | null>(null);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isShowContent && introScreen.current === null) {
      IntroScreen.initIntroScreen();
      introScreen.current = IntroScreen.getInstance();
      pixiContainerRef.current?.appendChild(introScreen.current.getApplication().view);
      eventManager.on(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        setShowContent(true);
      });
    }
  }, [isShowContent]);

  useEffect(() => {
    if (isShowContent && !setIsProcessToGame()) {
      setIsProcessToGame(true);
    }
  }, [isShowContent]);

  if (isShowContent) return null;

  return <div className={styles.canvas} ref={pixiContainerRef} />;
};

export default React.memo(IntroScreenLayout);
