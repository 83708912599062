// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_MAPPED_SYMBOLS,
  LOADER_TEXTURES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
  bonusIds,
  freeRoundBonusId,
  reelSets,
} from '../../global.d';
import {
  authGql,
  betsByInitialRoundId,
  client,
  configGql,
  getBonuses,
  getProgressGql,
  getUserBonuses,
  getUserGql,
  setBonuses,
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setIsAuthorized,
  setIsBuyFeaturePopupOpened,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsMobile,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setPreLoadedGrantBreakdown,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  slotHistoryGql,
  userBonusBetsGql,
} from '../../gql';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import {
  calcActiveUserBonusTotalWinAmount,
  loadErrorHandler,
  loadPixiAssets,
  nextTick,
  parseQuery,
  queryParams,
  wait,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(false);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });

      const replayData = replayBetFreeSpins.data.betsByInitialRoundId;

      if (replayData.length) {
        const replayBetIds = replayData.map((e) => e.id);
        if (replayData.length > 1) {
          replayBetIds.splice(0, 1);
          const challengePlayed = replayData.filter((v) => v.reelSetId === reelSets[GameMode.CHALLENGE_GAME]);
          replayBetIds.splice(0, challengePlayed.length);
          setReplayBet(replayBetIds[0]);
          setReplayFreeSpinReelSetId(replayData[1 + challengePlayed.length].reelSetId);
          setReplayFreeSpinBets(replayBetIds);
        } else {
          setReplayFreeSpinReelSetId(replayData[0].reelSetId);
        }
      }
    };

    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 1, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[0]) {
        setUserLastBetResult(betsData.data.bets.edges[0].node);
      }

      if (setUserLastBetResult().result.reelPositions.length === 0) {
        const lastBetRes = { ...setUserLastBetResult() };
        lastBetRes.reelSetId = reelSets[GameMode.REGULAR];
        lastBetRes.result = {
          ...lastBetRes.result,
          reelPositions: [0, 0, 0, 0, 0],
        };
        setUserLastBetResult(lastBetRes);
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBrokenGame = async () => {
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      // TODO(FRB) on loading
      // ->its locally for testing.
      /*const originActiveUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const activeUserBonusData: ApolloQueryResult<{
        userBonuses: UserBonus[];
      }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

      activeUserBonusData.data.userBonuses.push({
        ...(setCurrentBonus() as UserBonus),
        id: freeRoundBonusId,
        bonusId: freeRoundBonusId,
        coinAmount: 1,
        coinValue: 100, // KRW
        //coinValue: 1, // EUR
        rounds: 2,
        totalWinAmount: 0,
        isActive: true,
        currentRound: 0,
      });*/
      // <-its locally for testing.

      // const freeSpinsBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'PRE_LOADED');
      const fsBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId != freeRoundBonusId);

      if (activeUserBonusData.data.userBonuses.length > 0) {
        setPreLoadedGrantBreakdown(activeUserBonusData.data.userBonuses[0].data.preLoadedGrantBreakdown);
        if (activeUserBonusData.data.userBonuses[0].bonusId === bonusIds[GameMode.BUY_FEATURE]) {
          setBrokenBuyFeature(true);
          setIsBuyFeaturePopupOpened(true);
          setCurrentBonus({
            ...activeUserBonusData.data.userBonuses[0],
            isActive: true,
            currentRound: 0,
          });
          setCurrentBonusId(activeUserBonusData.data.userBonuses[0].id);
        } else if (fsBonus != undefined) {
          const fsTotalAmount = fsBonus?.totalWinAmount ? fsBonus?.totalWinAmount / fsBonus.coinValue : 0;
          setBrokenGame(true);
          setCurrentBonus({
            ...fsBonus,
            isActive: true,
            currentRound: 0,
          });
          setCurrentFreeSpinsTotalWin(fsTotalAmount);
          setCurrentBonusId(setCurrentBonus().id);
          const userBonusBetsData = await client.query<{
            bets: ISlotHistoryData;
          }>({
            query: userBonusBetsGql,

            variables: {
              input: {
                filter: {
                  userBonusId: setCurrentBonus().id,
                },
              },
            },
            fetchPolicy: 'network-only',
          });
          setCurrentBonus({
            ...setCurrentBonus(),
            currentRound: userBonusBetsData.data.bets.pageInfo.count,
            rounds: setCurrentBonus().rounds + userBonusBetsData.data.bets.pageInfo.count,
            totalWinAmount: 0,
          });

          const totalWinAmount = calcActiveUserBonusTotalWinAmount(setCurrentBonus());
          if (totalWinAmount > 0) {
            // setCurrentFreeSpinsTotalWin2(totalWinAmount);
            eventManager.once(EventTypes.UPDATE_USER_BALANCE, () => {
              setTimeout(() => {
                eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
                // eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin2());
              });
            });
          }
        }
      }

      if (setUserLastBetResult().reelSetId !== setCurrentBonus().reelSetId) {
        if (setCurrentBonus().reelSetId === undefined) {
          setCurrentBonus({
            ...setCurrentBonus(),
            reelSetId: setUserLastBetResult().reelSetId,
          });
        }
      }

      const freeRoundBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId);
      if (freeRoundBonus) {
        if (!fsBonus || fsBonus.data.frbReferenceId) {
          const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
          setFreeRoundBonus({
            id: freeRoundBonus.id,
            bonusId: freeRoundBonus.bonusId,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            status: freeRoundBonus.status,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: totalWinAmount,

            isActive: true,
            currentRound: 0,
          });
          setFreeRoundsTotalWin(totalWinAmount);
        }
      }
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(10, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobile);
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({
          variables: { input: { token, clientId } },
        });

        await remoteStorage.init(data?.auth.sessionId as string);
        window.remoteStorage = remoteStorage;
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(30, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }
        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBrokenGame();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.PIXI_ASSETS, async (stage) => {
        await loadPixiAssets(
          [...LOADER_MAPPED_SYMBOLS, ...LOADER_TEXTURES(setIsMobile()), ...SPINE_LOADER_TEXTURES(setIsMobile())],
          process.env.PUBLIC_URL,
        );
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.IMAGES, async (stage) => {
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, true, isSoundOn, setIsSuspended).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          if (setBrokenBuyFeature()) {
            setBrokenBuyFeature(false);
            if (setSkipIntroScreen()) {
              nextTick(() => {
                if (!queryParams.has('replayBetId')) {
                  eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
                }
              });
            } else {
              eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
                eventManager.emit(EventTypes.START_BUY_FEATURE_ROUND);
              });
            }
          }

          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img alt="logo" src={Resources.getSource(ResourceTypes.logo)} className={styles.companyLogo} />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
