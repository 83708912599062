import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import i18n from '../../i18next';
import ViewContainer from '../components/container';
import { SLOTS_CONTAINER_WIDTH, bottomContainerTextStyle, eventManager } from '../config';

class GameReplay extends ViewContainer {
  private replay = new PIXI.Container();

  constructor() {
    super();

    const replayText = new PIXI.Text(i18n.t<string>('replay'), {
      ...bottomContainerTextStyle,
      fill: 'red',
      fontSize: 40,
    });
    replayText.x = 80;
    this.replay.addChild(replayText);
    const circle = new PIXI.Graphics();
    circle.x = 40;
    circle.beginFill(0xff0000);
    circle.drawCircle(25, 25, 12.5);
    circle.endFill();
    this.replay.addChild(circle);
    this.replay.x = -50;
    this.replay.y = 50;
    this.replay.visible = false;
    this.addChild(this.replay);
    eventManager.on(EventTypes.SET_REPLAY_BET_ID, () => {
      this.replay.visible = true;
    });
  }
}

export default GameReplay;
