import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';
const GRADIENT_FIRE_TEXT_COLOR_CONFIG = {
  fill: ['#e7194b', '#f19e00', '#feff17', '#f19e00', '#e7194b'],
  fillGradientStops: [0.4, 0.5, 0.6, 0.7],
  fillGradientType: 0,
  stroke: '#111111',
  dropShadow: true,
  dropShadowAlpha: 1.0,
  dropShadowAngle: 1.0,
  dropShadowBlur: 2,
  dropShadowColor: 0x333333,
  dropShadowDistance: 3,
};

export const coinStyle = {
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 90,
  leading: 0,
  letterSpacing: 0,
  miterLimit: 1,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 10,
  trim: false,
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const freeSpinsStyle = {
  breakWords: true,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontFamily: font,
  fontSize: 90,
  leading: 0,
  letterSpacing: 0,
  miterLimit: 1,
  padding: 4,
  strokeThickness: 10,
  trim: false,
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const freeSpinsTextStyle = {
  fontFamily: font,
  ...GRADIENT_FIRE_TEXT_COLOR_CONFIG,
  fontSize: 25,
  leading: 0,
  letterSpacing: 0,
  miterLimit: 1,
  padding: 4,
  strokeThickness: 2,
  trim: false,
  wordWrap: false,
  wordWrapWidth: 1330,
};
