import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import { setIsLeftHandMode } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  buyFeatureTextStyle,
} from '../config';

class BuyFeatureBtnIcon extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.isDisabled = false;
    this.visible = true;
    this.btn = this.initBuyFeatureBtn();
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, 120, 100);

    return text;
  }
}

export default BuyFeatureBtnIcon;
