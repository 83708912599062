/* eslint-disable no-bitwise */

/* eslint-disable prefer-destructuring */
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageChallengeGameResultBannerProps, NinjaType } from '../../global.d';
import i18n from '../../i18next';
import { updateTextScale } from '../../utils/utils';
import NinjaSymbol from '../challengeGame/ninjaSymbol/ninjaSymbol';
import {
  MESSAGE_CHALLENGE_RESULT_FREE_SPINS,
  MESSAGE_CHALLENGE_RESULT_ICON,
  MESSAGE_CHALLENGE_RESULT_ICON_AND_FREE_SPINS,
  MESSAGE_CHALLENGE_RESULT_NINJA_POSITION,
  MESSAGE_CHALLENGE_RESULT_Y_POSITION,
  MessageResultRow,
  eventManager,
} from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { FreeSpinsStyles, andTextStyle, btnTextStyle, totalWinTitleStyles } from './textStyles';

export class MessageChallengeGameResultBanner extends BaseMessageBanner {
  protected props: MessageChallengeGameResultBannerProps;

  private title?: PIXI.Text;

  private freeSpins?: PIXI.Sprite;

  private andText?: PIXI.Sprite;

  private ninjaIcon?: PIXI.Sprite[];

  private btn?: PIXI.Text;

  private messageDisplayPtn = 0;

  constructor(props: MessageChallengeGameResultBannerProps) {
    super(props);
    this.props = props;

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeBackground.bind(this));
  }

  public init(): MessageChallengeGameResultBanner {
    this.banner = this.initBanner();
    this.addChild(this.banner);

    this.on('click', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
    });
    this.on('touchstart', () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
    });

    eventManager.addListener(EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER, () => {
      if (this.props.callback) {
        this.props.callback();
        this.props.callback = undefined;
      }
    });

    if (this.props.getIconPattern !== undefined) {
      this.messageDisplayPtn += MESSAGE_CHALLENGE_RESULT_ICON;
    }
    if (this.props.freeSpins !== undefined) {
      this.messageDisplayPtn += MESSAGE_CHALLENGE_RESULT_FREE_SPINS;
    }

    this.title = this.initTitle(this.props.title);
    this.freeSpins = this.initFreeSpins(this.props.freeSpins);
    this.andText = this.initAndText();
    this.ninjaIcon = this.initNinjaIcon(this.props.getIconPattern);
    this.btn = this.initBtn(this.props.btnText);

    this.addChild(this.title);

    if (this.messageDisplayPtn & MESSAGE_CHALLENGE_RESULT_FREE_SPINS) {
      this.addChild(this.freeSpins);
    }

    if (this.messageDisplayPtn === MESSAGE_CHALLENGE_RESULT_ICON_AND_FREE_SPINS) {
      this.addChild(this.andText);
    }

    if (this.messageDisplayPtn & MESSAGE_CHALLENGE_RESULT_ICON) {
      for (let j = 0; j < this.props.getIconPattern! + 1; j++) {
        this.addChild(this.ninjaIcon[j]);
      }
    }

    if (this.props.onInitCallback) {
      this.props.onInitCallback();
    }

    this.addChild(this.btn);

    return this;
  }

  private changeBackground(): void {
    if (!this.props.preventDefaultDestroy) this.handleDestroy();
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, totalWinTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.y = MESSAGE_CHALLENGE_RESULT_Y_POSITION[this.messageDisplayPtn][MessageResultRow.Result_Row_Title];
    updateTextScale(winTitle, this.banner!.width - 300, 250);
    return winTitle;
  }

  protected initFreeSpins(freeSpins: string | undefined): PIXI.Text {
    const freeSpinsText = new PIXI.Text(freeSpins, FreeSpinsStyles);
    freeSpinsText.resolution = 1;
    freeSpinsText.anchor.set(0.5, 0);
    freeSpinsText.y = MESSAGE_CHALLENGE_RESULT_Y_POSITION[this.messageDisplayPtn][MessageResultRow.Result_Row_FreeSpin];
    updateTextScale(freeSpinsText, this.banner!.width - 300, 250);
    return freeSpinsText;
  }

  protected initAndText(): PIXI.Text {
    const andText = new PIXI.Text(i18n.t<string>('and'), andTextStyle);
    andText.resolution = 1;
    andText.anchor.set(0.5, 0);
    andText.y = MESSAGE_CHALLENGE_RESULT_Y_POSITION[this.messageDisplayPtn][MessageResultRow.Result_Row_And];
    return andText;
  }

  private initNinjaIcon(NinjaIconType: NinjaType | undefined): PIXI.Sprite[] {
    const spriteArray: PIXI.Sprite[] = [];
    for (let j = 0; j < NinjaIconType! + 1; j++) {
      const x = 2 - j;
      const sprite = new NinjaSymbol(x);
      sprite.updateNinjaSymbolIcon(true);
      sprite.y = MESSAGE_CHALLENGE_RESULT_Y_POSITION[this.messageDisplayPtn][MessageResultRow.Result_Row_Ninja];
      sprite.x = MESSAGE_CHALLENGE_RESULT_NINJA_POSITION[NinjaIconType!][j];

      spriteArray.push(sprite);
    }

    return spriteArray;
  }

  private initBtn(btnText: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0);
    btn.y = MESSAGE_CHALLENGE_RESULT_Y_POSITION[this.messageDisplayPtn][MessageResultRow.Result_Row_Btn];
    updateTextScale(btn, 800, isMobile ? 250 : 250);

    return btn;
  }
}
