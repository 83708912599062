import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '008_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '008_02_BaseGameBGM_Melo',
  ChallengeGame01 = '008_03_ChallengeGame01',
  ChallengeGame02 = '008_04_ChallengeGame02',
  ChallengeGame03 = '008_05_ChallengeGame03',
  FreeSpinBGM = '008_06_FreeSpinBGM',
  TotalWinBanner = '008_07_TotalWinBanner',
  BigWin_Loop = '008_08_BigWin_Loop',
  BigWin_intro = '008_08_BigWin_intro',
  BigWin_End = '008_09_BigWin_END',
  Scatter_01 = '008_10_Scatter_01',
  Scatter_02 = '008_11_Scatter_02',
  Scatter_03 = '008_12_Scatter_03',
  LongSpin = '008_13_LongSpin',
  Challenge_Attack = '008_14_Challenge_Attack',
  Challenge_Success = '008_15_Challenge_Success',
  Challenge_Miss = '008_16_Challenge_Miss',
  Scene_Change = '008_17_Scene_Change',
  FeatureTrigger = '008_FeatureTrigger',
  SFX_BuyFeature = 'SFX_BuyFeature',
  Win_End = 'SFX_SM_CountUp_End',
  Win_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_Win_Big',
  SFX_WIN_EPIC = 'SFX_Win_Epic',
  SFX_WIN_GREAT = 'SFX_Win_Great',
  SFX_WIN_MEGA = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 66208.0045351474, true],
  [ISongs.BaseGameBGM_Melo]: [68000, 66206.93877551019, true],
  [ISongs.ChallengeGame01]: [136000, 35294.12698412699, true],
  [ISongs.ChallengeGame02]: [173000, 54857.188208616775, true],
  [ISongs.ChallengeGame03]: [229000, 52965.55555555557, true],
  [ISongs.FreeSpinBGM]: [283000, 54084.51247165533, true],
  [ISongs.TotalWinBanner]: [339000, 5000],
  [ISongs.BigWin_Loop]: [345000, 9142.87981859411, true],
  [ISongs.BigWin_intro]: [356000, 2285.7142857142776],
  [ISongs.BigWin_End]: [360000, 4875.0113378685],
  [ISongs.Scatter_01]: [366000, 1022.6530612245028],
  [ISongs.Scatter_02]: [369000, 1038.6848072562316],
  [ISongs.Scatter_03]: [372000, 879.841269841279],
  [ISongs.LongSpin]: [374000, 2995.9863945578036],
  [ISongs.Challenge_Attack]: [378000, 405.01133786847276],
  [ISongs.Challenge_Success]: [380000, 2534.875283446695],
  [ISongs.Challenge_Miss]: [384000, 1071.7687074829882],
  [ISongs.Scene_Change]: [387000, 385.89569160996007],
  [ISongs.FeatureTrigger]: [389000, 879.841269841279],
  [ISongs.SFX_BuyFeature]: [391000, 666.6666666666856],
  [ISongs.Win_End]: [393000, 933.4693877551103],
  [ISongs.Win_Loop]: [395000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [397000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [399000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [401000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [403000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [405000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [407000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [409000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [411000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [413000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [415000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [418000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [421000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [425000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.3,
  [ISongs.ChallengeGame01]: 0.3,
  [ISongs.ChallengeGame02]: 0.3,
  [ISongs.ChallengeGame03]: 0.3,
  [ISongs.FreeSpinBGM]: 0.3,
  [ISongs.TotalWinBanner]: 0.5,
  [ISongs.BigWin_Loop]: 0.4,
  [ISongs.BigWin_intro]: 0.4,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.Scatter_01]: 0.6,
  [ISongs.Scatter_02]: 0.6,
  [ISongs.Scatter_03]: 0.6,
  [ISongs.LongSpin]: 0.4,
  [ISongs.Challenge_Attack]: 0.6,
  [ISongs.Challenge_Success]: 0.6,
  [ISongs.Challenge_Miss]: 0.6,
  [ISongs.Scene_Change]: 0.5,
  [ISongs.FeatureTrigger]: 0.5,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.Win_End]: 0.3,
  [ISongs.Win_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.6,
  [ISongs.SFX_WIN_EPIC]: 0.6,
  [ISongs.SFX_WIN_GREAT]: 0.6,
  [ISongs.SFX_WIN_MEGA]: 0.6,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
