import { SlotId } from '../../config';
import { EventTypes, GameMode, ReelSet, reelSets } from '../../global.d';
import { ReelSetType, setReel, setSlotConfig, setUserLastBetResult } from '../../gql';
import { isChallengeMode, isExtendedSlot, isFreeSpinMode } from '../../utils';
import { BgSkin } from '../background/background';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_DURATION,
  ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  BASE_SPIN_TIME,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';
import { Icon } from '../d';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public forcedStop = false;

  private replay = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));

    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));

    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_REPLAY_BET_ID, () => {
      this.replay = true;
    });
    this.sortableChildren = true;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    const { mode, background } = settings;
    if (isChallengeMode(mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private hideSlots(
    spinResult: Icon[],
    constrcutorFlg: boolean,
    reelPosition?: number,
    reelLength?: number,
    reelId?: number,
  ): void {
    const arr = [];
    if (reelId !== undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i * REELS_AMOUNT + reelId);
      }
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(): void {
    const arr = [];
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) arr.push(i);
    this.setSlotsVisibility(arr, true);
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i].spinAnimation?.getStarting());
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i].spinAnimation?.getFakeRolling());
      this.reels[i].position = this.reels[i].size - positions[i];
      this.reels[i].state = ReelState.IDLE;
    }
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    if (this.replay && settings.reelSet.id === reelSets[GameMode.REGULAR]) return;
    const reelPositions = settings.reelPositions
      .slice(0, 5)
      .map((position, idx) => (settings.reelSet.layout[idx].length - position) % settings.reelSet.layout[idx].length);

    const reelBuf1: SlotId[][] = [];

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i].clean();
      const reelBuf: SlotId[] = [];
      const changeReelX3: SlotId[][] = [
        [SlotId.A, SlotId.Ax3],
        [SlotId.B, SlotId.Bx3],
        [SlotId.C, SlotId.Cx3],
      ];
      for (let j = 0; j < 3; j++) {
        for (let y = 0; y < settings.reelSet.layout[i].length; y++) {
          if (
            settings.reelSet.id === reelSets[GameMode.FREE_SPINS_SYMBOLS_C] ||
            settings.reelSet.id === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B] ||
            settings.reelSet.id === reelSets[GameMode.FREE_SPINS_SYMBOLS_C_B_A]
          ) {
            let nextIndex = y + 1;
            let beforeIndex = y - 1;
            if (y === settings.reelSet.layout[i].length - 1) {
              nextIndex = 0;
            }

            if (y === 0) {
              beforeIndex = settings.reelSet.layout[i].length - 1;
            }

            if (
              settings.reelSet.layout[i][y] === changeReelX3[j][0] &&
              settings.reelSet.layout[i][nextIndex] === changeReelX3[j][0]
            ) {
              const x3SlotId: SlotId = changeReelX3[j][1];
              reelBuf[y] = x3SlotId;
            } else if (
              settings.reelSet.layout[i][beforeIndex] === changeReelX3[j][0] &&
              settings.reelSet.layout[i][y] === changeReelX3[j][0]
            ) {
              const x3SlotId: SlotId = changeReelX3[j][1];
              reelBuf[y] = x3SlotId;
            } else {
              // eslint-disable-next-line no-lonely-if
              if (isExtendedSlot(reelBuf[y])) {
              } else {
                reelBuf[y] = settings.reelSet.layout[i][y];
              }
            }
          } else {
            reelBuf[y] = settings.reelSet.layout[i][y];
          }
        }
      }

      this.reels[i].init(reelBuf, reelPositions[i]);
      reelBuf1[i] = reelBuf;
    }
    setReel(reelBuf1);
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i], position);
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }

    this.changeReelSet({
      reelSet: setSlotConfig().reels.find((reels) => reels.id === setUserLastBetResult().reelSetId)!,
      reelPositions: setUserLastBetResult().result.reelPositions,
    });
  }

  private forceStopReels(isTurboSpin: boolean): void {
    this.forcedStop = true;
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0].spinAnimation!.startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);
    for (let i = 0; i < this.reels.length; i++) {
      if (stopAllReelsAtSameTime && i !== 0) {
        this.reels[i].isPlaySoundOnStop = false;
      }
      this.reels[i].stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setupAnimationTarget(
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationReelId: number,
  ): void {
    for (let j = 0; j < this.reels.length; j++) {
      const fakeRollingAnimation = this.reels[j].spinAnimation!.getFakeRolling();
      const rollingAnimation = this.reels[j].spinAnimation!.getRolling();
      const endingAnimation = this.reels[j].spinAnimation!.getEnding();
      let target = this.reels[j].getTarget(this.reels[j].data.length - reelPositions[j]);
      fakeRollingAnimation.duration = 0;
      this.reels[j].scatter_no = scatterNo[j];
      if (j > anticipationReelId) {
        let beginValue =
          target -
          INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP -
          ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
          j * 5 -
          (j - anticipationReelId - 1) * (this.reels[j].isTurboSpin ? 150 : 55);
        if (beginValue < 0) {
          const prolong = this.prolongTarget(this.reels[j], Math.abs(beginValue));
          beginValue += prolong;
          target += prolong;
        }
        rollingAnimation.propertyBeginValue = beginValue;

        rollingAnimation.target = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        rollingAnimation.duration += ANTICIPATION_DURATION * (j - anticipationReelId - 1);

        endingAnimation.propertyBeginValue = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
        endingAnimation.duration = ANTICIPATION_DURATION;
        endingAnimation.addOnStart(() => {
          eventManager.emit(EventTypes.SLOT_HIGHLIGHT_START);
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, j);
        });
      } else {
        rollingAnimation.propertyBeginValue =
          target - INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP - REEL_ENDING_SLOTS_AMOUNT - j * 5;
        rollingAnimation.target = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        endingAnimation.target = target;
      }
    }
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const position = this.reels[x].size - (Math.round(this.reels[x].position) % this.reels[x].size) + y - 1;
      const normalizedPosition = position === -1 ? this.reels[x].size - 1 : position % this.reels[x].size;

      let slot;
      for (let i = 0; i < 3; i++) {
        slot = this.reels[x].slots[normalizedPosition + i];
        if (slot !== undefined) {
          if (slot.height !== 0) {
            break;
          }
        }
      }

      if (slot) {
        slot.visible = visibility;
      }
    });
  }
}

export default ReelsContainer;
