import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isChallengeMode, isFreeSpinMode, updateTextScale } from '../../utils';
import { BgSkin } from '../background/background';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH } from '../background/config';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_PORTRAIT_HEIGHT,
  FEATURE_BTN_PORTRAIT_WIDTH,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  isLeftHandMode: boolean;

  private windowWidth: number;

  private windowHeight: number;

  private isFreeRoundBonus: boolean;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);

    this.isFreeRoundBonus = false;

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, this.handleLeftHandMode.bind(this));
    eventManager.on(EventTypes.SET_IS_FREE_ROUND_BONUS, (isFreeRoundBonus: boolean) => {
      this.isFreeRoundBonus = isFreeRoundBonus;
      this.handleDisable(isFreeRoundBonus);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (e) => {
      if (!this.isDisabled) {
        AudioHowl.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.style = buyFeatureHoverTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2);
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2);
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.style = buyFeatureClickTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);

        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2);
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, isMobile ? 120 : 120, isMobile ? 150 : 100);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      AudioHowl.play({ type: ISongs.SFX_BuyFeature });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isFreeRoundBonus && !disable) return;
    if (this.isAutoSpinInProgress) return;

    updateTextScale(
      this.text,
      this.windowWidth > this.windowHeight ? 120 : 200,
      this.windowWidth > this.windowHeight ? 150 : 300,
      2,
    );

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    } else {
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
      this.text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2);
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    if (width > height) {
      this.btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
      this.btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    } else {
      this.btn.width = FEATURE_BTN_PORTRAIT_WIDTH;
      this.btn.height = FEATURE_BTN_PORTRAIT_HEIGHT;

      this.x = 0;
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 2;
    }

    this.setPosition(width, height);
    this.text.position.set(this.btn.width / 2, this.btn.height / 2);

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    if (width > height) {
      if (setIsLeftHandMode() && isMobile) {
        this.x = 1330;
      } else {
        this.x = -54 - FEATURE_BTN_LANDSCAPE_WIDTH;
      }
      this.y = SLOTS_CONTAINER_HEIGHT / 2 - FEATURE_BTN_LANDSCAPE_HEIGHT / 2;
    } else {
      if (setIsLeftHandMode() && isMobile) {
        this.x = 960;
      } else {
        this.x = 0;
      }
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 2;
    }
  }

  private handleLeftHandMode = (isLhm: boolean): void => {
    this.setPosition(this.windowWidth, this.windowHeight);
  };

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isChallengeMode(settings.mode)) {
      this.visible = false;
    } else if (isFreeSpinMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
