import * as PIXI from 'pixi.js';

class ViewContainer extends PIXI.Container {
  constructor() {
    super();
    this.visible = true;
    this.sortableChildren = true;
  }

  public addChildNode(child: PIXI.Container): void {
    this.addChild(child);
  }
}
export default ViewContainer;
