import { GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const BANNER_POSITION_Y = -58;

// WIN BANNER
export const MESSAGE_WIN_BANNER_TITLE_Y = 230;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_X = 20;
export const MESSAGE_WIN_BANNER_WIN_AMOUNT_Y = 420;
export const MESSAGE_NEXT_BASE_GAME_Y = 580;
