import { Play } from '@phoenix7dev/play-music/dist/d';
import * as PIXI from 'pixi.js';
import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { ISongs, SlotId } from '../config';
import { bonusIds, EventTypes, GameMode, ReelSet, reelSets } from '../global.d';
import { setGameMode, setReel } from '../gql';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import SpineAnimation from '../slotMachine/animations/spine';
import {
  eventManager,
  REELS_AMOUNT,
  SLOTS_PER_REEL_AMOUNT,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.FREE_SPINS ||
    mode === GameMode.FREE_SPINS_SYMBOLS_C ||
    mode === GameMode.FREE_SPINS_SYMBOLS_C_B ||
    mode === GameMode.FREE_SPINS_SYMBOLS_C_B_A
  );
};

export const isChallengeMode = (mode: GameMode): boolean => {
  switch (mode) {
    case GameMode.CHALLENGE_GAME:
      return true;
    default:
      return false;
  }
};

export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};

export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return (
    ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || 25)) / 100
  );
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const destroySpine = (spine: SpineAnimation): void => {
  setImmediate(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy();
    }
  });
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.SC1;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
  isSceneChange,
  isInTransition
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
  isSceneChange: boolean;
  isInTransition: boolean;
}): boolean => {
  if (isInTransition) {
    return false;
  }

  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  if (isSceneChange) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (
  options: Partial<DropShadowFilterOptions>,
): PIXI.Filter => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getPlayListOnBroken = (isDuringBigWinLoop: boolean): Play[] => {
  if (isDuringBigWinLoop) {
    return [
      { type: ISongs.FreeSpinBGM, volume: 0 },
      { type: ISongs.BigWin_Loop },
    ];
  }
  return [{ type: ISongs.FreeSpinBGM }];
};

export const getGameModeByReelSetId = (reelSetId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(reelSets)) {
    if (id === reelSetId) {
      return Number(gameMode) as GameMode;
    }
  }
  return GameMode.REGULAR;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  for (const [gameMode, id] of Object.entries(bonusIds)) {
    if (id === bonusId) {
      return Number(gameMode) as GameMode;
    }
  }
  return GameMode.REGULAR;
};

export const getSpinResult5x3 = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const spinResult = [
    ...reelPositions.map((random, index) => {
      const prevRandom =
        random - 1 < 0 ? reelSet.layout[index].length - 1 : random - 1;
      return getIconById(icons, reelSet.layout[index][prevRandom]);
    }),
    ...reelPositions.map((random, index) => {
      return getIconById(icons, reelSet.layout[index][random]);
    }),
    ...reelPositions.map((random, index) => {
      const nextRandom =
        random + 1 >= reelSet.layout[index].length ? 0 : random + 1;
      return getIconById(icons, reelSet.layout[index][nextRandom]);
    }),
  ];
  return spinResult;
};

export const isSymbolABC = (slotId: SlotId): boolean => {
  let rtn = false;
  if (
    slotId === SlotId.A ||
    slotId === SlotId.B ||
    slotId === SlotId.C ||
    slotId === SlotId.Ax3 ||
    slotId === SlotId.Bx3 ||
    slotId === SlotId.Cx3
  ) {
    rtn = true;
  }
  return rtn;
};

export const isExtendedSlot = (slotId: SlotId): boolean => {
  let rtn = false;
  if (slotId === SlotId.Ax3 || slotId === SlotId.Bx3 || slotId === SlotId.Cx3) {
    rtn = true;
  }
  return rtn;
};

export const getExtendedSpinResult = (
  spinResult: Icon[],
  reelPosition?: number[],
): SlotId[] => {
  const extendedSpinResult: SlotId[] = [];
  const reelSet = setReel();
  const reelStopPosition: number[] = [];
  if (isFreeSpinMode(setGameMode())) {
    for (let col = 0; col < REELS_AMOUNT; col++) {
      for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
        let nextIndex = reelPosition![col] + 1;
        let beforeIndex = reelPosition![col] - 1;
        if (reelPosition![col] === reelSet[col].length - 1) {
          nextIndex = 0;
        } else if (reelPosition![col] === 0) {
          beforeIndex = reelSet[col].length - 1;
        }
        reelStopPosition[0] = beforeIndex;
        reelStopPosition[1] = reelPosition![col];
        reelStopPosition[2] = nextIndex;

        extendedSpinResult[row * REELS_AMOUNT + col] =
          reelSet[col][reelStopPosition[row]];
      }
    }
  } else {
    spinResult.forEach((slot, index) => (extendedSpinResult[index] = slot.id));
  }
  return extendedSpinResult;
};

export const setSlotsStopDisplayVisible = (visible: boolean): void => {
  for (let col = 0; col < REELS_AMOUNT; col++) {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      eventManager.emit(
        EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY,
        [row * REELS_AMOUNT + col],
        visible,
      );
    }
  }
};
