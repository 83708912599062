import _ from 'lodash';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setGameMode, setIsAnticipation, setReplayBet } from '../../gql';
import { isExtendedSlot, isFreeSpinMode } from '../../utils';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_REPLAY_ROLLING_DURATION,
  FAKE_REPLAY_ROLLING_SLOTS,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_SLOTS,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import { Slot1 } from '../slot/1slot';
import { Slot3 } from '../slot/3slot';
import { Slot3Dummy } from '../slot/3slotDummy';
import { Slot } from '../slot/slot';
import SpinAnimation from '../spin/spin';

import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: SpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public scatter_no = 0;

  private isForceStopped = false;

  // public anticipationAnimation: Animation | undefined;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = SLOT_WIDTH;
    this.container.x = id * REEL_WIDTH + (REEL_WIDTH - SLOT_WIDTH) / 2;
    this.container.y = 0;
    this.createSlots();
    this.position = startPosition === this.size ? 0 : this.size - (startPosition % this.size);
    // TODO interim support
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.isForceStopped = true;
    });
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  private onAnticipationStart(index: number): void {
    setIsAnticipation(true);
    _.forEach(this.slots, (slot) => {
      if (slot.slotId !== SlotId.SC1) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
    if (this.id === index && !this.isForceStopped) {
      eventManager.emit(EventTypes.NEARMISS);
      AudioHowl.play({ type: ISongs.LongSpin, stopPrev: true });
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
    this.isForceStopped = false;
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length];
      const postSlotId = this.data[(i + 1) % this.data.length];
      let slot;

      if (isExtendedSlot(slotId)) {
        if (postSlotId === slotId) {
          slot = new Slot3Dummy(i, slotId);
        } else {
          slot = new Slot3(i, slotId);
        }
      } else {
        slot = new Slot1(i, slotId);
      }
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): SpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingTime = isTurboSpin
      ? TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      : BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
    const target = this.position + INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP + this.id * 5;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });
    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target:
        this.position + REEL_STARTING_SLOTS_AMOUNT + setReplayBet() ? FAKE_REPLAY_ROLLING_SLOTS : FAKE_ROLLING_SLOTS,
      duration: setReplayBet() ? FAKE_REPLAY_ROLLING_DURATION : FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });
    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });
    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });
    this.spinAnimation = new SpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      endingAnimation: ending,
    });
    return this.spinAnimation;
  }

  private onReelEnding(previousState: ReelState, newState: ReelState): void {}

  private onReelStop(): void {
    if (this.id === 4) {
      eventManager.emit(EventTypes.NEARMISS_SKIP);
    }
    this.isForceStopped = false;
    const reelStopSoundList = [ISongs.SFX_UI_SpinStop, ISongs.Scatter_01, ISongs.Scatter_02, ISongs.Scatter_02];

    if (this.isPlaySoundOnStop) {
      let idx = 0;
      if (this.id === 0 && this.scatter_no === 1) {
        idx = 1;
      } else if (this.id === 2 && this.scatter_no === 2) {
        idx = 2;
      } else if (this.id === 4 && this.scatter_no === 3) {
        idx = 3;
      }

      AudioHowl.play({
        type: reelStopSoundList[idx],
        stopPrev: true,
      });
      this.isPlaySoundOnStop = false;
      this.scatter_no = 0;
    }
  }

  private onReelIdle(previousState: ReelState, newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);
      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    this.spinAnimation!.getEnding().duration = endingDuration;
  }

  private getReelStopSlots(position: number): Array<Slot> {
    const slots: Array<Slot> = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    slots.push(this.slots[top]);
    slots.push(this.slots[middle]);
    slots.push(this.slots[bottom]);
    return slots;
  }

  private onReelRolling(previousState: ReelState, newState: ReelState): void {}

  private onReelStarting(previousState: ReelState, newState: ReelState): void {}

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.ROLLING) {
      this.onReelRolling(previousState, ReelState.ROLLING);
    }
    if (newState === ReelState.STARTING) {
      this.onReelStarting(previousState, ReelState.STARTING);
    }
    if (newState === ReelState.ENDING) {
      this.onReelEnding(previousState, ReelState.ENDING);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j];
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT;

      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
