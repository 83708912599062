import { ResourceTypes } from '../resources.d';
import { Slot } from '../slotMachine/slot/slot';
import { IPixiAssets } from '../utils/d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.symbolANinja,
  [SlotId.Ax3]: ResourceTypes.symbolANinjax3,
  [SlotId.B]: ResourceTypes.symbolBNinja,
  [SlotId.Bx3]: ResourceTypes.symbolBNinjax3,
  [SlotId.C]: ResourceTypes.symbolCNinja,
  [SlotId.Cx3]: ResourceTypes.symbolCNinjax3,
  [SlotId.D]: ResourceTypes.symbolDNinjasword,
  [SlotId.E]: ResourceTypes.symbolEChainsickle,
  [SlotId.F]: ResourceTypes.symbolFKunai,
  [SlotId.G]: ResourceTypes.symbolGMakibishi,
  [SlotId.WL]: ResourceTypes.symbolWLRoll,
  [SlotId.SC1]: ResourceTypes.symbolSCShuriken,
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.blurSymbolANinja,
  [SlotId.Ax3]: ResourceTypes.blurSymbolANinjax3,
  [SlotId.B]: ResourceTypes.blurSymbolBNinja,
  [SlotId.Bx3]: ResourceTypes.blurSymbolBNinjax3,
  [SlotId.C]: ResourceTypes.blurSymbolCNinja,
  [SlotId.Cx3]: ResourceTypes.blurSymbolCNinjax3,
  [SlotId.D]: ResourceTypes.blurSymbolDNinjasword,
  [SlotId.E]: ResourceTypes.blurSymbolEChainsickle,
  [SlotId.F]: ResourceTypes.blurSymbolFKunai,
  [SlotId.G]: ResourceTypes.blurSymbolGMakibishi,
  [SlotId.WL]: ResourceTypes.symbolWLRoll,
  [SlotId.SC1]: ResourceTypes.symbolSCShuriken,
};

export const MAPPED_SYMBOLS_STOP_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_WL_Roll_announce',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_SC_Shuriken_announce',
  },
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Ninja_win',
  },
  [SlotId.Ax3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_A_Ninja3_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Ninja_win',
  },
  [SlotId.Bx3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_B_Ninja3_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Ninja_win',
  },
  [SlotId.Cx3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_C_Ninja3_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_D_Ninjasword_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_E_Chainsickle_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_F_Kunai_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_G_Makibishi_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_WL_Roll_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbol_all',
    animation: 'Symbol_SC_Shuriken_win',
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  {
    name: ResourceTypes.symbolANinja,
    src: Resources.getSource(ResourceTypes.symbolANinja),
  },
  {
    name: ResourceTypes.symbolBNinja,
    src: Resources.getSource(ResourceTypes.symbolBNinja),
  },
  {
    name: ResourceTypes.symbolCNinja,
    src: Resources.getSource(ResourceTypes.symbolCNinja),
  },
  {
    name: ResourceTypes.symbolANinjax3,
    src: Resources.getSource(ResourceTypes.symbolANinjax3),
  },
  {
    name: ResourceTypes.symbolBNinjax3,
    src: Resources.getSource(ResourceTypes.symbolBNinjax3),
  },
  {
    name: ResourceTypes.symbolCNinjax3,
    src: Resources.getSource(ResourceTypes.symbolCNinjax3),
  },
  {
    name: ResourceTypes.symbolDNinjasword,
    src: Resources.getSource(ResourceTypes.symbolDNinjasword),
  },
  {
    name: ResourceTypes.symbolEChainsickle,
    src: Resources.getSource(ResourceTypes.symbolEChainsickle),
  },
  {
    name: ResourceTypes.symbolFKunai,
    src: Resources.getSource(ResourceTypes.symbolFKunai),
  },
  {
    name: ResourceTypes.symbolGMakibishi,
    src: Resources.getSource(ResourceTypes.symbolGMakibishi),
  },
  {
    name: ResourceTypes.symbolWLRoll,
    src: Resources.getSource(ResourceTypes.symbolWLRoll),
  },
  {
    name: ResourceTypes.symbolSCShuriken,
    src: Resources.getSource(ResourceTypes.symbolSCShuriken),
  },
];

export const LOADER_TEXTURES = (isMobile: boolean): Array<IPixiAssets> => [
  {
    name: ResourceTypes.reelsBackBasegame,
    src: Resources.getSource(ResourceTypes.reelsBackBasegame),
  },
  {
    name: ResourceTypes.reelFrame,
    src: Resources.getSource(ResourceTypes.reelFrame),
  },
  {
    name: ResourceTypes.challengegameFrame,
    src: Resources.getSource(ResourceTypes.challengegameFrame),
  },
  {
    name: ResourceTypes.blurSymbolANinja,
    src: Resources.getSource(ResourceTypes.blurSymbolANinja),
  },
  {
    name: ResourceTypes.blurSymbolANinjax3,
    src: Resources.getSource(ResourceTypes.blurSymbolANinjax3),
  },
  {
    name: ResourceTypes.blurSymbolBNinja,
    src: Resources.getSource(ResourceTypes.blurSymbolBNinja),
  },
  {
    name: ResourceTypes.blurSymbolBNinjax3,
    src: Resources.getSource(ResourceTypes.blurSymbolBNinjax3),
  },
  {
    name: ResourceTypes.blurSymbolCNinja,
    src: Resources.getSource(ResourceTypes.blurSymbolCNinja),
  },
  {
    name: ResourceTypes.blurSymbolCNinjax3,
    src: Resources.getSource(ResourceTypes.blurSymbolCNinjax3),
  },
  {
    name: ResourceTypes.blurSymbolDNinjasword,
    src: Resources.getSource(ResourceTypes.blurSymbolDNinjasword),
  },
  {
    name: ResourceTypes.blurSymbolEChainsickle,
    src: Resources.getSource(ResourceTypes.blurSymbolEChainsickle),
  },
  {
    name: ResourceTypes.blurSymbolFKunai,
    src: Resources.getSource(ResourceTypes.blurSymbolFKunai),
  },
  {
    name: ResourceTypes.blurSymbolGMakibishi,
    src: Resources.getSource(ResourceTypes.blurSymbolGMakibishi),
  },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.challengegameBase,
    src: Resources.getSource(ResourceTypes.challengegameBase),
  },
  {
    name: ResourceTypes.challengegameBase2,
    src: Resources.getSource(ResourceTypes.challengegameBase2),
  },
  {
    name: ResourceTypes.challengegameBase3,
    src: Resources.getSource(ResourceTypes.challengegameBase3),
  },
  {
    name: ResourceTypes.freespinsBase,
    src: Resources.getSource(ResourceTypes.freespinsBase),
  },
  {
    name: ResourceTypes.backgroundCastle,
    src: Resources.getSource(ResourceTypes.backgroundCastle),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.intro01,
    src: Resources.getSource(ResourceTypes.intro01),
  },
  {
    name: ResourceTypes.intro02,
    src: Resources.getSource(ResourceTypes.intro02),
  },
  {
    name: ResourceTypes.intro03,
    src: Resources.getSource(ResourceTypes.intro03),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.textLogo,
    src: Resources.getSource(ResourceTypes.textLogo),
  },
  {
    name: ResourceTypes.titlelogo,
    src: Resources.getSource(ResourceTypes.titlelogo),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActive,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActive),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureInput,
    src: Resources.getSource(ResourceTypes.buyFeatureInput),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.challengegameIconShuriken,
    src: Resources.getSource(ResourceTypes.challengegameIconShuriken),
  },
  {
    name: ResourceTypes.challengegameIconShurikenOff,
    src: Resources.getSource(ResourceTypes.challengegameIconShurikenOff),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaA,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaA),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaAOff,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaAOff),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaB,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaB),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaBOff,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaBOff),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaC,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaC),
  },
  {
    name: ResourceTypes.challengegameLeveliconNinjaCOff,
    src: Resources.getSource(ResourceTypes.challengegameLeveliconNinjaCOff),
  },
  {
    name: ResourceTypes.particle,
    src: Resources.getSource(ResourceTypes.particle),
  },
  {
    name: ResourceTypes.sparks,
    src: Resources.getSource(ResourceTypes.sparks),
  },
  {
    name: ResourceTypes.challengegameCutinCoinicon,
    src: Resources.getSource(ResourceTypes.challengegameCutinCoinicon),
  },
  {
    name: ResourceTypes.challengegameCutinTreasureicon,
    src: Resources.getSource(ResourceTypes.challengegameCutinTreasureicon),
  },
  {
    name: ResourceTypes.iconGlow,
    src: Resources.getSource(ResourceTypes.iconGlow),
  },
];

export const generateTexturePath = (before: string, after: string, isMobile: boolean): string => {
  return `${before}/${isMobile ? 'mobile' : 'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean): Array<IPixiAssets> => {
  return [
    {
      name: 'Ambient_Cloud',
      src: generateTexturePath('/animations', 'Ambient_Cloud/Ambient_Cloud.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Ambient_Cloud.png'],
        mobile: ['Ambient_Cloud.png'],
      },
    },
    {
      name: 'Ambient_Shine',
      src: generateTexturePath('/animations', 'Ambient_Shine/Ambient_Shine.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Ambient_Shine.png'],
        mobile: ['Ambient_Shine.png'],
      },
    },
    {
      name: 'Ambient_Tree',
      src: generateTexturePath('/animations', 'Ambient_Tree/Ambient_Tree.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Ambient_Tree.png'],
        mobile: ['Ambient_Tree.png'],
      },
    },
    {
      name: 'challengeGame_ninja1',
      src: generateTexturePath('/animations', 'Challengegame_Ninja1/Challengegame_Ninja1.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Challengegame_Ninja1.png'],
        mobile: ['Challengegame_Ninja1.png'],
      },
    },
    {
      name: 'challengeGame_ninja2',
      src: generateTexturePath('/animations', 'Challengegame_Ninja2/Challengegame_Ninja2.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Challengegame_Ninja2.png'],
        mobile: ['Challengegame_Ninja2.png'],
      },
    },
    {
      name: 'challengeGame_ninja3',
      src: generateTexturePath('/animations', 'Challengegame_Ninja3/Challengegame_Ninja3.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Challengegame_Ninja3.png'],
        mobile: ['Challengegame_Ninja3.png'],
      },
    },
    {
      name: 'challengeGame_ninja4',
      src: generateTexturePath('/animations', 'Challengegame_Ninja4/Challengegame_Ninja4.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Challengegame_Ninja4.png'],
        mobile: ['Challengegame_Ninja4.png'],
      },
    },
    {
      name: 'challengeGame_ninja5',
      src: generateTexturePath('/animations', 'Challengegame_Ninja5/Challengegame_Ninja5.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Challengegame_Ninja5.png'],
        mobile: ['Challengegame_Ninja5.png'],
      },
    },
    {
      name: 'Nearmiss',
      src: generateTexturePath('/animations', 'Nearmiss/Nearmiss.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Nearmiss.png'],
        mobile: ['Nearmiss.png'],
      },
    },
    {
      name: 'Cutin_Base',
      src: generateTexturePath('/animations', 'Cutin_Base/Cutin_Base.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Cutin_Base.png'],
        mobile: ['Cutin_Base.png'],
      },
    },
    {
      name: 'Cutin_intro_animation',
      src: generateTexturePath('/animations', 'Cutin_intro_animation/Cutin_intro_animation.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Cutin_intro_animation.png'],
        mobile: ['Cutin_intro_animation.png'],
      },
    },
    {
      name: 'Cutin_animation',
      src: generateTexturePath('/animations', 'Cutin_animation/Cutin_animation.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Cutin_animation.png'],
        mobile: ['Cutin_animation.png'],
      },
    },
    {
      name: 'Gamechange_Ninja',
      src: generateTexturePath('/animations', 'Gamechange_Ninja/Gamechange_Ninja.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Gamechange_Ninja.png'],
        mobile: ['Gamechange_Ninja.png'],
      },
    },
    {
      name: 'symbol_all',
      src: generateTexturePath('/animations', 'Symbol_all/Symbol_all.json', isMobile),
      isSpine: true,
      spineImages: {
        desktop: ['Symbol_all.png', 'Symbol_all1.jpg'],
        mobile: ['Symbol_all.png', 'Symbol_all1.jpg'],
      },
    },
  ];
};
