import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { getBetAmountGql, getUserGql, setCoinValue } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.symbolSCShuriken)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return countCoins({ totalAmount: betAmount / 25, coinValue: setCoinValue() }) * multiplier;
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.symbolWLRoll || slug === ResourceTypes.symbolSCShuriken;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = balance;

  return (
    <section>
      <div className={styles['paytable-list']}>
        {data.map(({ combos, slug, extendFlg }, index) => (
          <div key={index} className={extendFlg ? styles['paytable-list__item_extend'] : styles['paytable-list__item']}>
            {extendFlg && (
              <div key={slug} className={styles['paytable-list__item_extend']}>
                <div className={styles.img}>
                  {/* dynamic used keys */}
                  {/* t('infoPayTable_symbolSCShuriken') */
                  /* t('infoPayTable_symbolWLRoll') */}

                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  {hasTranslate(slug) && (
                    <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
                  )}
                </div>
                <div className={styles.content}>
                  {combos.map((i) => (
                    <div key={i.pattern}>
                      <span className={styles.multiplier}>{i.pattern} </span>
                      {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!extendFlg && (
              <div key={slug} className={styles['paytable-list__item']}>
                <div className={styles.img}>
                  <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
                  {hasTranslate(slug) && (
                    <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>
                  )}
                </div>
                <div className={styles.content}>
                  {combos.map((i) => (
                    <div key={i.pattern}>
                      <span className={styles.multiplier}>{i.pattern} </span>
                      {formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaytableComponent;
