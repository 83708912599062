import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 5,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [25, 50, 75, 100],
    minBet: 25,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.symbolWLRoll,
      combos: [
        { pattern: 'x5', multiplier: 1000 },
        { pattern: 'x4', multiplier: 200 },
        { pattern: 'x3', multiplier: 100 },
      ],
      extendFlg: false,
    },
    {
      slug: ResourceTypes.symbolSCShuriken,
      combos: [{ pattern: 'x3', multiplier: 1 }],
      extendFlg: false,
    },
    {
      slug: ResourceTypes.symbolANinja,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolANinjax3,
      combos: [
        { pattern: 'x5', multiplier: 500 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolBNinja,
      combos: [
        { pattern: 'x5', multiplier: 400 },
        { pattern: 'x4', multiplier: 80 },
        { pattern: 'x3', multiplier: 40 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolBNinjax3,
      combos: [
        { pattern: 'x5', multiplier: 400 },
        { pattern: 'x4', multiplier: 80 },
        { pattern: 'x3', multiplier: 40 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolCNinja,
      combos: [
        { pattern: 'x5', multiplier: 250 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 25 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolCNinjax3,
      combos: [
        { pattern: 'x5', multiplier: 250 },
        { pattern: 'x4', multiplier: 50 },
        { pattern: 'x3', multiplier: 25 },
      ],
      extendFlg: true,
    },
    {
      slug: ResourceTypes.symbolDNinjasword,
      combos: [
        { pattern: 'x5', multiplier: 30 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
      extendFlg: false,
    },
    {
      slug: ResourceTypes.symbolEChainsickle,
      combos: [
        { pattern: 'x5', multiplier: 30 },
        { pattern: 'x4', multiplier: 15 },
        { pattern: 'x3', multiplier: 5 },
      ],
      extendFlg: false,
    },
    {
      slug: ResourceTypes.symbolFKunai,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
      extendFlg: false,
    },
    {
      slug: ResourceTypes.symbolGMakibishi,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
      extendFlg: false,
    },
  ],
};

export enum SlotId {
  A = 'A',
  Ax3 = 'Ax3',
  B = 'B',
  Bx3 = 'Bx3',
  C = 'C',
  Cx3 = 'Cx3',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  WL = 'WL',
  SC1 = 'SC1',
}

export enum SlotIdPriority {
  A = 80,
  Ax3 = 80,
  B = 100,
  Bx3 = 100,
  C = 120,
  Cx3 = 120,
  D = 0,
  E = 20,
  F = 40,
  G = 60,
  WL = 140,
  SC1 = 160,
}
