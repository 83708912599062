import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { MessageChallengeGameStartBannerProps } from '../../global';
import { updateTextScale } from '../../utils/utils';
import NinjaSymbol from '../challengeGame/ninjaSymbol/ninjaSymbol';
import { MESSAGE_CHALLENGE_RESULT_NINJA_POSITION, NINJA_OFF_PTN } from '../config';

import { BaseMessageBanner } from './baseMessageBanner';
import { btnTextStyle, challengeGameTitleStyles, descriptionListTextStyle } from './textStyles';

export class MessageChallengeGameStartBanner extends BaseMessageBanner {
  protected props: MessageChallengeGameStartBannerProps;

  private title?: PIXI.Text;

  private ninjaIcon?: PIXI.Sprite[];

  private descriptionList?: PIXI.Text;

  private btn?: PIXI.Text;

  constructor(props: MessageChallengeGameStartBannerProps) {
    super(props);
    this.props = props;
  }

  public init(): MessageChallengeGameStartBanner {
    super.init();

    this.title = this.initTitle(this.props.title);
    updateTextScale(this.title, isMobile ? 950 : 1200, isMobile ? 300 : 350);

    this.ninjaIcon = this.initNinjaIcon();
    this.descriptionList = this.initDescriptionList(this.props.description1);
    this.btn = this.initBtn(this.props.btnText);

    this.addChild(this.title, this.descriptionList, this.btn);
    for (let j = 0; j < NINJA_OFF_PTN.length; j++) {
      this.addChild(this.ninjaIcon[0], this.ninjaIcon[1], this.ninjaIcon[2]);
    }
    return this;
  }

  protected initTitle(titleText: string): PIXI.Text {
    const winTitle = new PIXI.Text(titleText, challengeGameTitleStyles);
    winTitle.resolution = 1;
    winTitle.anchor.set(0.5, 0);
    winTitle.y = 150;
    return winTitle;
  }

  private initNinjaIcon(): PIXI.Sprite[] {
    const spriteArray: PIXI.Sprite[] = [];
    for (let j = 0; j < NINJA_OFF_PTN.length; j++) {
      const x = NINJA_OFF_PTN.length - 1 - j;
      const sprite = new NinjaSymbol(x);
      sprite.updateNinjaSymbolIcon(true);
      sprite.y = 320;
      sprite.x = MESSAGE_CHALLENGE_RESULT_NINJA_POSITION[2][j];

      spriteArray.push(sprite);
    }

    return spriteArray;
  }

  private initDescriptionList(descriptionText: string): PIXI.Text {
    const description = new PIXI.Text(descriptionText, descriptionListTextStyle);
    description.resolution = 1;
    description.anchor.set(0.5, 0);
    description.y = 320 + 200;
    updateTextScale(description, isMobile ? 1000 : 1200, isMobile ? 300 : 350);

    return description;
  }

  private initBtn(btnText: string): PIXI.Text {
    const btn = new PIXI.Text(btnText, btnTextStyle);
    btn.resolution = 1;
    btn.anchor.set(0.5, 0);
    updateTextScale(btn, isMobile ? 670 : 800, isMobile ? 150 : 250);

    btn.y = 630;

    return btn;
  }
}
