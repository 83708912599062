import * as PIXI from 'pixi.js';

import { NINJA_OFF_PTN, NINJA_ON_PTN, NINJA_SYMBOL_WIDTH } from '../../config';

class NinjaSymbol extends PIXI.Sprite {
  private textureNinjaOff: PIXI.Texture;

  private textureNinja: PIXI.Texture;

  private ninjaType: number;

  constructor(ninjaType: number) {
    super();
    this.ninjaType = ninjaType;

    this.textureNinjaOff = PIXI.Texture.from(NINJA_OFF_PTN[ninjaType]);

    this.textureNinja = PIXI.Texture.from(NINJA_ON_PTN[ninjaType]);

    this.init();
    this.setPosition();
  }

  private init(): void {
    this.texture = this.textureNinjaOff;
  }

  private setPosition(): void {
    this.position.x = this.ninjaType * NINJA_SYMBOL_WIDTH;
  }

  public updateNinjaSymbolIcon(valid: boolean): void {
    if (valid) {
      this.texture = this.textureNinja;
    } else {
      this.texture = this.textureNinjaOff;
    }
  }
}

export default NinjaSymbol;
