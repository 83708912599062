import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes } from '../../global.d';
import { setIsShowSoundToast, setIsSoundLoading, setIsSoundOn, setIsSuspended } from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { eventManager } from '../../slotMachine/config';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import Menu from '../MenuButton';
import SlotMachineLayout from '../SlotMachineLayout';
import Spin from '../SpinButton';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn, isShowSuspendedToast } = data!;
  const [isShowContent, setShowContent] = useState(false);
  const isLoading = useReactiveVar(setIsSoundLoading);

  useEffect(() => {
    AudioHowl.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    setShowContent(true);
  }, []);

  if (!isShowContent) return null;

  return (
    <>
      <EventListener />
      <Clock />
      <BuyFeature />
      <SlotMachineLayout />
      <Menu />
      <BetSettingsMenu />
      <Spin />
      <AutoPlaySettingsMenu />
      {AudioHowl.isRestricted &&
        !AudioHowl.restrictionChangedOnIntroScreen &&
        !AudioHowl.hideRestrictionModal &&
        !isShowSuspendedToast &&
        setIsSoundOn() && (
          <Toast
            title={t('soundWarningTitle')}
            text={t('soundWarningText')}
            btnText={t('soundWarningBtn')}
            isLoading={isLoading}
            handleClick={() => {
              if (!AudioHowl.isInitialized) {
                eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
              }
              AudioHowl.changeRestriction(
                false,
                [],
                () => setIsSoundLoading(true),
                () => {
                  setIsShowSoundToast(false);
                  handleChangeRestriction();
                },
              );
            }}
          />
        )}
      {isShowSuspendedToast && (
        <Toast
          title={t('suspendedWarningTitle')}
          text={t('suspendedWarningText')}
          btnText={t('suspendedWarningBtn')}
          handleClick={() => {
            setIsSuspended(false);
            AudioHowl.unSuspend();
            handleChangeRestriction();
          }}
        />
      )}
    </>
  );
};

export default GameScreen;
