import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setIsBuyFeaturePopupOpened,
  setIsChallengeModeSceneChange,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setSlotMachineInitialized,
} from '../../gql';
import { calcPercentage, isFreeSpinMode, setSlotsStopDisplayVisible } from '../../utils';
import { BgSkin } from '../background/background';
import { eventManager } from '../config';

import Button from '.';

class MenuBtn extends Button {
  private gameMode: GameMode;

  private isAutoSpins: boolean;

  private isSpinInProgress: boolean;

  private isSlotBusy: boolean;

  private isFreeSpinsWin: boolean;

  private isPopupFreeRoundsOpened: boolean;

  constructor() {
    super('menu', 75);
    this.interactive = !this.isDisabled;
    if (setBrokenGame()) {
      this.gameMode = GameMode.FREE_SPINS;
    } else {
      this.gameMode = GameMode.REGULAR;
    }
    this.isAutoSpins = false;
    this.isSpinInProgress = false;
    this.isSlotBusy = false;
    this.isFreeSpinsWin = false;
    this.isPopupFreeRoundsOpened = false;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.CHANGE_MODE, (data: { mode: GameMode }) => {
      this.gameMode = data.mode;
      this.handleDisable();
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_FREESPINS_WIN, (isFreeSpinWin: boolean) => {
      this.isFreeSpinsWin = isFreeSpinWin;
      this.handleDisable();
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_POPUP, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.CHALLENGE_MODE_SCENE_CHANGE, () => {
      this.handleDisable();
    });
    eventManager.on(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, (isPopupFreeRoundsOpened: boolean) => {
      this.isPopupFreeRoundsOpened = isPopupFreeRoundsOpened;
      this.handleDisable();
    });
  };

  private handleClick = (): void => {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && setSlotMachineInitialized()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.MENU);

      setIsOpenMenuModal({
        isOpen: !setIsOpenMenuModal().isOpen,
        type: 'menu',
      });
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
      setSlotsStopDisplayVisible(true);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  };

  private handleDisable = (): void => {
    this.setDisable(
      isFreeSpinMode(this.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        this.isFreeSpinsWin ||
        this.isPopupFreeRoundsOpened ||
        setIsBuyFeaturePopupOpened() ||
        setIsChallengeModeSceneChange(),
    );
  };

  public handlePosition(): void {
    let x = this.isLeftHandMode
      ? calcPercentage(this.applicationSize.width, 95.5)
      : calcPercentage(this.applicationSize.width, 4.5);
    let y = this.isMobile
      ? calcPercentage(this.applicationSize.height, 94)
      : calcPercentage(this.applicationSize.height, 87);
    this.setSize(calcPercentage(this.applicationSize.width, 5.4));

    if (this.isPortraitMode) {
      this.setSize(calcPercentage(this.underGameContainerSpaceHeight, 20));
      x = this.isLeftHandMode
        ? this.applicationSize.width - calcPercentage(this.underGameContainerSpaceHeight, 20)
        : calcPercentage(this.underGameContainerSpaceHeight, 20);
      y = this.gameContainerBottomPosition + calcPercentage(this.underGameContainerSpaceHeight, 80);
    }

    if (this.isLandscapeMode) {
      this.setSize(calcPercentage(this.applicationSize.height, 10.4));
      x = this.isLeftHandMode
        ? calcPercentage(this.applicationSize.width, 93)
        : calcPercentage(this.applicationSize.width, 7);
      y = calcPercentage(this.applicationSize.height, 87.5);
    }

    this.x = x;
    this.y = y;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    this.gameMode = settings.mode;
    this.handleDisable();
  }
}

export default MenuBtn;
